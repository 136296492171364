import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import Switch from 'react-switch';
import { SETTINGS } from '../../util/constants';
import { editUserSettings, getUserSettings } from '../../util/api';
import { Row, Col } from 'react-bootstrap';
import styles from './index.module.scss';
import { IUserSetting } from '../../util/types';
import { stringToBoolean, customReplaceAll } from '../../util/helpers';

const ACTIVE_COLOR = '#2f80ed';

/** The below object will have data like these (dynamic keys, and extendable) -> the type must be 'any':
  'notifications.email.newIdea': true,
  'notifications.email.newCommentOnMyIdeas': false,
  'notifications.email.newCommentOnIdeasICommented': true,
  ...
*/
const initialNotificationStates = {} as any;

// Automatically extendable when new notification types
// are added to the constant SETTINGS.NOTIFICATION_TYPES
SETTINGS.NOTIFICATION_TYPES.map((notificationType) => {
  initialNotificationStates[notificationType.id] = false;
});

const SettingsPage = () => {
  const history = useHistory();

  const [notificationSettings, setNotificationSettings] = useState(initialNotificationStates);

  // TODO: The default value should not be true if its not set.
  useEffect(() => {
    getUserSettings().then((settings: IUserSetting[]) => {
      Object.keys(initialNotificationStates).map((notificationKey: string) => {
        const strValue = settings?.find((setting: IUserSetting) => setting.name === notificationKey)?.value || 'true'; // if not set yet: -> default to True
        initialNotificationStates[notificationKey] = stringToBoolean(strValue);
      });

      // Should use the below syntax to update state which is an object
      setNotificationSettings(() => ({ ...initialNotificationStates }));
    });
  }, []); // Only happens at 1st load

  const closeTab = () => {
    window.close(); // can only close if the tab was opened by "window.open" before (default browser behavior)

    // if the tab cannot be closed, go to home page
    /**
    This is kind of a trick: after the tab is closed, other subsequent code won't be executed

    There is a standard behavior here: our SPA can never close the a browser's tab (modern browsers nowadays) if it is not open by the SPA.
    => So if the Settings page is opened by clicking an item inside dropdown menu (under avatar) -> our <a target="__blank"> will open Settings page in a new tab -> in this case, the window.close() can close the tab (containing this Settings page)

    However, if users access by themselves the Settings page by direct link (e.g. https://smap.test.moonisgood.com/settings)
    => in this case, window.close() won't do anything!
    */
    setTimeout(() => {
      history.push('/home');
    }, 300);
  };

  const handleNotificationSettingToggle = (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>, // default by "react-switch" library
    id: string,
  ) => {
    // cannot have 'dot' inside DOM id (reverted)
    editUserSettings({ name: customReplaceAll(id, '-', '.'), value: String(checked) }).then(
      (newSetting: IUserSetting) => {
        const newState = notificationSettings;
        newState[newSetting.name] = stringToBoolean(newSetting.value);

        // Should use the below syntax to update state which is an object
        setNotificationSettings(() => ({ ...newState }));
      },
    );
  };

  return (
    <PageWrapper>
      <Row>
        <Col md={9}>
          <div className={styles.title}>Settings</div>
          <h3 className={styles.section}>Notification</h3>
          <hr />
          {SETTINGS.NOTIFICATION_TYPES.map((option) => (
            <div key={option.id}>
              <div key={option.id}>
                <Row className={styles.titleWrapper}>
                  <Col md={3} className={styles.optionTitle}>
                    {option.name}
                  </Col>
                  <Col md={7}>{option.description}</Col>
                  <Col md={2}>
                    <Switch
                      id={customReplaceAll(option.id, '.', '-')} // cannot have 'dot' inside DOM id
                      className={styles.switch}
                      onChange={handleNotificationSettingToggle}
                      checked={notificationSettings[option.id]}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor={ACTIVE_COLOR}
                    />
                  </Col>
                </Row>
              </div>
              <hr />
            </div>
          ))}
          <div className={styles.buttonWrapper}>
            <div id="backButton" className={styles.backButton} onClick={closeTab}>
              Back
            </div>
          </div>
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default SettingsPage;
