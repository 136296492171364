import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useWindowFocus from 'use-window-focus';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { useCurrentUser } from './useCurrentUser';

export const ACTS_ON_BEHALF_OF_KEY = 'smapActOnBehalfEmail';

export interface ActsOnBehalfOfLocalStorageValues {
  email: string;
  startedAt: string;
}

export const useActsOnBehalfOf = () => {
  const history = useHistory();
  const { refetch } = useCurrentUser();
  const windowFocused = useWindowFocus();

  useEffect(() => {
    if (values) {
      const timeLapsed = differenceInMinutes(new Date(), new Date(values.startedAt));

      if (timeLapsed >= 30) {
        dismissActsOnBehalfOf();
      }
    }
  }, [windowFocused]);

  // localStorag.getItem returns either a string or null
  // JSON.parse(null) returns null
  // therefore, actsOnBehalfOfValues from localStorage is either an object or null
  const actsOnBehalfOfValues = localStorage.getItem(ACTS_ON_BEHALF_OF_KEY) as any;
  const values = JSON.parse(actsOnBehalfOfValues) as ActsOnBehalfOfLocalStorageValues | null;

  const actsOnBehalfOf = (email: string) => {
    localStorage.setItem(ACTS_ON_BEHALF_OF_KEY, JSON.stringify({ email, startedAt: new Date() }));
    refetch();
    history.push('/home');
  };

  const dismissActsOnBehalfOf = () => {
    localStorage.removeItem(ACTS_ON_BEHALF_OF_KEY);
    refetch();
    history.push('/admin/on-behalf-of');
  };

  return { values, actsOnBehalfOf, dismissActsOnBehalfOf };
};
