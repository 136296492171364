import styles from './index.module.scss';

interface IProps {
  title: string;
  shortDescription: string;
  longDescription?: string;
}

const EvaluationFormHeader = ({ title, shortDescription, longDescription }: IProps) => {
  return (
    <div>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.shortDescription}>{shortDescription}</p>
      {longDescription && <p className={styles.longDescription}>{longDescription}</p>}
    </div>
  );
};

export default EvaluationFormHeader;
