import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import formatDistance from 'date-fns/formatDistance';
import useOnClickOutside from 'use-onclickoutside';
import styles from './index.module.scss';
import ProfileAvatar from '../ProfileAvatar';
import { IIdeaComment, ReactQueryKey } from '../../util/types';
import { useMutation } from 'react-query';
import { deleteIdeaComment, updateIdeaComment } from '../../util/api';
import { queryClient } from '../../util/client';
import { ReactComponent as ThreeDotsIcon } from '../../assets/icons/threeDots.svg';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import AlertModal from '../AlertModal';
import RichTextPreview from '../RichTextPreview';
import RichTextInput from '../RichTextInput';
import { Button } from '../Button';

interface IProps {
  comment: IIdeaComment;
}

const IdeaComment = ({ comment }: IProps) => {
  const { data: currentUser } = useCurrentUser();
  const optionsModalRef = useRef(null);
  const [optionsModalVisible, setOptionsModalVisible] = useState<boolean>(false);
  const deleteCommentMutation = useMutation(deleteIdeaComment);
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [commentValue, setCommentValue] = useState(comment.content);

  useOnClickOutside(optionsModalRef, () => setOptionsModalVisible(false));

  const isAuthorCurrentUser = comment.user.id === currentUser?.id;

  const handleShowAlertModal = () => {
    setOptionsModalVisible(false);
    setIsAlertModalVisible(true);
  };

  const handleDeleteComment = () => {
    if (!isAuthorCurrentUser) return;

    deleteCommentMutation.mutate(comment.id, {
      onSuccess: () => queryClient.invalidateQueries(ReactQueryKey.IdeaComments),
    });
  };

  const handleEditComment = () => {
    setIsEditing(true);
    setOptionsModalVisible(false);
  };

  const handleUpdateComment = async () => {
    if (!isAuthorCurrentUser) return;
    await updateIdeaComment(comment.id, commentValue).then(() => {
      queryClient.invalidateQueries(ReactQueryKey.IdeaComments);
      setIsEditing(false);
    });
  };

  return (
    <div className={classnames(styles.ideaComment, isEditing && styles.ideaComment__editing)}>
      {isAuthorCurrentUser ? (
        <div className={styles.ideaComment__options}>
          <ThreeDotsIcon
            className={styles.ideaComment__optionsIcon}
            onClick={() => setOptionsModalVisible(!optionsModalVisible)}
            data-cy="idea-comment-setting-icon"
          />
          {optionsModalVisible ? (
            <div ref={optionsModalRef} className={styles.ideaComment__optionsModal}>
              <div className={styles.ideaComment__option} onClick={handleEditComment}>
                Edit comment
              </div>
              <div
                className={styles.ideaComment__option}
                data-danger
                data-cy="idea-comment-delete-icon"
                onClick={handleShowAlertModal}
              >
                Delete comment
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className={styles.authorContainer}>
        <ProfileAvatar avatarUrl={comment?.user?.avatar?.url} firstName={comment?.user?.givenName} />
        <div className={styles.author}>
          <span className={styles.authorName}>
            {comment.user.givenName} {comment.user.familyName}
          </span>
        </div>
      </div>

      {isEditing ? (
        <div>
          <RichTextInput value={commentValue} onChange={(e: any) => setCommentValue(e)} data-cy="idea-comment-input" />
          <div className={styles.editingButtons}>
            <Button variant="outline" onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
            <Button variant="black" onClick={handleUpdateComment}>
              Save
            </Button>
          </div>
        </div>
      ) : (
        <>
          <RichTextPreview value={comment.content} data-cy="idea-comment" />
          <div className={styles.commentedAt}>
            {formatDistance(new Date(comment.createdAt), new Date(), { addSuffix: true })}
          </div>
          <AlertModal
            title="Delete your comment"
            body="Are you sure you want to delete this comment?"
            isVisible={isAlertModalVisible}
            cancel={{ text: 'Cancel', onClick: () => setIsAlertModalVisible(false) }}
            confirm={{ text: 'Delete', onClick: handleDeleteComment }}
          />
        </>
      )}
    </div>
  );
};

export default IdeaComment;
