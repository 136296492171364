import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getUserIdeas } from '../../util/api';
import { ideaUrl } from '../../util/helpers';
import { IUserShort, ReactQueryKey, Uuid } from '../../util/types';
import IdeaCard from '../IdeaCard';
import styles from './index.module.scss';

interface IProps {
  currentIdeaId: Uuid;
  user: IUserShort;
}

const OtherIdeas = ({ currentIdeaId, user }: IProps) => {
  const { data: userIdeas } = useQuery([ReactQueryKey.UserIdeas, { userId: user.id }], () => getUserIdeas(user.id));

  const otherIdeas = userIdeas?.data.filter((userIdea) => userIdea.id !== currentIdeaId);

  if (!otherIdeas || otherIdeas.length === 0) return null;

  return (
    <div className={styles.otherIdeas}>
      <h2 className={styles.otherIdeas__header}>Other ideas by {user.givenName}</h2>
      <div className={styles.otherIdeas__ideas}>
        {otherIdeas.map((idea) => (
          <Link to={ideaUrl(idea)} key={idea.id}>
            <IdeaCard idea={idea} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default OtherIdeas;
