import classnames from 'classnames';
import { useState } from 'react';
import { ReactComponent as ChevronDownIcon } from '../../assets/icons/chevronDown.svg';
import { IUser } from '../../util/types';
import ProfileAvatar from '../ProfileAvatar';
import UserProfileMenu from '../UserProfileMenu';
import styles from './index.module.scss';

interface IProps {
  user: IUser;
}

const AdminUserProfilePanel = ({ user }: IProps) => {
  const [showUserProfileMenu, setShowUserProfileMenu] = useState(false);

  return (
    <div className={styles.wrapper}>
      <ProfileAvatar small avatarUrl={user.avatar?.url} firstName={user.givenName} />
      <div
        className={classnames(styles.iconWrapper, showUserProfileMenu && styles.isActive)}
        onClick={() => setShowUserProfileMenu(true)}
      >
        <ChevronDownIcon className={styles.icon} />
      </div>
      {showUserProfileMenu && <UserProfileMenu onAdmin dismiss={() => setShowUserProfileMenu(false)} />}
    </div>
  );
};

export default AdminUserProfilePanel;
