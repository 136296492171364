import classnames from 'classnames';
import { Link } from 'react-router-dom';
import noImage from '../../assets/img/noImage.png';
import { capitalizeWord, transformImage } from '../../util/helpers';
import { ISubmissionsFilters } from '../../util/types';
import { useSubmissions } from '../../hooks/useSubmissions';
import { useError } from '../../hooks/useError';
import Grid from '../Grid';
import EvaluationCountBadge from '../EvaluationCountBadge';
import IdeaVisibilityStatus from '../IdeaVisibilityStatus';
import { Button } from '../Button';
import styles from './index.module.scss';

interface IProps {
  filters: ISubmissionsFilters;
}

const AdminIdeas = ({ filters }: IProps) => {
  const {
    data: ideasData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    error,
  } = useSubmissions({ filters });
  const { handleError } = useError();

  if (isLoading) return <p>loading...</p>;

  const ideas = ideasData?.pages.flatMap((page) => page.data) || [];

  if (!ideas) return null;
  if (isError) handleError(error);

  // TODO: handle empty state
  if (ideas.length === 0) <p>No ideas submitted</p>;

  const columns = [
    <span>Ideas</span>,
    <span>Owner</span>,
    <span>Evaluations</span>,
    <span>Phase</span>,
    <span>Status</span>,
  ];

  const rows = ideas.map((idea) => [
    <span className={styles.ideaColumn}>
      <img className={styles.image} src={transformImage({ imageUrl: idea.image?.url }) || noImage} alt="idea image" />
      <div className={styles.nameAndDescription}>
        <Link to={`/admin/ideas/${idea.id}`}>
          <div className={styles.name}>{idea.name}</div>
          <div className={styles.description}>{idea.description}</div>
        </Link>
      </div>
    </span>,
    <span>{[idea.author.givenName, idea.author.familyName].join(' ')}</span>,
    <EvaluationCountBadge evaluations={idea.evaluations} />,
    <span className={classnames(styles.phase, styles[idea.phase])}>{capitalizeWord(idea.phase)}</span>,
    <IdeaVisibilityStatus isVisibleToNonAuthors={idea.isVisibleToNonAuthors} />,
  ]);

  return (
    <div className={styles.adminIdeas}>
      <Grid columns={columns} rows={rows} data-testid="ideas-list" />
      {hasNextPage && (
        <Button variant="outline" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
          Load more
        </Button>
      )}
    </div>
  );
};

export default AdminIdeas;
