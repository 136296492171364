import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import Switch from 'react-switch';
import { Row, Col } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import ReactGA from 'react-ga';
import { createCookie, cookieExists, eraseCookie, clearIntercomCookies, clearGACookies } from '../../util/helpers';
import cookiesBg from '../../assets/img/cookiesBg.svg';
import styles from './index.module.scss';
import { useCurrentUser } from '../../hooks/useCurrentUser';

const CONSENT_GIVEN_COOKIE_NAME = 'rcl_consent_given';
const FUNCTIONAL_COOKIE_NAME = 'rcl_preferences_consent';
const PERFORMANCE_COOKIE_NAME = 'rcl_statistics_consent';
const ACTIVE_COLOR = '#2f80ed';

const CookiePreferencesPage = () => {
  const [allowFunctional, setAllowFunctional] = useState(cookieExists(FUNCTIONAL_COOKIE_NAME));
  const [allowPerformance, setAllowPerformance] = useState(cookieExists(PERFORMANCE_COOKIE_NAME));

  const history = useHistory();

  const { data: currentUser } = useCurrentUser();

  const { boot: bootIntercom, shutdown: shutDownIntercom } = useIntercom();

  useEffect(() => {
    if (allowFunctional === true) {
      createCookie(FUNCTIONAL_COOKIE_NAME, 'true', 365); // alive for 1 year
      if (currentUser?.id) {
        bootIntercom({
          name: currentUser.givenName,
          email: currentUser.emailAddress,
          createdAt: currentUser.createdAt.toDateString(),
        });
      }

      return;
    }

    // Functional cookie is unchecked:
    shutDownIntercom();
    eraseCookie(FUNCTIONAL_COOKIE_NAME);
    clearIntercomCookies();
  }, [allowFunctional]);

  useEffect(() => {
    if (allowPerformance === true) {
      createCookie(PERFORMANCE_COOKIE_NAME, 'true', 365); // alive for 1 year
      ReactGA.initialize(process.env.REACT_APP_GA_ID || ''); // blank string so it passes typescript check

      return;
    }

    // Performance cookie is unchecked:
    eraseCookie(PERFORMANCE_COOKIE_NAME);
    clearGACookies();
  }, [allowPerformance]);

  const acceptAll = () => {
    setAllowFunctional(true);
    setAllowPerformance(true);

    setTimeout(() => {
      acceptAndExit();
    }, 300);
  };

  const acceptAndExit = () => {
    if (!cookieExists(CONSENT_GIVEN_COOKIE_NAME)) {
      createCookie(CONSENT_GIVEN_COOKIE_NAME, 'true', 365); // alive for 1 year
    }
    window.close(); // can only close if the tab was opened by "window.open" before (default browser behavior)

    // if the tab cannot be closed, go to previous page
    history.goBack();
  };

  const handleChangeFunctional = () => {
    setAllowFunctional(!allowFunctional);
  };
  const handleChangePerformance = () => {
    setAllowPerformance(!allowPerformance);
  };

  return (
    <PageWrapper>
      <Row>
        <Col md={6}>
          <div className={styles.title}>Cookie Preferences</div>
          <p>
            SMAP uses cookies to ensure that you are given the best experience on our website. Some of these cookies are
            essential, while others help us to improve your experience by providing insights into how the site is used.
            <br />
            <a className={styles.link} href="/privacy-policy" target="__blank">
              See our Privacy Policy
            </a>
          </p>
          <br />
          <div>
            <div className={styles.titleWrapper}>
              <h4 className={styles.cookieTitle}>Necessary Cookies</h4>
            </div>
            <p>
              These cookies enable core functionality. The website cannot function properly without these cookies which
              <b> can only be disabled by changing your browser preferences.</b>
            </p>
            <br />
            <div className={styles.titleWrapper}>
              <h4 className={styles.cookieTitle}>Functional Cookies</h4>
              <Switch
                className={styles.switch}
                onChange={handleChangeFunctional}
                checked={allowFunctional}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={ACTIVE_COLOR}
              />
            </div>
            <p>
              We use these cookies to tailor the content and experience you receive on our website.
              <br />
              <b>Intercom</b>: helps us build customer relationships through conversational, messenger-based experiences
              across the customer journey. For more information, check their&nbsp;
              <a className={styles.link} href="https://www.intercom.com/legal/privacy" target="__blank">
                Privacy Policy
              </a>
            </p>
            <br />
            <div className={styles.titleWrapper}>
              <h4 className={styles.cookieTitle}>Performance Cookie</h4>
              <Switch
                className={styles.switch}
                onChange={handleChangePerformance}
                checked={allowPerformance}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={ACTIVE_COLOR}
              />
            </div>
            <p>
              We use these cookies to monitor and improve website performance.
              <br />
              We use <b>Google Analytics</b> to analyze the use of our website. For more information about Google’s
              privacy practices, see their&nbsp;
              <a className={styles.link} href="https://policies.google.com/privacy" target="__blank">
                Privacy Policy
              </a>
            </p>
            <br />
          </div>
          <div className={styles.buttonWrapper}>
            <div className={styles.acceptAllButton} onClick={acceptAll}>
              Accept All
            </div>
            <div className={styles.confirmButton} onClick={acceptAndExit}>
              Confirm
            </div>
          </div>
        </Col>
        <Col md={6} className="d-none d-md-block">
          <img src={cookiesBg} className={styles.cookiesBg} alt="" />
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default memo(CookiePreferencesPage);
