import { useQuery } from 'react-query';
import { Redirect, useParams } from 'react-router';
import ChallengeFormPreview from '../../components/ChallengeFormPreview';
import PageWrapper from '../../components/PageWrapper';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { getChallenge } from '../../util/api';
import { capitalizeWord } from '../../util/helpers';
import { IUrlParams, IUserPermission, ReactQueryKey } from '../../util/types';
import styles from './index.module.scss';

const AdminChallengeFormPreviewPage = () => {
  const { hasPermission } = useCurrentUser();
  const { challengeId, formId } = useParams<IUrlParams>();
  const { data: challenge } = useQuery([ReactQueryKey.Challenge, { challengeId }], () => getChallenge(challengeId));

  const submissionForm = challenge?.forms.find((form) => form.id === formId);
  const evaluationForm = challenge?.evaluationForms?.find((form) => form.id === formId);

  const form = submissionForm || evaluationForm;

  if (
    !hasPermission(IUserPermission.CanSeeAdminUI) ||
    (evaluationForm && !hasPermission(IUserPermission.CanSeeEvaluationForms))
  ) {
    return <Redirect to="/admin" />;
  }

  return (
    <PageWrapper>
      {challenge && form && (
        <>
          <h1 className={styles.header}>
            {challenge.title}: {capitalizeWord(form.type)} Form Preview
          </h1>
          <ChallengeFormPreview form={form} />
        </>
      )}
    </PageWrapper>
  );
};

export default AdminChallengeFormPreviewPage;
