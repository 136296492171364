import { useQuery } from 'react-query';
import { getEvaluation } from '../../util/api';
import { IForm, IFormField, IFormFieldType, ReactQueryKey, Uuid } from '../../util/types';
import styles from './index.module.scss';

interface IProps {
  form: IForm;
  evaluationId: Uuid;
}

const EvaluationForm = ({ form, evaluationId }: IProps) => {
  const { data: evaluation, isLoading } = useQuery([ReactQueryKey.Evaluation, { evaluationId }], () =>
    getEvaluation(evaluationId),
  );

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const evaluationFieldValueByFormField = (formField: IFormField) =>
    evaluation?.fields.find((field) => field.fieldId === formField.id)?.value;

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.formFields}>
          {form.fields.map((field) => (
            <div key={field.id}>
              <label className={styles.label}>{field.label}</label>
              {field.description && <div className={styles.description}>{field.description}</div>}
              {
                {
                  // TODO: Refactor into TextAreaField, RangeField, CheckBoxField components in a later PR
                  // when we support different kind of value display (i.e. Rich Text)
                  [IFormFieldType.TextArea]: <p className={styles.field}>{evaluationFieldValueByFormField(field)}</p>,
                  [IFormFieldType.Range]: <p className={styles.field}>{evaluationFieldValueByFormField(field)}</p>,
                  [IFormFieldType.CheckBox]: <p className={styles.field}>{evaluationFieldValueByFormField(field)}</p>,
                  // TODO: Add the blob field in a later PR
                  [IFormFieldType.Blob]: null,
                }[field.type]
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EvaluationForm;
