import { formatDate, humanizeChallengeDefaultSubmissionVisibility } from '../../util/helpers';
import { IChallenge } from '../../util/types';
import RichTextPreview from '../RichTextPreview';
import styles from './index.module.scss';

interface IProps {
  challenge: IChallenge;
}

const AdminChallengeDescription = ({ challenge }: IProps) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.header}>Description</h3>

      <div className={styles.sections}>
        <section>
          <h4 className={styles.sectionHeader}>Content</h4>
          <RichTextPreview value={challenge.content} isSanitized={false} />
        </section>

        <section>
          <h4 className={styles.sectionHeader}>Published At</h4>
          <p className={styles.sectionParagraph}>{challenge.publishedAt ? formatDate(challenge.publishedAt) : 'n/a'}</p>
        </section>

        <section>
          <h4 className={styles.sectionHeader}>Starts At</h4>
          <p className={styles.sectionParagraph}>{challenge.startsAt ? formatDate(challenge.startsAt) : 'n/a'}</p>
        </section>

        <section>
          <h4 className={styles.sectionHeader}>Ends At</h4>
          <p className={styles.sectionParagraph}>{challenge.endsAt ? formatDate(challenge.endsAt) : 'n/a'}</p>
        </section>

        <section>
          <h4 className={styles.sectionHeader}>Default Submission Visibility</h4>
          <p className={styles.sectionParagraph}>
            {humanizeChallengeDefaultSubmissionVisibility(challenge.defaultSubmissionVisibility)}
          </p>
        </section>
      </div>
    </div>
  );
};

export default AdminChallengeDescription;
