import {
  GET_CHALLENGE_LIST,
  GET_CHALLENGE_LIST_SUCCESS,
  GET_CHALLENGE_DETAIL,
  CREATE_CHALLENGE,
  CREATE_FORM,
  CREATE_TEXT_QUESTION,
  CREATE_RANGE_QUESTION,
} from '../actionConstants';

export const getChallengeList = () => ({
  type: GET_CHALLENGE_LIST,
});
export const getChallengeListSuccess = (challengeList) => ({
  type: GET_CHALLENGE_LIST_SUCCESS,
  payload: { challengeList },
});
export const getChallengeDetail = ({ id }) => ({
  type: GET_CHALLENGE_DETAIL,
  payload: { id },
});
export const createChallenge = ({ title, content, startsAt, endsAt }) => ({
  type: CREATE_CHALLENGE,
  payload: { title, content, startsAt, endsAt },
});
export const createForm = ({ challengeId, title, shortDescription, longDescription }) => ({
  type: CREATE_FORM,
  payload: { challengeId, title, shortDescription, longDescription },
});
export const createTextQuestion = ({ formId, type, isRequired, label, description, placeHolder, rows }) => ({
  type: CREATE_TEXT_QUESTION,
  payload: { formId, type, isRequired, label, description, placeHolder, rows },
});
export const createRangeQuestion = ({
  formId,
  type,
  isRequired,
  label,
  description,
  from,
  to,
  fieldDefault,
  fromLabel,
  toLabel,
}) => ({
  type: CREATE_RANGE_QUESTION,
  payload: { formId, type, isRequired, label, description, from, to, fieldDefault, fromLabel, toLabel },
});
