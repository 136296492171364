import ReactSwitch from 'react-switch';
import styles from './index.module.scss';

interface IProps {
  name: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
  dataTestId?: string;
}

const Switch = ({ name, checked, onChange, className, dataTestId }: IProps) => {
  return (
    <ReactSwitch
      name={name}
      data-testid={dataTestId}
      checked={checked}
      onChange={onChange}
      className={className}
      width={60}
      offColor="#E5E7EB"
      onColor="#000000"
      handleDiameter={34}
      checkedIcon={false}
      uncheckedIcon={false}
      boxShadow="inset 0 0 0 1px #E5E7EB, 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
      activeBoxShadow="0 0 1px 2px #2E7FEC"
    />
  );
};

export default Switch;
