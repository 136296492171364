import styles from './index.module.scss';
import { useState } from 'react';
import AdminPageTitle from '../../components/AdminPageTitle';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { IUserPermission, IGetUserShortsFilters, ISelectOption, IGetUserShortsFiltersRequest } from '../../util/types';
import DatePicker from '../../components/DatePicker';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import HookFormTextInput from '../../components/HookFormTextInput';
import HookFormSelect from '../../components/HookFormSelect';
import Switch from '../../components/Switch';
import { Button } from '../../components/Button';
import AdminUserSearchResults from '../../components/AdminUserSearchResults';

const AdminUserSearchPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm();

  const [matchToFind, setMatchToFind] = useState<IGetUserShortsFiltersRequest>({ match: '' });
  const [isAdvancedActive, setIsAdvancedActive] = useState(false);

  const { hasPermission } = useCurrentUser();

  const onSubmit = (data: IGetUserShortsFilters) => {
    const match: IGetUserShortsFiltersRequest = { match: data.match };
    // TODO: find a way to make this more legible
    Object.entries(data).forEach((pair, i) => {
      if (i !== 0 && pair[1] !== undefined) {
        if (pair[1] instanceof Date) {
          match[pair[0] as string] = pair[1].toISOString();
        } else {
          const option = pair[1] as ISelectOption<string>;
          if (option.value !== '') {
            match[pair[0] as string] = option.value;
          }
        }
      }
    });
    setMatchToFind(match);
  };

  const mapOptions = (value: string, i: number) => ({
    label: value,
    value: i < 2 ? (i === 0).toString() : '',
  });

  const approvalSelectOptions: ISelectOption<string>[] = [
    'Approved only',
    'Unapproved only',
    'Approved and unapproved users',
  ].map(mapOptions);

  const acceptedSelectOptions: ISelectOption<string>[] = [
    'Accepted only',
    'Unaccepted only',
    'Accepted and unaccepted users',
  ].map(mapOptions);

  return (
    <div>
      <AdminPageTitle text="User Search" />
      <div>
        {/* TODO: move permission check to advanced toggle button only? */}
        {hasPermission(IUserPermission.CanRunAdvancedUserSearch) ? (
          <>
            <div className={styles.sectionTitle} data-testid="section-title">
              Search for users
            </div>
            <div className={styles.filters}>
              <form>
                <HookFormTextInput
                  name="match"
                  label="Name or email:"
                  rules={{ required: 'This form is required' }}
                  register={register}
                  errors={errors}
                  dataTestId="match-input"
                />
                <div>
                  <div className={styles.switchRow}>
                    <Switch
                      name={'advanced-switch'}
                      checked={isAdvancedActive}
                      onChange={(value) => {
                        reset({ match: getValues('match') });
                        setIsAdvancedActive(value);
                      }}
                      dataTestId="use-advanced-switch"
                    />
                    <label htmlFor="advanced-switch">Use advanced options</label>
                  </div>
                  {isAdvancedActive ? (
                    <div className={styles.advanced}>
                      <HookFormSelect
                        name="approved"
                        label="Search approved users?"
                        placeholder="Select one"
                        options={approvalSelectOptions}
                        control={control}
                        errors={errors}
                      />
                      <HookFormSelect
                        name="touAccepted"
                        label="Search TOU accepted users?"
                        placeholder="Select one"
                        options={acceptedSelectOptions}
                        control={control}
                        errors={errors}
                      />
                      <HookFormSelect
                        name="ppAccepted"
                        label="Search privacy policy accepted users?"
                        placeholder="Select one"
                        options={acceptedSelectOptions}
                        control={control}
                        errors={errors}
                      />
                      <HookFormSelect
                        name="ipConsentAccepted"
                        label="Search IP consent accepted users?"
                        placeholder="Select one"
                        options={acceptedSelectOptions}
                        control={control}
                        errors={errors}
                      />

                      <section className={styles.date}>
                        <label className={styles.label}>Search users created before:</label>
                        <Controller
                          control={control}
                          name="createdBefore"
                          render={({ field }) => {
                            const { ref, ...props } = field;
                            return (
                              <DatePicker
                                {...props}
                                name={field.name}
                                selected={field.value}
                                onChange={field.onChange}
                                dataTestId="created-before-date-picker"
                              />
                            );
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="createdBefore"
                          render={({ message }) => <span style={{ color: 'red' }}>*{message}</span>}
                        />
                      </section>

                      <section className={styles.date}>
                        <label className={styles.label}>Search users created after:</label>
                        <Controller
                          control={control}
                          name="createdAfter"
                          rules={{
                            validate: {
                              beforeCreatedBefore: (value) =>
                                !value ||
                                (watch('createdBefore') && new Date(value) < new Date(watch('createdBefore'))) ||
                                'Created After date must be before Created Before date',
                            },
                          }}
                          render={({ field }) => {
                            const { ref, ...props } = field;
                            return (
                              <DatePicker
                                {...props}
                                name={field.name}
                                selected={field.value}
                                onChange={field.onChange}
                                dataTestId="created-after-date-picker"
                              />
                            );
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="createdAfter"
                          render={({ message }) => <span style={{ color: 'red' }}>*{message}</span>}
                        />
                      </section>
                    </div>
                  ) : null}
                </div>
                <Button
                  data-testid="submit"
                  variant="black"
                  onClick={handleSubmit(onSubmit)}
                  className={styles.searchButton}
                >
                  Search
                </Button>
              </form>
            </div>
          </>
        ) : (
          <div>No permission to run advanced user search!</div>
        )}
      </div>
      {/* TODO: autoscroll to these results when successfully submitted */}
      <AdminUserSearchResults matchToFind={matchToFind} />
    </div>
  );
};

export default AdminUserSearchPage;
