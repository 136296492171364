import { client as FilestackClient, PickerOptions } from 'filestack-react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { openMessageModal } from '../redux/actions';
import { uploadBlobRequest } from '../util/api';
import { ERROR_MESSAGES, MAINTENANCE_STATUS_CODE } from '../util/constants';
import {
  FileStackAcceptedImageType,
  FileStackFromSource,
  FileStackMaxSize,
  IError,
  IFileStackOptions,
  IModalType,
} from '../util/types';

export const useFilestack = () => {
  const dispatch = useDispatch();
  const uploadBlobMutation = useMutation(uploadBlobRequest);

  // openFilestack default
  // - accept all image type
  // - maxSize 10MB
  // - fromSources local_file_system
  const openFilestack = async ({
    originType,
    originId,
    onFileUploadFinished,
    onFileSelected,
    accept = FileStackAcceptedImageType.All,
    maxSize = FileStackMaxSize.Image,
    fromSources = [FileStackFromSource.LocalFileSystem],
  }: IFileStackOptions) => {
    try {
      const uploadResponse = await uploadBlobMutation.mutateAsync({ originType, originId });
      const options = {
        accept,
        maxSize,
        maxFiles: 1,
        fromSources,
        transformations: { circle: false, crop: true, rotate: true },
        storeTo: {
          location: uploadResponse.location,
          path: uploadResponse.path,
          container: uploadResponse.container,
          region: uploadResponse.region,
        },
        uploadConfig: { tags: uploadResponse.tags },
        onFileUploadFinished,
        onFileSelected,
      } as PickerOptions;

      FilestackClient.init(uploadResponse.filestackKey).picker(options).open();
    } catch (err: any) {
      // TODO: Typescript 4.4 handles the catch "error" as an unkonwn type instead of "any" as before.
      // Properly handle the error return by server case by case in a later PR
      // https://devblogs.microsoft.com/typescript/announcing-typescript-4-4/#use-unknown-catch-variables
      const error = err.error as IError;
      let errorMessage = '';
      const errorContext = error.status === MAINTENANCE_STATUS_CODE ? '' : ERROR_MESSAGES.SUBMISSION_ERROR_CONTEXT;

      if (Array.isArray(error.data)) {
        errorMessage = error.data.map((item: any) => item.errorCode).join('\n');
      } else {
        errorMessage = error.data.message;
      }

      dispatch(
        openMessageModal({
          messageModalTitle: ERROR_MESSAGES.ERROR,
          messageModalContent: `${errorContext}${errorMessage}`,
          messageModalType: IModalType.Error,
          messageModalButtonText: 'I understand',
          messageModalButtonAction: null,
        }),
      );
    }
  };

  return { openFilestack };
};
