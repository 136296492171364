import { useState } from 'react';
import { IForm, IFormFieldType, IFormType, ISelectOption } from '../../util/types';
import Select from '../Select';
import BlobFormFieldForm from '../BlobFormFieldForm';
import CheckboxFormFieldForm from '../CheckboxFormFieldForm';
import RangeFormFieldForm from '../RangeFormFieldForm';
import TextAreaFormFieldForm from '../TextAreaFormFieldForm';

interface IProps {
  form: IForm;
  onSubmit?: () => void;
}

const FormFieldCreateForm = ({ form, onSubmit }: IProps) => {
  const [selectedType, setSelectedType] = useState<ISelectOption<IFormFieldType>>();

  const typeOptions: ISelectOption<IFormFieldType>[] = Object.values(IFormFieldType).map((type) => ({
    label: type as string,
    value: type,
  }));

  const evaluationFilterTypedOption = typeOptions.filter((option) => option.value !== IFormFieldType.Blob);
  const submissionFilterTypedOption = typeOptions.filter(
    (option) => option.value !== IFormFieldType.Range && option.value !== IFormFieldType.CheckBox,
  );

  const filteredTypeOptions = {
    [IFormType.Evaluation]: evaluationFilterTypedOption,
    [IFormType.Submission]: submissionFilterTypedOption,
  };

  return (
    <div>
      <h4>Create a form field</h4>
      <Select
        name="type"
        inputId="type"
        options={filteredTypeOptions[form.type]}
        placeholder="Select one"
        value={selectedType}
        onChange={(type: ISelectOption<IFormFieldType>) => setSelectedType(type)}
      />

      {selectedType &&
        {
          [IFormFieldType.TextArea]: <TextAreaFormFieldForm form={form} onSubmit={onSubmit} />,
          [IFormFieldType.Range]: <RangeFormFieldForm form={form} onSubmit={onSubmit} />,
          [IFormFieldType.CheckBox]: <CheckboxFormFieldForm form={form} onSubmit={onSubmit} />,
          [IFormFieldType.Blob]: <BlobFormFieldForm form={form} onSubmit={onSubmit} />,
        }[selectedType.value]}
    </div>
  );
};

export default FormFieldCreateForm;
