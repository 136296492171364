import { IBlobFormField, IBlobType, IFormField, IFormFieldType } from '../../util/types';
import { SecondaryButton } from '../Button';
import styles from './index.module.scss';

interface IProps {
  field: IFormField;
  value: any;
}

// TODO: Unfinished as it still does not handle Checkbox nor Range
const SubmissionFieldValue = ({ field, value }: IProps) => {
  switch (field.type) {
    case IFormFieldType.TextArea:
      return <p className={styles.textAreaValue}>{value}</p>;

    case IFormFieldType.Blob:
      const blobField = field as IBlobFormField;

      return {
        [IBlobType.Video]: (
          <video className={styles.video} data-testid={`video-playback-${blobField.id}`} controls src={value.url} />
        ),
        [IBlobType.Document]: (
          <a href={value.url} target="_" data-testid={`document-link-${blobField.id}`}>
            <SecondaryButton>Link to File</SecondaryButton>
          </a>
        ),
        [IBlobType.Image]: <img src={value.url} width="500px" />,
      }[blobField.blobType];

    default:
      return null;
  }
};

export default SubmissionFieldValue;
