import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';
import { useAuthenticate } from '../../hooks/useAuthenticate';
import styles from './index.module.scss';

interface IProps {
  dismiss: () => void;
  canSeeAdmin?: boolean;
  onAdmin?: boolean;
}

const UserProfileMenu = ({ dismiss, canSeeAdmin = false, onAdmin = false }: IProps) => {
  const { logOut } = useAuthenticate();
  const menuRef = useRef(null);
  useOnClickOutside(menuRef, dismiss);

  const handleLogout = () => {
    dismiss();
    logOut();
  };

  return (
    <div ref={menuRef} className={styles.wrapper}>
      <div className={styles.container}>
        <Link to="/settings" className={styles.item} data-cy="settings-button" target="_blank">
          Settings
        </Link>
        {canSeeAdmin && (
          <Link to="/admin" className={styles.item} data-cy="admin-portal-button">
            Admin Portal
          </Link>
        )}
        {onAdmin && (
          <Link to="/home" className={styles.item} data-cy="user-portal-button">
            User Portal
          </Link>
        )}
        <div className={styles.item} onClick={handleLogout}>
          Sign Out
        </div>
      </div>
    </div>
  );
};

export default UserProfileMenu;
