import { useQuery } from 'react-query';
import { getChallengeUserGroups } from '../../util/api';
import { IChallenge, ReactQueryKey } from '../../util/types';
import ChallengeUserGroup from '../ChallengeUserGroup';
import styles from './index.module.scss';

interface IProps {
  challenge: IChallenge;
}

const ChallengeUserGroups = ({ challenge }: IProps) => {
  const { data: challengeUserGroupsData } = useQuery([ReactQueryKey.UserGroups, { challengeId: challenge.id }], () =>
    getChallengeUserGroups(challenge.id),
  );

  const challengeUserGroups = challengeUserGroupsData?.data;

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.header}>User Groups with access</h3>
      {challengeUserGroups && (
        <div className={styles.container} data-testid="challenge-user-groups">
          {challengeUserGroups.map((userGroup) => (
            <ChallengeUserGroup key={userGroup.id} userGroup={userGroup} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ChallengeUserGroups;
