import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ReactComponent as LightbulbIcon } from '../../assets/icons/lightbulb.svg';
import styles from './index.module.scss';
import classNames from 'classnames';

const MyIdeaNavItem = () => {
  const location = useLocation();
  const isFocus = location.pathname === '/my/ideas';

  return (
    <NavLink to="/my/ideas">
      <LightbulbIcon className={classNames(styles.icon, isFocus && styles.isFocus)} />
    </NavLink>
  );
};

export default MyIdeaNavItem;
