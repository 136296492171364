import classnames from 'classnames';
import { useState } from 'react';
import { ReactComponent as TrashBinIcon } from '../../assets/icons/trashBin.svg';
import { deleteFormField } from '../../util/api';
import {
  IBlobFormField,
  ICheckBoxFormField,
  IFormField,
  IFormFieldType,
  IRangeFormField,
  ITextAreaFormField,
} from '../../util/types';
import AlertModal from '../AlertModal';
import TextAreaFormField from '../TextAreaFormField';
import RangeFormField from '../RangeFormField';
import BlobFormField from '../BlobFormField';
import CheckBoxFormField from '../CheckBoxFormField';
import styles from './index.module.scss';

interface IProps {
  field: IFormField;
  isPreview?: boolean;
  onDelete?: () => Promise<void>;
}

const FormField = ({ field, onDelete, isPreview = false }: IProps) => {
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false);

  const handleDeleteFormField = async (field: IFormField) =>
    await deleteFormField(field.id)
      .then(() => {
        onDelete && onDelete();
        setIsAlertModalVisible(false);
      })
      .catch((e: any) => console.error(e));

  return (
    <div key={field.id} className={classnames(styles.formField, isPreview && styles.isPreview)}>
      <h4 className={styles.header}>
        {field.label}
        <span className={styles.isRequired}> (*)</span>
      </h4>

      {Boolean(onDelete) && (
        <TrashBinIcon className={styles.trashBinIcon} onClick={() => setIsAlertModalVisible(true)} />
      )}
      {field.description && <div>{field.description}</div>}
      {
        {
          [IFormFieldType.TextArea]: <TextAreaFormField field={field as ITextAreaFormField} />,
          [IFormFieldType.Range]: <RangeFormField field={field as IRangeFormField} />,
          [IFormFieldType.CheckBox]: <CheckBoxFormField field={field as ICheckBoxFormField} />,
          [IFormFieldType.Blob]: <BlobFormField field={field as IBlobFormField} />,
        }[field.type]
      }

      <AlertModal
        title="Delete field"
        body="Are you sure you want to delete this field?"
        isVisible={isAlertModalVisible}
        cancel={{ text: 'Cancel', onClick: () => setIsAlertModalVisible(false) }}
        confirm={{ text: 'Delete', onClick: () => handleDeleteFormField(field) }}
      />
    </div>
  );
};

export default FormField;
