import Select from 'react-select';
import { useWindowWidth } from '@react-hook/window-size';
import { IChallenge, Uuid } from '../../util/types';
import { MOBILE_WIDTH } from '../../util/constants';

export interface IChallengeSelectOption {
  label: string;
  value: Uuid;
}

interface IProps {
  value?: Uuid;
  onChange: (event: IChallengeSelectOption | null) => void;
  challenges: IChallenge[];
  isClearable?: boolean;
  placeholder?: string;
}

const ChallengeSelect = ({ value, onChange, challenges, isClearable, placeholder }: IProps) => {
  const onlyWidth = useWindowWidth();

  const options: IChallengeSelectOption[] = challenges.map((challenge) => ({
    label: challenge.title,
    value: challenge.id,
  }));

  const valueOption = options.find((option) => option.value === value);

  const customStyles = {
    container: (styles: any) => ({
      ...styles,
      width: onlyWidth > MOBILE_WIDTH ? '19rem' : '100%',
      marginBottom: '1rem',
      zIndex: 10,
    }),
    option: (styles: any) => ({
      ...styles,
      cursor: 'pointer',
    }),
    control: (styles: any) => ({
      ...styles,
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  };

  const customTheme = (theme: any) => ({
    ...theme,
    borderRadius: 6,
    colors: { ...theme.colors, primary25: '#F3F4F6', primary50: '#F3F4F6' },
  });

  return (
    <form role="form">
      <label hidden htmlFor="challenge">
        Challenge
      </label>
      <Select
        name="challenge"
        inputId="challenge"
        value={valueOption}
        styles={customStyles}
        placeholder={placeholder}
        theme={customTheme}
        options={options}
        onChange={onChange}
        isClearable={isClearable}
        isSearchable={false}
      />
    </form>
  );
};

export default ChallengeSelect;
