import { useQuery } from 'react-query';
import { Redirect } from 'react-router-dom';
import FullScreenLoading from '../../components/FullScreenLoading';
import PageWrapper from '../../components/PageWrapper';
import UserChallengeList from '../../components/UserChallengeList';
import { getChallenges } from '../../util/api';
import { challengeUrl } from '../../util/helpers';
import { ReactQueryKey } from '../../util/types';
import styles from './index.module.scss';

const UserChallengeListPage = () => {
  const { data: challengesData, isLoading } = useQuery(ReactQueryKey.Challenges, getChallenges);

  if (isLoading) return <FullScreenLoading />;

  const challenges = challengesData?.data || [];

  // If a user has access to only one published, not closed challenge,
  // automatically redirect them to the splash page. Assuming we use
  // challenge groups appropriately, this will result in 1 challenge
  // for non Moon users.
  const publishedNotClosedChallenges = challenges.filter(
    (c) => c.publishedAt && c.publishedAt <= new Date() && c.endsAt && c.endsAt <= new Date(),
  );
  if (publishedNotClosedChallenges.length === 1) {
    return <Redirect to={challengeUrl(challenges[0])} />;
  }

  return (
    <PageWrapper>
      <div className={styles.pageTitle}>Submit an Idea to Get Started</div>
      <div className={styles.pageDescription}>
        Ready to start building an idea you’re passionate about? You’ve come to the right place. We know even the
        biggest ideas start small and take time to develop. That’s why we’re building a community dedicated to helping
        develop your ideas with you right here on SMAP. Our goal is to help your ideas grow and succeed.
      </div>
      <div className={styles.challengeListWrapper}>
        <UserChallengeList />
      </div>
    </PageWrapper>
  );
};

export default UserChallengeListPage;
