import { Card, Carousel } from 'react-bootstrap';
import styles from './index.module.scss';
import noImage from '../../assets/img/noImage.png';
import ProfileAvatar from '../ProfileAvatar';
import { ReactComponent as ChatBubbleIcon } from '../../assets/icons/chatBubble.svg';
import { transformImage } from '../../util/helpers';
import { NAME_PLACEHOLDER, DESC_PLACEHOLDER } from '../../util/constants';
import { useCurrentUser } from '../../hooks/useCurrentUser';

interface IProps {
  name: string;
  description: string;
  imageUrl: string;
  videoUrls?: string[];
}

const IdeaCardPreview = ({ name, description, imageUrl, videoUrls }: IProps) => {
  const { data: currentUser, fullName } = useCurrentUser();

  // TODO: Temporary remove carousel until it's proper addressed
  return (
    <Card className={styles.wrapper} data-cy="idea-card">
      <img className={styles.blob} src={transformImage({ imageUrl, size: 'base' }) || noImage} role="image" />
      {/* <div className={styles.carouselWrapper}>
        <Carousel indicators={true} interval={null}>
          <Carousel.Item className={styles.blobWrapper}>
            <img className={styles.blob} src={transformImage({ imageUrl, size: 'base' }) || noImage} role="image" />
          </Carousel.Item>

           {videoUrls?.map((url) => (
            <Carousel.Item key={url} className={styles.blobWrapper}>
              <video controls className={styles.blob} src={url} role="video" />
            </Carousel.Item>
          ))}
        </Carousel>
      </div> */}

      <Card.Body>
        <h1 className={styles.name}>{name || NAME_PLACEHOLDER}</h1>
        <p className={styles.description}>{description || DESC_PLACEHOLDER}</p>
        <div className={styles.ideaOwnerAndIdeaCommentsWrapper}>
          <div className={styles.ideaOwnerContainer}>
            <ProfileAvatar small avatarUrl={currentUser?.avatar?.url || noImage} firstName={name} />
            <div className={styles.ideaOwnerName}>{fullName}</div>
          </div>
          <div className={styles.comment}>
            <ChatBubbleIcon className={styles.chatBubblIcon} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default IdeaCardPreview;
