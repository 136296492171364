import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ReactComponent as ReadingBookSitting } from '../../assets/img/readingBookSitting.svg';
import { getChallenges } from '../../util/api';
import { ideaUrl, sortedIdeasBySpotlightTag } from '../../util/helpers';
import useChallengeFilters from '../../hooks/useChallengeFilters';
import { useSubmissions } from '../../hooks/useSubmissions';
import {
  IDefaultSubmissionTag,
  IIdeaStatus,
  ISubmissionFindDirection,
  ISubmissionFindOrder,
  ISubmissionPhaseFilter,
  ReactQueryKey,
} from '../../util/types';
import { useError } from '../../hooks/useError';
import IdeaCard from '../../components/IdeaCard';
import PageWrapper from '../../components/PageWrapper';
import FullScreenLoading from '../../components/FullScreenLoading';
import EmptyState from '../../components/EmptyState';
import ChallengeSelect, { IChallengeSelectOption } from '../../components/ChallengeSelect';
import { Button } from '../../components/Button';
import styles from './index.module.scss';

const INITIAL_FILTER = {
  status: IIdeaStatus.Published,
  includeRestrictedChallenges: true,
  includeInvisibleSubmissions: true,
  includeEvaluations: false,
  phase: ISubmissionPhaseFilter.All,
  order: ISubmissionFindOrder.Interesting,
  direction: ISubmissionFindDirection.Desc,
};

const PublicIdeasPage = () => {
  const [filters, setFilters] = useChallengeFilters({ initialFilters: INITIAL_FILTER, defaultPath: '/ideas' });
  const { handleError } = useError();
  const { data: challengesData } = useQuery(ReactQueryKey.Challenges, getChallenges);
  const { data: spotlightIdeasData } = useSubmissions({
    filters: { ...filters, tag: IDefaultSubmissionTag.Spotlight },
  });
  const {
    data: ideasData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    error,
  } = useSubmissions({ filters });

  if (isLoading) return <FullScreenLoading />;
  if (isError) handleError(error);

  const challenges = challengesData?.data || [];
  const publishedChallenges = challenges.filter((c) => c.publishedAt && c.publishedAt <= new Date());

  const spotlightIdeas = spotlightIdeasData?.pages.flatMap((page) => page.data) || [];
  const allIdeas = ideasData?.pages.flatMap((page) => page.data) || [];
  const sortedIdeas = sortedIdeasBySpotlightTag(spotlightIdeas, allIdeas);

  const handleOnChallengeSelectChange = (event: IChallengeSelectOption | null) =>
    event?.value ? setFilters({ ...filters, challenge: event.value }) : setFilters(INITIAL_FILTER);

  return (
    <PageWrapper className={styles.publicIdeasPage}>
      <h1 className={styles.publicIdeasPage__title}>Idea Library</h1>
      <ChallengeSelect
        value={filters.challenge}
        onChange={handleOnChallengeSelectChange}
        challenges={publishedChallenges}
        isClearable
        placeholder="All challenges"
      />
      {sortedIdeas.length === 0 ? (
        <div className={styles.publicIdeasPage__emptyStateWrapper}>
          <EmptyState
            title="No ideas to see here (yet)"
            subtitle="Currently, there are no published ideas, but no worries, we are working on it!"
            Icon={ReadingBookSitting}
          />
        </div>
      ) : (
        <div className={styles.publicIdeasPage__ideas}>
          {sortedIdeas.map((idea) => (
            <Link to={ideaUrl(idea)} key={idea.id}>
              <IdeaCard idea={idea} />
            </Link>
          ))}
        </div>
      )}
      {hasNextPage && (
        <Button variant="outline" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
          Load more
        </Button>
      )}
    </PageWrapper>
  );
};

export default PublicIdeasPage;
