import React, { useCallback, memo, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Row } from 'react-bootstrap';
import IdeaCard from '../../components/IdeaCard';
import PageWrapper from '../../components/PageWrapper';
import styles from './index.module.scss';
import queryString from 'query-string';
import { IIdeaStatus, ISubmissionFindOrder } from '../../util/types';
import { ideaUrl } from '../../util/helpers';
import { useSubmissions } from '../../hooks/useSubmissions';
import { Button } from '../../components/Button';

const UserIdeasPage = () => {
  const history = useHistory();

  const { tab } = queryString.parse(location.search);
  const ideaStatus = tab === IIdeaStatus.Draft ? IIdeaStatus.Draft : IIdeaStatus.Published;
  const {
    data: myIdeasList,
    refetch: myIdeasRefetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useSubmissions({
    filters: {
      authorAlias: 'ME',
      order: ISubmissionFindOrder.LastUpdated,
      status: ideaStatus,
      includeInvisibleSubmissions: true,
    },
  });

  useEffect(() => {
    myIdeasRefetch();
  }, [ideaStatus]);

  const changeTab = useCallback(
    (newIdeaStatus) => {
      if (newIdeaStatus === ideaStatus) {
        return;
      }

      history.replace(`?tab=${newIdeaStatus}`);
    },
    [ideaStatus],
  );

  const myIdeas = myIdeasList?.pages.flatMap((page) => page.data) || [];

  return (
    <PageWrapper className={styles.userIdeasPage}>
      <h1 className={styles.title}>My Ideas</h1>
      <nav>
        <ul className={styles.pageTabs}>
          <li
            onClick={() => changeTab(IIdeaStatus.Published)}
            className={classnames(styles.tab, ideaStatus === IIdeaStatus.Published ? styles.activeTab : '')}
          >
            Submitted Ideas
          </li>
          <li
            onClick={() => changeTab(IIdeaStatus.Draft)}
            className={classnames(styles.tab, ideaStatus === IIdeaStatus.Draft ? styles.activeTab : '')}
          >
            Draft Ideas
          </li>
          <li className={styles.tabSlider} role="presentation"></li>
        </ul>
      </nav>
      <Row className={styles.ideaCards}>
        {myIdeas.map((idea) => (
          <Link to={ideaUrl(idea)} key={idea.id} className={classnames('col-md-6 col-lg-4', styles.ideaCardWrapper)}>
            <IdeaCard idea={idea} />
          </Link>
        ))}
      </Row>
      {hasNextPage && (
        <Button variant="outline" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
          Load more
        </Button>
      )}
    </PageWrapper>
  );
};

export default memo(UserIdeasPage);
