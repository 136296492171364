import React from 'react';

import styles from './index.module.scss';
import classNames from 'classnames';

interface IProps {
  isUnsaved: boolean;
  isSaving: boolean;
  confirm: () => void;
  reset: () => void;
}

const AdminUserInfoConfirm = ({ isUnsaved, isSaving, confirm, reset }: IProps) => {
  return (
    <div className={classNames(styles.container, (isUnsaved || isSaving) && styles.shown)}>
      <div>
        <h2>Save changes to user data?</h2>
      </div>
      {isUnsaved ? (
        <div className={styles.buttons}>
          <button className={styles.button} onClick={reset}>
            Reset
          </button>
          <button className={styles.button} onClick={confirm}>
            Save
          </button>
        </div>
      ) : null}
      {isSaving ? (
        <div>
          <h1>Saving!</h1>
        </div>
      ) : null}
    </div>
  );
};

export default AdminUserInfoConfirm;
