import { useQuery } from 'react-query';
import { getChallenge } from '../../util/api';
import { ReactQueryKey, Uuid } from '../../util/types';
import EvaluationEditForm from '../EvaluationEditForm';
import FullScreenLoading from '../FullScreenLoading';
import styles from './index.module.scss';

interface IProps {
  challengeId: Uuid;
  evaluationId: Uuid;
}

const EvaluationEditForms = ({ challengeId, evaluationId }: IProps) => {
  const { data: challenge, isLoading: isLoading } = useQuery([ReactQueryKey.Challenge, { challengeId }], () =>
    getChallenge(challengeId),
  );

  if (isLoading || !challenge) {
    return <FullScreenLoading />;
  }

  const evaluationForms = challenge.evaluationForms;
  const hasEvaluationForms = evaluationForms && evaluationForms.length !== 0;

  if (!hasEvaluationForms) {
    // TODO: Create evaluation call to action where there are no evaluation forms
    // May display different notice depends on admin permission.
    return <button>Create Evaluation form</button>;
  }

  return (
    <div className={styles.formsContainer}>
      {evaluationForms.map((form) => (
        <div key={form.id} data-testid={`form-${form.id}`}>
          <EvaluationEditForm form={form} evaluationId={evaluationId} />
        </div>
      ))}
    </div>
  );
};

export default EvaluationEditForms;
