import classnames from 'classnames';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg';
import styles from './index.module.scss';

interface IAlertModalButton {
  text: string;
  onClick: () => void;
}
interface IProps {
  title: string;
  body: string | JSX.Element;
  isVisible: boolean;
  confirm: IAlertModalButton;
  cancel: IAlertModalButton;
}

const AlertModal = ({ title, body, isVisible, confirm, cancel }: IProps) => {
  return (
    <Modal data-cy="alert-modal" contentClassName={styles.content} centered show={isVisible} onHide={cancel.onClick}>
      <AlertIcon className={styles.icon} />
      <Modal.Title className={styles.title}>{title}</Modal.Title>
      <Modal.Body className={styles.body}>{body}</Modal.Body>
      <Modal.Footer className={styles.footer}>
        <Button className={classnames(styles.button, styles.cancel)} variant="outline-light" onClick={cancel.onClick}>
          {cancel.text}
        </Button>
        <Button className={styles.button} variant="danger" onClick={confirm.onClick}>
          {confirm.text}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
