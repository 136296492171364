import { memo, useState } from 'react';
import { Button } from '../Button';
import MailChimpForm from '../MailChimpForm';
import styles from './index.module.scss';

const NewLetterSignUps = () => {
  const [email, setEmail] = useState('');

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <MailChimpForm
          action="https://mooncreativelab.us20.list-manage.com/subscribe/post?u=d99815b9bbaab382f9236d1b5&amp;id=cf5d07672c"
          actionText="Submit"
          email={email}
          setEmail={setEmail}
          containerStyle={styles.mailChimpSubscribeForm}
          label="Sign up for Moon’s Newsletter"
          labelStyle={styles.text}
          placeholder="Enter your email address"
          inputActionContainerStyle={styles.inputActionContainerStyle}
          emailInputStyle={styles.emailInput}
          actionTextStyle={styles.subscribeButton}
        />
        <div className={styles.learnMore}>
          <span className={styles.text}>Want to know more about Moon?</span>
          <a target="_" href="https://www.mooncreativelab.com/">
            <Button variant="outline" className={styles.learnMoreButton}>
              Learn More
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default memo(NewLetterSignUps);
