import { useDispatch } from 'react-redux';
import { openMessageModal } from '../redux/actions';
import { ERROR_MESSAGES } from '../util/constants';
import { IError, IModalType } from '../util/types';

export const useError = () => {
  const dispatch = useDispatch();

  const handleError = (error: any, onConfirm?: () => void) => {
    const customError = error.error as IError;

    let errorCode: string;
    let errorMessage: string | undefined;

    if (Array.isArray(error.error.data)) {
      errorCode = error.error.data.map((item: any) => item.errorCode).join('\n');
    } else {
      errorCode = error.error.data.errorCode;
    }

    if (customError.status === 404) errorMessage = 'The requested resource does not exist.';

    dispatch(
      openMessageModal({
        messageModalTitle: ERROR_MESSAGES.ERROR,
        messageModalContent: errorMessage || `${ERROR_MESSAGES.SOMETHING_WENT_WRONG}: ${errorCode}`,
        messageModalType: IModalType.Error,
        messageModalButtonText: 'I understand',
        messageModalButtonAction: onConfirm || null,
      }),
    );

    return null;
  };

  return { handleError };
};
