import { NavLink } from 'react-router-dom';
import { SERVICE_NAME } from '../../util/constants';
import { IUserPermission } from '../../util/types';
import SMAP_LOGO from '../../assets/img/SMAP.svg';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import styles from './index.module.scss';

const AdminSideMenu = () => {
  const { hasPermission } = useCurrentUser();

  return (
    <div data-cy="admin-sidemenu" className={styles.wrapper}>
      <nav role="navigation" className={styles.nav}>
        <img src={SMAP_LOGO} alt={SERVICE_NAME} className={styles.logo} />

        <div className={styles.adminLinks} data-testid="nav-items">
          {hasPermission(IUserPermission.CanRunAdvancedUserSearch) && (
            <NavLink to={'/admin/user-search'} className={styles.adminLink} activeClassName={styles.adminLinkActive}>
              Users Management
            </NavLink>
          )}

          {/* TODO: we may want to consolidate this Preapproval page into 'Users Management' */}
          {hasPermission(IUserPermission.CanApproveUsers) && (
            <NavLink
              to={'/admin/preapprove-users'}
              className={styles.adminLink}
              activeClassName={styles.adminLinkActive}
            >
              User Preapproval
            </NavLink>
          )}

          {hasPermission(IUserPermission.CanCreateChallenge) && (
            <NavLink to={'/admin/challenges'} className={styles.adminLink} activeClassName={styles.adminLinkActive}>
              Challenges Management
            </NavLink>
          )}

          {hasPermission(IUserPermission.CanSeeSubmissionsForRestrictedChallenges) && (
            <NavLink to={'/admin/ideas'} className={styles.adminLink} activeClassName={styles.adminLinkActive}>
              Submissions Management
            </NavLink>
          )}

          {hasPermission(IUserPermission.CanManageUserGroups) && (
            <NavLink to={'/admin/user-groups'} className={styles.adminLink} activeClassName={styles.adminLinkActive}>
              User Groups Management
            </NavLink>
          )}
        </div>
      </nav>
    </div>
  );
};

export default AdminSideMenu;
