import { IRangeFormField } from '../../util/types';
import styles from './index.module.scss';

interface IProps {
  field: IRangeFormField;
}

const RangeFormField = ({ field }: IProps) => {
  const stepCount = field.to - field.from + 1;
  const steps = Array.from({ length: stepCount }, (_, key) => key + 1);

  return (
    <div className={styles.wrapper}>
      <div>{field.fromLabel}</div>
      <div className={styles.steps}>
        {steps.map((step) => (
          <div key={step} className={styles.step} data-testid="range-inputs">
            <input
              type="radio"
              value={step}
              defaultChecked={step === field.fieldDefault || step === field.fieldDefault}
              disabled
            />
            <label>{step}</label>
          </div>
        ))}
      </div>
      <div>{field.toLabel}</div>
    </div>
  );
};

export default RangeFormField;
