import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { getChallenge, getSubmission } from '../../util/api';
import { IUrlParams, IUserPermission, ReactQueryKey } from '../../util/types';
import { ideaUrl, transformImage } from '../../util/helpers';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import AdminPageTitle from '../../components/AdminPageTitle';
import BackButton from '../../components/BackButton';
import EvaluationButton from '../../components/EvaluationButton';
import IdeaVisibilityToggle from '../../components/IdeaVisibilityToggle';
import IdeaPhaseSelect from '../../components/IdeaPhaseSelect';
import FormsSubmission from '../../components/FormsSubmission';
import EvaluationSelect from '../../components/EvaluationSelect';
import CreateMemo from '../../components/CreateMemo';
import Memos from '../../components/Memos';
import EditPitchFeedback from '../../components/EditPitchFeedback';
import IdeaOwner from '../../components/IdeaOwner';
import SubmissionTags from '../../components/SubmissionTags';
import OtherIdeas from '../../components/OtherIdeas';
import styles from './index.module.scss';

const AdminIdeaDetailPage = () => {
  const { data: currentUser, hasPermission } = useCurrentUser();
  const { ideaId } = useParams<IUrlParams>();
  const { data: submission, isLoading } = useQuery([ReactQueryKey.Submission, { submissionId: ideaId }], () =>
    getSubmission({ ideaId, includeEvaluations: true }),
  );
  const challengeId = submission?.idea.challengeId;
  const { data: challenge } = useQuery(
    [ReactQueryKey.Challenge, { challengeId }],
    () => challengeId && getChallenge(challengeId),
    { enabled: !!challengeId },
  );

  const isEvaluationButtonVisible =
    challenge?.evaluationForms &&
    challenge.evaluationForms.length > 0 &&
    hasPermission(IUserPermission.CanEvaluateSubmissions);

  const currentUserIdeaEvaluation = submission?.idea.evaluations?.find(
    (evaluation) => evaluation.author.id === currentUser?.id,
  );

  const otherEvaluations = submission?.idea.evaluations?.filter(
    (evaluation) => evaluation.author.id !== currentUser?.id,
  );

  return (
    <div>
      <AdminPageTitle text="Submissions Management" />
      {isLoading && <p>loading...</p>}
      {submission && (
        <div>
          <div className={styles.actions}>
            <Link to={`/admin/ideas?challenge=${submission.idea.challengeId}`} data-testid="back-icon">
              <BackButton />
            </Link>

            <div className={styles.control}>
              {hasPermission(IUserPermission.CanTransitionSubmissionPhases) && (
                <EditPitchFeedback submission={submission} />
              )}
              {isEvaluationButtonVisible && (
                <EvaluationButton ideaId={ideaId} evaluationId={currentUserIdeaEvaluation?.id} />
              )}
              {hasPermission(IUserPermission.CanMemo) && <CreateMemo submissionId={ideaId} />}
              {hasPermission(IUserPermission.CanSeeEvaluationDetails) && otherEvaluations && (
                <div className={styles.viewOtherEvaluationsContainer}>
                  <EvaluationSelect evaluations={otherEvaluations} />
                </div>
              )}
              {hasPermission(IUserPermission.CanTransitionSubmissionPhases) && (
                <IdeaPhaseSelect idea={submission.idea} />
              )}
              {hasPermission(IUserPermission.CanChangeIdeaVisibility) && (
                <IdeaVisibilityToggle idea={submission.idea} data-testid="idea-visibility-button" />
              )}
            </div>
          </div>
          <h1 className={styles.name}>
            <Link to={ideaUrl(submission.idea)} target="_blank">
              {submission.idea.name}
            </Link>
          </h1>
          <div className={styles.container}>
            <div className={styles.main}>
              <div>
                <img
                  className={styles.image}
                  src={transformImage({ imageUrl: submission.idea.image?.url, size: 'banner' })}
                  alt="idea image"
                />
                {submission.idea.description && <div className={styles.description}>{submission.idea.description}</div>}
              </div>

              <div className={styles.submission}>
                <IdeaOwner ideaAuthor={submission.idea.author} />
                {challenge?.forms && challenge.forms.length !== 0 && (
                  <FormsSubmission forms={challenge.forms} submission={submission} />
                )}
              </div>
              <OtherIdeas currentIdeaId={submission.idea.id} user={submission.idea.author} />
            </div>

            <div className={styles.side}>
              <SubmissionTags
                tags={submission.idea.tags}
                submissionId={submission.idea.id}
                isEditable={hasPermission(IUserPermission.CanManageTags)}
              />
              <Memos submissionId={submission.idea.id} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminIdeaDetailPage;
