import React, { memo } from 'react';
import styles from './index.module.scss';

const BrowserNotSupported = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.brand}>SMAP</div>
      <div className={styles.container}>
        <p className={styles.messageText}>
          Thank you for accessing SMAP. For a better user experience please use Edge or Chrome.
        </p>
      </div>
    </div>
  );
};

export default memo(BrowserNotSupported);
