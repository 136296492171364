import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Switch from 'react-switch';
import classnames from 'classnames';
import { openMessageModal } from '../../redux/actions';
import { ReactComponent as TiltedRocketIcon } from '../../assets/icons/tiltedRocket.svg';
import { ReactComponent as RocketIcon } from '../../assets/icons/rocket.svg';
import { ReactComponent as QuestionMarkCircle } from '../../assets/icons/questionMarkCircle.svg';
import { queryClient } from '../../util/client';
import { IIdea, IIdeaVisibility, IModalType, ReactQueryKey } from '../../util/types';
import { updateIdeaVisibility } from '../../util/api';
import styles from './index.module.scss';
import { useError } from '../../hooks/useError';

interface IProps {
  idea: IIdea;
}

const IdeaVisibilityToggle = ({ idea }: IProps) => {
  const dispatch = useDispatch();

  const { handleError } = useError();

  const changeIdeaVisibility = async (ideaVisibility: IIdeaVisibility) => {
    await updateIdeaVisibility(idea.id, ideaVisibility).catch(handleError);
    queryClient.invalidateQueries([ReactQueryKey.Submission, { submissionId: idea.id }]);
  };

  const toggleIdeaVisibilityToAll = () => {
    dispatch(
      openMessageModal({
        messageModalTitle: 'You are about to publish an idea',
        messageModalContent:
          'By selecting to publish this idea, this idea will become visible to all users in the Idea Library. Do you want to proceed?',
        messageModalType: IModalType.ChangeIdeaVisibility,
        messageModalButtonText: 'Confirm',
        messageModalButtonAction: () => changeIdeaVisibility(IIdeaVisibility.All),
      }),
    );
    return;
  };

  const toggleIdeaVisibilityToPermissionOnly = () => {
    dispatch(
      openMessageModal({
        messageModalTitle: 'You are about to unpublish an idea',
        messageModalContent:
          'By selecting to un-publish this idea, this idea will no longer be visible to all users in the Idea Library. Do you want to proceed?',
        messageModalType: IModalType.ChangeIdeaVisibility,
        messageModalButtonText: 'Confirm',
        messageModalButtonAction: () => changeIdeaVisibility(IIdeaVisibility.PermissionOnly),
      }),
    );
    return;
  };

  const handleToggleIdeaVisibility = () => {
    idea.isVisibleToNonAuthors ? toggleIdeaVisibilityToPermissionOnly() : toggleIdeaVisibilityToAll();
  };

  return (
    <div className={styles.wrapper}>
      {idea.isVisibleToNonAuthors ? (
        <div className={styles.label}>
          <TiltedRocketIcon className={styles.switchLabelIcon} />
          <div>Published</div>
        </div>
      ) : (
        <div className={classnames(styles.label, styles.grayOut)}>
          <RocketIcon className={styles.switchLabelIcon} />
          <div>Unpublished</div>
        </div>
      )}
      <Switch
        checked={idea.isVisibleToNonAuthors}
        onChange={handleToggleIdeaVisibility}
        className={styles.switch}
        width={60}
        offColor="#E5E7EB"
        onColor="#2E7FEC"
        handleDiameter={34}
        checkedIcon={false}
        uncheckedIcon={false}
        boxShadow="inset 0 0 0 1px #E5E7EB, 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
        activeBoxShadow="0 0 1px 2px #2E7FEC"
      />
      <>
        <QuestionMarkCircle className={styles.questionMarkCircleIcon} data-tip="custom show" data-event="click focus" />
        <ReactTooltip globalEventOff="click" effect="solid" place="bottom">
          <ul className={styles.switchTooltipContent}>
            <li>
              Marking this idea as Published will make it visible on the Idea Library page to all users who have access
              to this Challenge.
            </li>
            <li>
              Marking it as Unpublished restricts visibility to Admins or specific users who have permission to view
              Challenges where visibility of submissions is limited.
            </li>
          </ul>
        </ReactTooltip>
      </>
    </div>
  );
};

export default IdeaVisibilityToggle;
