import classnames from 'classnames';
import styles from './index.module.scss';

interface IProps {
  columns: JSX.Element[];
  rows: JSX.Element[][];
}

const Grid = ({ columns, rows }: IProps) => {
  const isFirstColumnHeader = (index: number) => index === 0;
  const isLastColumnHeader = (index: number) => columns.length - 1 === index;
  const isEvenRow = (index: number) => (index + 1) % 2 === 0;

  return (
    <div className={styles.grid} style={{ gridTemplateColumns: `repeat(${columns.length}, auto)` }}>
      {columns.map((column, index) => (
        <div
          key={index}
          className={classnames(
            styles.columnHeader,
            isFirstColumnHeader(index) && styles.firstColumnHeader,
            isLastColumnHeader(index) && styles.lastColumnHeader,
          )}
          data-testid={`column-header-${index + 1}`}
        >
          {column}
        </div>
      ))}

      {rows.reduce(
        (previousValue: any[], currentValue: any[], currentIndex: number) => [
          ...previousValue,
          ...currentValue.map((cell: any, index: number) => (
            <div
              key={`${currentIndex}-${index}`}
              className={classnames(styles.gridCell, isEvenRow(currentIndex) && styles.even)}
              data-testid={`grid-cell-row-${currentIndex + 1}-column-${index + 1}`}
            >
              {cell}
            </div>
          )),
        ],
        [],
      )}
    </div>
  );
};

export default Grid;
