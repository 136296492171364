import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { getSubmission } from '../../util/api';
import { IUrlParams, ReactQueryKey } from '../../util/types';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Button } from '../../components/Button';
import EvaluationForms from '../../components/EvaluationForms';
import AdminPageTitle from '../../components/AdminPageTitle';
import BackButton from '../../components/BackButton';
import styles from './index.module.scss';

const AdminIdeaEvaluationPage = () => {
  const { data: currentUser } = useCurrentUser();
  const { ideaId, evaluationId } = useParams<IUrlParams>();
  const { data: submission, isLoading } = useQuery([ReactQueryKey.Submission, { submissionId: ideaId }], () =>
    getSubmission({ ideaId, includeEvaluations: true }),
  );

  const currentEvaluation = submission?.idea.evaluations?.find((evaluation) => evaluation.id === evaluationId);
  const isCurrentUserEvaluation = currentEvaluation?.author.id === currentUser?.id;

  return (
    <div>
      <AdminPageTitle text="Submissions Management" />
      {isLoading && <p>loading...</p>}
      {submission && (
        <div>
          <div className={styles.actions}>
            <Link to={`/admin/ideas/${ideaId}`} data-testid="back-icon">
              <BackButton />
            </Link>

            <div className={styles.control}>
              {isCurrentUserEvaluation && (
                <Link to={`/admin/ideas/${ideaId}/evaluations/${evaluationId}/edit`}>
                  <Button className={styles.editButton} variant="black">
                    Edit Evaluation
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <h1 className={styles.headerContainer}>
            <span className={styles.header}>Idea evaluation</span> /{' '}
            <span className={styles.ideaName}>{submission.idea.name}</span>{' '}
            {currentEvaluation && (
              <div className={styles.evaluator}>
                by {currentEvaluation.author.givenName} {currentEvaluation.author.familyName}
              </div>
            )}
          </h1>
          <EvaluationForms challengeId={submission.idea.challengeId} evaluationId={evaluationId} />
        </div>
      )}
    </div>
  );
};

export default AdminIdeaEvaluationPage;
