import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { GET_CHALLENGE_LIST, CREATE_CHALLENGE } from '../actionConstants';
import { startLoading, endLoading, getChallengeList, getChallengeListSuccess, openMessageModal } from '../actions';
import { createChallenge, getChallenges } from '../../util/api';
import { ERROR_MESSAGES } from '../../util/constants';
import { IBaseAction, IIdeaVisibility, IModalType } from '../../util/types';

export function* getChallengeListHanlder() {
  try {
    yield put(startLoading());

    const { data } = yield call(getChallenges);

    yield put(getChallengeListSuccess(data));

    yield put(endLoading());
  } catch {
    yield put(endLoading());

    yield put(
      openMessageModal({
        messageModalTitle: ERROR_MESSAGES.ERROR,
        messageModalContent: ERROR_MESSAGES.ERROR,
        messageModalType: IModalType.Error,
        messageModalButtonText: 'I understand',
        messageModalButtonAction: null,
      }),
    );
  }
}

export function* createChallengeHanlder(action: IBaseAction) {
  const {
    payload: { title, content, startsAt, endsAt },
  } = action;

  try {
    yield put(startLoading());

    // TODO: remove all redux on create challenge later it's not needed
    yield createChallenge({
      title,
      content,
      startsAt,
      endsAt,
      restrictSubmissions: false,
      defaultSubmissionVisibility: IIdeaVisibility.All,
    });

    yield put(getChallengeList());

    yield put(endLoading());
  } catch {
    yield put(endLoading());

    yield put(
      openMessageModal({
        messageModalTitle: ERROR_MESSAGES.ERROR,
        messageModalContent: ERROR_MESSAGES.ERROR,
        messageModalType: IModalType.Error,
        messageModalButtonText: 'I understand',
        messageModalButtonAction: null,
      }),
    );
  }
}

export default function* authSaga() {
  yield takeEvery(GET_CHALLENGE_LIST, getChallengeListHanlder);
  yield takeLatest(CREATE_CHALLENGE, createChallengeHanlder);
}
