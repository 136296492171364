import { useQuery } from 'react-query';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { IUserGroup, IUserShorts, ReactQueryKey } from '../../util/types';
import { getUserShortsByGroup } from '../../util/api';
import HookFormTextInput from '../HookFormTextInput';
import styles from './index.module.scss';
import HookFormTextAreaInput from '../HookFormTextAreaInput';

interface IProps {
  userGroup: IUserGroup;
  form: UseFormReturn<FieldValues, object>;
}

const UserGroupEditForm = ({ userGroup, form }: IProps) => {
  const { data: users } = useQuery([ReactQueryKey.UserShorts, userGroup.id], () => getUserShortsByGroup(userGroup.id));

  const {
    register,
    formState: { errors },
  } = form;

  const userEmails = (users: IUserShorts) => users.data.map((user) => user.emailAddress).join(', ');

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>User Group Detail</h3>
      <form className={styles.form} data-testid="user-group-edit-form">
        <HookFormTextInput
          name="name"
          label="Name"
          rules={{ required: 'name is required' }}
          register={register}
          errors={errors}
          dataTestId="name-input"
          defaultValue={userGroup.name}
        />
        <HookFormTextInput
          name="description"
          label="Description"
          register={register}
          errors={errors}
          dataTestId="description-input"
          defaultValue={userGroup.description}
        />
        <HookFormTextInput
          name="code"
          label="Code"
          rules={{ required: 'code is required' }}
          register={register}
          errors={errors}
          dataTestId="code-input"
          defaultValue={userGroup.code}
        />
        {users && (
          <HookFormTextAreaInput
            name="userEmails"
            label="User Emails"
            rows={3}
            register={register}
            errors={errors}
            dataTestId="user-emails-input"
            defaultValue={userEmails(users)}
          />
        )}
      </form>
    </div>
  );
};

export default UserGroupEditForm;
