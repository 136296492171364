import React, { memo, ReactNode } from 'react';
import styles from './index.module.scss';

interface IProps {
  children: ReactNode;
}

const NoLayout = ({ children }: IProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.mainContent}>{children}</div>
    </div>
  );
};

export default memo(NoLayout);
