import { useForm } from 'react-hook-form';
import { createRangeFormField } from '../../util/api';
import { queryClient } from '../../util/client';
import { IForm, IFormFieldRangeFormInputs, IFormFieldType, ReactQueryKey } from '../../util/types';
import { Button } from '../Button';
import HookFormSwitch from '../HookFormSwitch';
import HookFormTextInput from '../HookFormTextInput';
import styles from './index.module.scss';

interface IProps {
  form: IForm;
  onSubmit?: () => void;
}

const RangeFormFieldForm = ({ form, onSubmit }: IProps) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toSubmit = async (data: IFormFieldRangeFormInputs) => {
    const { label, description, isRequired, to, from, fieldDefault, toLabel, fromLabel } = data;

    await createRangeFormField({
      formId: form.id,
      type: IFormFieldType.TextArea,
      label,
      to,
      from,
      fieldDefault: fieldDefault || undefined,
      description: description || undefined,
      fromLabel: fromLabel || undefined,
      toLabel: toLabel || undefined,
      isRequired: Boolean(isRequired),
    })
      .then(() => {
        queryClient.invalidateQueries([ReactQueryKey.Challenge, { challengeId: form.challengeId }]);
        onSubmit && onSubmit();
      })
      .catch((e: any) => console.error(e));
  };

  return (
    <form data-testid="range-field-form" className={styles.form}>
      <HookFormTextInput
        name="label"
        label="Label"
        rules={{ required: 'label is required' }}
        register={register}
        errors={errors}
        dataTestId="range-label-input"
      />
      <HookFormTextInput
        name="description"
        label="Description"
        register={register}
        errors={errors}
        dataTestId="range-description-input"
      />
      <HookFormSwitch
        name="isRequired"
        label="Required"
        control={control}
        errors={errors}
        dataTestId="range-isRequired-switch"
      />
      <HookFormTextInput
        type="number"
        min="0"
        name="from"
        label="From"
        rules={{ required: 'required' }}
        register={register}
        errors={errors}
        dataTestId="range-from-input"
      />
      <HookFormTextInput
        type="number"
        min="1"
        name="to"
        label="To"
        rules={{ required: 'required' }}
        register={register}
        errors={errors}
        dataTestId="range-to-input"
      />
      <HookFormTextInput
        type="number"
        name="fieldDefault"
        label="Field Default"
        register={register}
        errors={errors}
        dataTestId="range-fieldDefault-input"
      />
      <HookFormTextInput
        name="fromLabel"
        label="From Label"
        register={register}
        errors={errors}
        dataTestId="range-fromLabel-input"
      />
      <HookFormTextInput
        name="toLabel"
        label="To Label"
        register={register}
        errors={errors}
        dataTestId="range-toLabel-input"
      />
      <Button variant="black" onClick={handleSubmit(toSubmit)} className={styles.addFormFieldButton}>
        Add form field
      </Button>
    </form>
  );
};

export default RangeFormFieldForm;
