import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import FullScreenLoading from '../../components/FullScreenLoading';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import styles from './index.module.scss';

const SignUpThankYouPage = () => {
  const history = useHistory();
  const { data: currentUser, isLoading } = useCurrentUser();

  if (isLoading) {
    return <FullScreenLoading />;
  }

  if (currentUser && currentUser.approved) {
    history.replace('/home');
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.brandMessageContainer}>
          <div className={styles.description}>
            Thank you for registering
            <br />
            your interest with SMAP!
          </div>
        </div>
        <p className={styles.smallMessage}>We will get in touch soon.</p>
      </div>
    </div>
  );
};

export default memo(SignUpThankYouPage);
