import React, { memo, useEffect } from 'react';
import PageWrapper from '../../components/PageWrapper';
import { PrimaryButton } from '../../components/Button';
import { transformImage } from '../../util/helpers';
import styles from './index.module.scss';

const OrganizationIdeasPage = () => {
  return (
    <PageWrapper>
      <div className={styles.challengeOverview}>
        <h1 className={styles.title}>Bring Innovation to Your Organization</h1>
        <div className={styles.content}>
          <img
            className={styles.bannerImage}
            src={transformImage({ imageUrl: 'https://cdn.filestackcontent.com/k2s16wpTQRSiw175deRw', size: 'banner' })}
          />
          <div className={styles.description}>
            <p>
              Moon Creative Lab supports Mitsui & Co. organizations that want to create a new business in support of its
              organizational goals and objectives. Organizations can mean business units, departments, subsidiaries,
              teams or task forces.
            </p>
            <p>
              Whether it’s expanding into an adjacent market for increased revenue and profit, defending against new
              competitors, or looking for a way to transform your industry – our team at Moon is here to help.
            </p>
            <p>
              There is no need to submit your idea into the SMAP Idea Library or pitch your idea to the Moon Committee.
              You do not need to have someone seconded to Moon. Resource allocation will be determined by business area
              and the location is not an issue.
            </p>
            <p>
              Moon is here to work flexibly with your organization to help Mitsui & Co.’s transform itself from
              ‘connecting to creating’. Our team is equipped with designers, product managers, engineers, data
              scientists and other skilled specialists who can help you with application and website development and
              even early product prototyping. Our team can work with you to develop your idea. We can take you from
              dreaming to doing -- from ideation to execution. Even if you don’t have a solid idea and just want to know
              more about Moon and what we can offer, let’s start the conversation there.
            </p>
          </div>
          <div className={styles.offerList}>
            <p>Once registered, you’ll receive:</p>
            <ul>
              <li>Brainstorming session with Moon Operating Team members</li>
              <li>Tailor-made support from Moon to fit your needs</li>
              <li>
                Some of the support we might offer include: conducting design research with users, developing a quick
                prototype to test and gather feedback, building an MVP, building live prototypes and much more.
              </li>
            </ul>
            <a className={styles.buttonWrapper} target="_" href="https://www.surveymonkey.com/r/P6BNCG3">
              <PrimaryButton>Start the Conversation</PrimaryButton>
            </a>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default memo(OrganizationIdeasPage);
