import React, { memo } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClientProvider } from 'react-query';
import { IntercomProvider } from 'react-use-intercom';
import { store, history } from './redux/store';
import { queryClient } from './util/client';
import { INTERCOM_APP_ID } from './util/constants';
import AppBootstrap from './components/AppBootstrap';
import GlobalErrorFallback, { globalErrorHandler } from './components/GlobalErrorFallback';

const App = () => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <ErrorBoundary FallbackComponent={GlobalErrorFallback} onError={globalErrorHandler}>
              <AppBootstrap />
            </ErrorBoundary>
          </ConnectedRouter>
        </Provider>
      </QueryClientProvider>
    </IntercomProvider>
  );
};

export default memo(App);
