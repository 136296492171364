import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { openMessageModal } from '../../redux/actions';
import { deleteChallenge, getChallenge, updateChallenge } from '../../util/api';
import { queryClient } from '../../util/client';
import { ERROR_MESSAGES } from '../../util/constants';
import { IChallengeFormData, IModalType, IUrlParams, IUserPermission, ReactQueryKey } from '../../util/types';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import AdminPageTitle from '../../components/AdminPageTitle';
import { Button } from '../../components/Button';
import BackButton from '../../components/BackButton';
import ChallengeEditForm from '../../components/ChallengeEditForm';
import styles from './index.module.scss';
import AlertModal from '../../components/AlertModal';
import { useError } from '../../hooks/useError';

const AdminChallengeEditPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { challengeId } = useParams<IUrlParams>();
  const { hasPermission } = useCurrentUser();
  const { data: challenge, isLoading } = useQuery(
    [ReactQueryKey.Challenge, { challengeId }],
    () => getChallenge(challengeId),
    { enabled: hasPermission(IUserPermission.CanUpdateAllChallenges) },
  );
  const updateChallengeForm = useForm({ reValidateMode: 'onSubmit' });
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false);

  // TODO: Add a submit evaluation error model
  const { handleError } = useError();

  const toUpdateChallenge = async (data: IChallengeFormData) => {
    // TODO: add a useServerErrorHandling hook to handle server request error in a later PR
    const { title, content, restrictSubmissions, defaultSubmissionVisibility, startsAt, endsAt, image } = data;
    await updateChallenge(challengeId, {
      title,
      content,
      restrictSubmissions,
      defaultSubmissionVisibility: defaultSubmissionVisibility.value,
      startsAt: startsAt ? new Date(startsAt) : undefined,
      endsAt: endsAt ? new Date(endsAt) : undefined,
      image: image ? image : undefined,
      publishedAt: challenge && challenge.publishedAt ? challenge.publishedAt : undefined,
    })
      .then(() => {
        queryClient.invalidateQueries([ReactQueryKey.Challenges, ReactQueryKey.Challenge, { challengeId }]);
        history.push(`/admin/challenges/${challengeId}`);
      })
      .catch(handleError);
  };

  const handleDeleteChallenge = async () => {
    await deleteChallenge(challengeId)
      .then(() => {
        queryClient.invalidateQueries(ReactQueryKey.Challenges);
        history.push('/admin/challenges');
      })
      .catch(handleError);
  };

  if (!isLoading && !challenge) {
    dispatch(
      openMessageModal({
        messageModalTitle: ERROR_MESSAGES.ERROR,
        messageModalContent: 'The challenge could not be found',
        messageModalType: IModalType.Error,
        messageModalButtonText: 'I understand',
        messageModalButtonAction: () => history.push('/admin/challenges'),
      }),
    );

    return null;
  }

  return (
    <div>
      <AdminPageTitle text="Challenges Management" />
      <div className={styles.container}>
        {isLoading && <p>loading...</p>}
        {challenge && (
          <>
            <div className={styles.actions}>
              <Link to={`/admin/challenges/${challengeId}`} className={styles.backButton} data-testid="back-icon">
                <BackButton />
              </Link>

              <div className={styles.control}>
                {hasPermission(IUserPermission.CanDeleteAllChallenges) && (
                  <Button
                    className={styles.button}
                    variant="black"
                    style={{ backgroundColor: '#EF4444' }}
                    onClick={() => setIsAlertModalVisible(true)}
                  >
                    Delete Challenge
                  </Button>
                )}
                <Button
                  className={styles.button}
                  variant="black"
                  onClick={updateChallengeForm.handleSubmit(toUpdateChallenge)}
                >
                  Update Challenge
                </Button>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.editForm}>
                <ChallengeEditForm challenge={challenge} form={updateChallengeForm} />
              </div>
            </div>

            <AlertModal
              title={`Delete the challenge: ${challenge.title}`}
              body={
                <div>
                  This action permanently deletes this challenge and all related submissions. Alternatively you can
                  change the published status of this challenge, which will allow you to make the challenge publicly
                  invisible to users but still accessible in the admin portal. The published status can be changed{' '}
                  <Link className={styles.link} to={`/admin/challenges/${challengeId}`}>
                    here
                  </Link>
                  . You will lose all submissions and evaluations to this challenge. We can not recover them once you
                  delete.
                  <br /> <br />
                  If you wish to proceed with <span className={styles.permanentlyDelete}>permanent deletion</span> of
                  this challenge, please confirm by selecting the option below:
                </div>
              }
              isVisible={isAlertModalVisible}
              cancel={{ text: 'Cancel', onClick: () => setIsAlertModalVisible(false) }}
              confirm={{ text: 'Yes, delete it', onClick: handleDeleteChallenge }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AdminChallengeEditPage;
