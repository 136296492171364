interface IProps {
  action: string;
  actionText: string;
  email: string;
  setEmail: any;
  placeholder: string;
  label: string;
  actionTextStyle: string;
  emailInputStyle: string;
  containerStyle: string;
  labelStyle: string;
  inputActionContainerStyle: string;
}

// Translate from Mailchimp embedded form snippet https://mailchimp.com/help/add-a-signup-form-to-your-website/
const MailChimpForm = ({
  action,
  email,
  setEmail,
  placeholder,
  label,
  containerStyle,
  actionText,
  labelStyle,
  emailInputStyle,
  actionTextStyle,
  inputActionContainerStyle,
}: IProps) => (
  <div id="mc_embed_signup">
    <form
      action={action}
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="validate"
      target="_blank"
      noValidate
    >
      <div className={containerStyle} id="mc_embed_signup_scroll">
        <label className={labelStyle} htmlFor="mce-EMAIL">
          {label}
        </label>
        <div className={inputActionContainerStyle}>
          <input
            className={emailInputStyle}
            type="email"
            value={email}
            name="EMAIL"
            id="mce-EMAIL"
            onChange={(e) => setEmail(e.target.value)}
            placeholder={placeholder}
            required
          />
          {/* From mailchimp: real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
            <input readOnly type="text" name="b_d99815b9bbaab382f9236d1b5_cf5d07672c" tabIndex={-1} value="" />
          </div>
          <div className="clear">
            <input
              type="submit"
              value={actionText}
              name="subscribe"
              id="mc-embedded-subscribe"
              className={actionTextStyle}
            />
          </div>
        </div>
      </div>
    </form>
  </div>
);

export default MailChimpForm;
