import { useState } from 'react';
import { useQuery } from 'react-query';
import { IUserPermission, ReactQueryKey } from '../../util/types';
import { approveUser, findUsersByIds, unapproveUser, updatePermissionsForUsers } from '../../util/api';
import { useParams } from 'react-router-dom';
import { IUrlParams } from '../../util/types';

import FullScreenLoading from '../../components/FullScreenLoading';
import AdminUserInfoPermissions from '../../components/AdminUserInfoPermissions';
import AdminUserInfoConfirm from '../../components/AdminUserInfoConfirm';
import { queryClient } from '../../util/client';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useError } from '../../hooks/useError';
import AdminUserInfoApproval from '../../components/AdminUserInfoApproval';
import styles from './index.module.scss';

const AdminUserInfoPage = () => {
  const { userId } = useParams<IUrlParams>();
  const { hasPermission } = useCurrentUser();
  const { handleError } = useError();

  const [isUnsaved, setIsUnsaved] = useState({ approval: false, permissions: false });
  const [isSaving, setIsSaving] = useState(false);
  const [reset, setReset] = useState(false);
  const [permissionsToSave, setPermissionsToSave] = useState<IUserPermission[]>([]);
  const [approvalToSave, setApprovalToSave] = useState<boolean>(false);

  const { data, isLoading } = useQuery([ReactQueryKey.Users, userId], () => findUsersByIds([userId], false));

  if (isLoading) {
    return <FullScreenLoading />;
  }
  if (!data) {
    return <h1>No data!</h1>;
  }
  const user = data[0];
  if (!user) {
    return <h1>No user found!</h1>;
  }

  const onPermissionsChange = (permissions: IUserPermission[], isChanged: boolean) => {
    setIsUnsaved({ ...isUnsaved, permissions: isChanged });
    if (!isChanged) {
      setReset(false);
    } else {
      setPermissionsToSave(permissions);
    }
  };

  const onApprovalChange = (approvalValue: boolean, isChanged: boolean) => {
    setIsUnsaved({ ...isUnsaved, approval: isChanged });
    if (!isChanged) {
      setReset(false);
    } else {
      setApprovalToSave(approvalValue);
    }
  };

  const clickReset = () => {
    setReset(true);
  };

  const onChangeSaved = () => {
    queryClient.invalidateQueries([ReactQueryKey.Users, userId]);
    setIsSaving(false);
  };

  const clickConfirm = () => {
    setIsSaving(true);
    setIsUnsaved({ approval: false, permissions: false });
    if (isUnsaved.permissions) {
      updatePermissionsForUsers({ [userId]: permissionsToSave })
        .then(onChangeSaved)
        .catch((error: any) => {
          handleError(error);
        });
    }
    if (isUnsaved.approval) {
      if (approvalToSave) {
        approveUser({ userId: user.id, userEmail: undefined })
          .then(onChangeSaved)
          .catch((error: any) => {
            handleError(error);
          });
      } else {
        unapproveUser({ userId: user.id, userEmail: undefined })
          .then(onChangeSaved)
          .catch((error: any) => {
            handleError(error);
          });
      }
    }
  };

  return (
    <div className={styles.userInfoPage}>
      <div className={styles.title}>{`User Management for ${user.givenName} ${user.familyName}`}</div>
      <AdminUserInfoApproval
        user={user}
        reset={reset}
        canView={hasPermission(IUserPermission.CanApproveUsers)}
        canEdit={hasPermission(IUserPermission.CanApproveUsers)}
        onApprovalChange={onApprovalChange}
      />
      <AdminUserInfoPermissions
        user={user}
        reset={reset}
        canList={hasPermission(IUserPermission.CanListUserPermissions)}
        canChange={hasPermission(IUserPermission.CanChangeUserPermissions)}
        onPermissionsChange={onPermissionsChange}
      />
      <AdminUserInfoConfirm
        isUnsaved={isUnsaved.permissions || isUnsaved.approval}
        isSaving={isSaving}
        confirm={clickConfirm}
        reset={clickReset}
      />
    </div>
  );
};

export default AdminUserInfoPage;
