import classnames from 'classnames';
import MDEditor, {
  ICommand,
  bold as editorBold,
  unorderedListCommand,
  orderedListCommand,
  italic as editorItalic,
  link as editorLink,
  codePreview,
  codeEdit,
} from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import { ReactComponent as UnOrderListIcon } from '../../assets/icons/unOrderList.svg';
import { ReactComponent as OrderedListIcon } from '../../assets/icons/orderList.svg';
import { ReactComponent as BoldIcon } from '../../assets/icons/bold.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';
import { ReactComponent as ItalicIcon } from '../../assets/icons/italic.svg';
import { ReactComponent as PenIcon } from '../../assets/icons/pen.svg';
import styles from './index.module.scss';

interface IProps {
  value: any;
  onChange: any;
  placeholder?: string;
  className?: string;
  height?: number;
  dataTestId?: string;
  isSanitized?: boolean;
}

const unorderedList: ICommand = { ...unorderedListCommand, icon: <UnOrderListIcon className={styles.icon} /> };
const orderedList: ICommand = { ...orderedListCommand, icon: <OrderedListIcon className={styles.icon} /> };
const bold: ICommand = { ...editorBold, icon: <BoldIcon className={styles.icon} /> };
const italic: ICommand = { ...editorItalic, icon: <ItalicIcon className={styles.icon} /> };
const link: ICommand = { ...editorLink, icon: <LinkIcon className={styles.icon} /> };
const customCodeEdit: ICommand = { ...codeEdit, icon: <PenIcon className={styles.icon} /> };
const customCodePreview: ICommand = { ...codePreview, icon: <div>preview</div> };

// RichTextInput sanitizes potential malicious user input by default.
// Be sure to keep the isSanitized prop in sync with RichTextPreview for consistency.
// For more information: https://github.com/rehypejs/rehype-sanitize
const RichTextInput = ({
  value,
  onChange,
  height,
  placeholder,
  className,
  dataTestId,
  isSanitized = true,
  ...props
}: IProps) => {
  const commands = [unorderedList, orderedList, bold, italic, link];
  const extraCommands = [customCodeEdit, customCodePreview];
  const commandsFilter = (cmd: any) => {
    const isFilteredCommands = /(live|fullscreen)/.test(cmd.name);
    return isFilteredCommands ? false : cmd;
  };

  const sanitize = isSanitized ? [rehypeSanitize] : [];
  const rehypePlugins = [...sanitize];

  return (
    <div className={styles.richTextInput}>
      <MDEditor
        {...props}
        className={classnames(styles.richTextInput__editor, className)}
        toolbarHeight={50}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        preview="edit"
        commands={commands}
        extraCommands={extraCommands}
        height={height}
        commandsFilter={commandsFilter}
        data-testid={dataTestId}
        previewOptions={{ rehypePlugins }}
      />
    </div>
  );
};

export default RichTextInput;
