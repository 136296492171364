import { slugify } from 'transliteration';
import { RefObject } from 'react';
import { DEFAULT_DATE_STYLE, FILESTACK_URL, INTERCOM_APP_ID, MOBILE_WIDTH, NEW_CONTENT_IDS } from './constants';
import {
  FileStackAcceptedFileType,
  FileStackAcceptedImageType,
  FileStackAcceptedVideoType,
  FileStackMaxSize,
  IBlobType,
  IChallenge,
  IDefaultSubmissionTag,
  IFormField,
  IFormFieldType,
  IForSearchKeyValue,
  IIdea,
  IIdeaVisibility,
  ISubmissionFormData,
  IUser,
  Uuid,
} from './types';
import noImage from '../assets/img/noImage.png';
import { format } from 'date-fns';
import { IBadgeColors } from '../components/Badge';

export const isMobile = window.innerWidth <= MOBILE_WIDTH;

// Detect browser: Internet Explorer 6-11
declare global {
  interface Document {
    documentMode?: number;
  }
}
export const isIE = /*@cc_on!@*/ false || !!window.document.documentMode;

export const collectFormData = (formRef: RefObject<HTMLFormElement>) => {
  // TODO: Needs proper typing
  // const formData: Array<ICreateTextAreaFormFieldVars | ICreateRangeFormFieldVars> = [];
  const formData: any = [];

  if (!formRef.current || formRef.current.elements.length === 0) {
    return formData;
  }

  const inputCount = formRef.current.elements.length;
  let index = 0;

  while (index < inputCount) {
    const processedInput = formRef.current.elements[index] as HTMLInputElement;

    if (processedInput.type === 'hidden') {
      // TODO: Needs proper typing
      // const newQuestion = {} as ICreateTextAreaFormFieldVars | ICreateRangeFormFieldVars;
      const newQuestion = {} as any;

      newQuestion.type = processedInput.name;

      const currentQuestionFieldCount = parseInt(processedInput.value);

      let i = 1;

      while (i <= currentQuestionFieldCount) {
        const currentInput = formRef.current.elements[index + 1] as HTMLInputElement;
        // To avoid extra input (created by 3rd party UI library like Select)
        // Inputs of all our questions' fields have name
        if (currentInput.name) {
          newQuestion[currentInput.name] = currentInput.value;
          i++;
        }
        index++;
      }
      formData.push(newQuestion);
    }
    index++;
  }

  return formData;
};

// TODO: Rework
export const collectSubmissionFormData = (formRef: RefObject<HTMLFormElement>) => {
  const formData: any = {};

  if (!formRef.current || formRef.current.elements.length === 0) {
    return formData;
  }

  for (let i = 0; i <= formRef.current.elements.length; i++) {
    if (formRef.current.elements[i]) {
      const fieldId = formRef.current.elements[i].getAttribute('data-fieldid');
      const fieldType = formRef.current.elements[i].getAttribute('data-fieldtype');
      if (fieldId) {
        const fieldValue = (formRef.current.elements[i] as HTMLInputElement).value;

        // just process blank-string case; in some cases, 'false' boolean can be a valid value
        if (fieldValue !== '') {
          switch (fieldType) {
            case IFormFieldType.Range:
              formData[fieldId] = parseInt(fieldValue);
              break;
            case IFormFieldType.Blob:
              const blobValue = JSON.parse(fieldValue);
              formData[fieldId] = blobValue;
              break;
            default:
              formData[fieldId] = fieldValue;
              break;
          }
        } else {
          formData[fieldId] = null;
        }
      }
    }
  }

  return formData;
};

export const arraySearchByKeyValuePair = ({ array, searchKey, searchValue }: IForSearchKeyValue) => {
  // Return all items that match
  return array.filter((item) => item[searchKey] === searchValue);
};

type ImageSize = 'small' | 'card' | 'base' | 'banner' | 'card-small';
export const transformImage = ({ imageUrl, size = 'base' }: { imageUrl?: string; size?: ImageSize }) => {
  const RESIZE_VALUE = {
    banner: 'width:800,height:450,f:crop', // 16:9 ratio
    base: 'width:800,height:600,f:crop', // 4:3 ratio
    card: 'width:300,height:168,f:crop', // 16:9 ratio
    'card-small': 'width:240,height:135,f:crop', // 16:9 ratio
    small: 'width:120,height:68,f:crop', // 16:9 ratio
  };

  if (!imageUrl) return noImage;

  if (!imageUrl?.includes(FILESTACK_URL)) return imageUrl;

  const fileId = imageUrl?.replace(`${FILESTACK_URL}/`, '');
  const resizeValue = RESIZE_VALUE[size];
  const qualityValue = 'v:50';

  return `${FILESTACK_URL}/quality=${qualityValue}/resize=${resizeValue}/compress/${fileId}`;
};

export const hasAcceptedAgreements = (user: IUser) =>
  Boolean(user.touAcceptedAt && user.ipConsentAcceptedAt && user.privacyPolicyAcceptedAt);

export const clearGACookies = () => {
  if (cookieExists('_ga')) {
    eraseCookie('_ga');
  }
  if (cookieExists('_gat')) {
    eraseCookie('_gat');
  }
  if (cookieExists('_gid')) {
    eraseCookie('_gid');
  }
};

export const clearIntercomCookies = () => {
  if (cookieExists(`intercom-session-${INTERCOM_APP_ID}`)) {
    eraseCookie(`intercom-session-${INTERCOM_APP_ID}`);
  }
};

export const createCookie = (name: string, value: string, days?: number) => {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = '; expires=' + date.toUTCString();
  } else {
    var expires = '';
  }

  document.cookie = name + '=' + value + expires + '; path=/';
};

export const cookieExists = (name: string) => {
  return document.cookie.split(';').some((c) => {
    return c.trim().startsWith(name + '=');
  });
};

export const eraseCookie = (name: string) => {
  createCookie(name, '', -1);
};

// Temporary indicator on "new" marcom contents (be updated manually until proper CMS implementation)
export const isNewMarcomContent = (id: string) => NEW_CONTENT_IDS.includes(id);

// TODO: Change to a safer and more extensive approach
// Convert date string to a Date object from the API response
// Inspired by https://stackoverflow.com/questions/65692061/casting-dates-properly-from-an-api-response-in-typescript
export const mutateDateStringToDate = (body: any) => {
  if (body === null || body === undefined || typeof body !== 'object') return body;

  for (const key of Object.keys(body)) {
    const value = body[key];

    if (isDateString(value)) {
      body[key] = new Date(value);
    } else if (typeof value === 'object') {
      mutateDateStringToDate(value);
    }
  }
};
// Regex from https://www.regextester.com/112232
const isISODateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;
const isDateString = (value: any): boolean => {
  return value && typeof value === 'string' && isISODateFormat.test(value);
};

export const formatDate = (date: Date, style: string = DEFAULT_DATE_STYLE) => format(date, style);

export const sortObjectsByKey = <T>(
  data: T[],
  keyToSort: keyof T,
  direction: 'ascending' | 'descending',
  isDate = false,
) => {
  const compare = (objectA: T, objectB: T) => {
    const valueA = isDate ? Date.parse(String(objectA[keyToSort])) : objectA[keyToSort];
    const valueB = isDate ? Date.parse(String(objectB[keyToSort])) : objectB[keyToSort];

    if (valueA === valueB) {
      return 0;
    }

    if (valueA > valueB) {
      return direction === 'ascending' ? 1 : -1;
    } else {
      return direction === 'ascending' ? -1 : 1;
    }
  };

  return data.sort(compare);
};

export const isChallengeNotStarted = (challenge: IChallenge) =>
  Boolean(challenge.startsAt && challenge.startsAt >= new Date());

export const isChallengeClosed = (challenge: IChallenge) => Boolean(challenge.endsAt && challenge.endsAt < new Date());

export const stringToBoolean = (value: string): boolean => value !== 'false';

export const customReplaceAll = (originalStr: string, replaceStr: string, byStr: string): string =>
  // cannot use the default replaceAll (only ready from Node 15.x) because current Jest tests will fail
  originalStr.split(replaceStr).join(byStr);

export const capitalizeWord = (word: string): string => {
  // Capitalize 1st letter only
  return `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;
};

// ******************************************
// **** START OF INFORMATIVE URL WARNING ****
// ******************************************
// IF YOU ARE TO MODIFY THE BELOW FUNCTIONS. MAKE SURE TO NOT BREAK THE URL
// BY CHANGING THE OUTPUT. CHECK COMPATIBILITY ON UPGRADING OR CHANGING
// THE SLUGIFY LIBRARY
//
// If the slugify string returns an empty string i.e. when the name
// are emojis, return a prefix with fist part of the object's uuid.
export const ideaUrl = (idea: IIdea): string => {
  const slugifyString = slugify(idea.name);
  const nameSlug = slugifyString.length !== 0 ? slugifyString : `idea-${idea.id.split('-')[0]}`;

  return `/ideas/${nameSlug}/${idea.id}`;
};
export const challengeUrl = (challenge: IChallenge): string => {
  const slugifyString = slugify(challenge.title);
  const titleSlug = slugifyString.length !== 0 ? slugifyString : `challenge-${challenge.id.split('-')[0]}`;

  return `/challenges/${titleSlug}/${challenge.id}`;
};
// ******************************************
// ***** END OF INFORMATIVE URL WARNING *****
// ******************************************

export const blobAcceptedType = (blobType: IBlobType) =>
  ({
    [IBlobType.Image]: FileStackAcceptedImageType.All,
    [IBlobType.Video]: FileStackAcceptedVideoType.Mp4,
    [IBlobType.Document]: FileStackAcceptedFileType.Pdf,
  }[blobType]);

export const blobMaxUploadSize = (blobType: IBlobType): FileStackMaxSize =>
  ({
    [IBlobType.Image]: FileStackMaxSize.Image,
    [IBlobType.Video]: FileStackMaxSize.Video,
    [IBlobType.Document]: FileStackMaxSize.Document,
  }[blobType]);

export const isOverFileUploadLimit = (fileSize: number, blobType: IBlobType): boolean =>
  fileSize > blobMaxUploadSize(blobType);

// The radio button value default (always) return as string. We need to transform the value
// from type string to type number because The server validation requires the range value to be a number.
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#value_2
export const transformSubmissionFormData = (data: ISubmissionFormData, formFields: IFormField[]) => {
  // shallow clone the "data" object so we don't mutate the "data" value
  let transformedData = { ...data };
  const rangeFields = formFields.filter((field) => field.type === IFormFieldType.Range).map((v) => v.id);

  Object.keys(transformedData).forEach((key) => {
    const keyEntry = key as Uuid;

    if (rangeFields.includes(keyEntry)) {
      transformedData[keyEntry] = parseInt(transformedData[keyEntry] as string);
    }
  });

  return transformedData;
};

export const humanizeChallengeDefaultSubmissionVisibility = (ideaVisibility: IIdeaVisibility): string =>
  ({
    [IIdeaVisibility.All]: 'All',
    [IIdeaVisibility.PermissionOnly]: 'Permission Only',
  }[ideaVisibility]);

export const submissionTag: (tag: string) => { text: string; color: IBadgeColors } = (tag) => {
  if (tag === IDefaultSubmissionTag.Spotlight) return { text: 'Idea Spotlight', color: 'purple' };
  if (tag === IDefaultSubmissionTag.MoonOriginal) return { text: 'Moon Original', color: 'black' };
  // TODO: Associate new tag with color. For now, new tags default to blue.
  // Requires backend support to associate new tag with color.
  return { text: tag, color: 'blue' };
};

export const sortedIdeasBySpotlightTag = (spotlightIdeas: IIdea[], allIdeas: IIdea[]): IIdea[] => {
  const spotlightIdeaIds = new Set(spotlightIdeas.map((idea) => idea.id));
  const otherIdeas = allIdeas.filter((idea) => !spotlightIdeaIds.has(idea.id));

  return [...spotlightIdeas, ...otherIdeas];
};
