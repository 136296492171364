import { IUser } from '../../util/types';
import { ReactComponent as BanIcon } from '../../assets/icons/ban.svg';

import styles from './index.module.scss';
import { useState } from 'react';
import Switch from '../Switch';
import classnames from 'classnames';

interface IProps {
  user: IUser;
  reset: boolean;
  canView: boolean;
  canEdit: boolean;
  onApprovalChange: (approvalValue: boolean, isChanged: boolean) => void;
}

const AdminUserInfoApproval = ({ user, reset, canView, canEdit, onApprovalChange }: IProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isChecked, setIsChecked] = useState(user.approved);

  const handleChange = (checked: boolean) => {
    setIsChecked(checked);
    onApprovalChange(checked, checked !== user.approved);
  };

  if (reset && isChecked !== user.approved) {
    setIsChecked(user.approved);
    onApprovalChange(user.approved, false);
  }

  return canView ? (
    <>
      <div className={styles.header}>
        <div className={styles.title}>{'Approval Status:'}</div>
        {canEdit ? (
          <button data-testid="edit-button" onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? 'Done' : 'Edit'}
          </button>
        ) : null}
      </div>
      <div className={styles.wrapper}>
        <div className={classnames(styles.approval, user.approved && styles.approved)}>
          <div>
            {isEditing ? (
              <Switch
                name="approval-switch"
                onChange={(checked) => handleChange(checked)}
                checked={isChecked}
                dataTestId="permission-switch"
              />
            ) : user.approved ? null : (
              <BanIcon className={styles.icon} />
            )}
          </div>
          <div>
            {isEditing && isChecked !== user.approved
              ? `${isChecked ? 'Approve' : 'Unapprove'} user?`
              : `User is${!user.approved ? ' not ' : ' '}approved`}
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default AdminUserInfoApproval;
