import { useQuery } from 'react-query';
import { getUser } from '../util/api';
import { IUserPermission, ReactQueryKey } from '../util/types';

export const useCurrentUser = () => {
  const meQueryResult = useQuery(ReactQueryKey.CurrentUser, getUser, {
    refetchOnMount: false,
  });

  const currentUserQuery = meQueryResult.data?.onBehalfOf
    ? { ...meQueryResult, data: meQueryResult.data?.onBehalfOf }
    : { ...meQueryResult, data: meQueryResult.data?.me };

  const currentUserData = currentUserQuery.data;

  const hasPermission = (permission: IUserPermission): boolean =>
    Boolean(currentUserData && currentUserData.permissions.includes(permission));

  const fullName = currentUserData && [currentUserData.givenName, currentUserData.familyName].join(' ');
  const email = currentUserData && currentUserData.emailAddress;

  return { ...currentUserQuery, hasPermission, fullName, email };
};
