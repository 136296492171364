import React, { useState, useRef, SyntheticEvent, Fragment, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { map } from 'lodash-es';
import { history } from '../../redux/store';
import { Row, Col } from 'react-bootstrap';
import { startLoading, endLoading, openMessageModal } from '../../redux/actions';
import { getChallenge, updateSubmission, getSubmission } from '../../util/api';
import {
  collectSubmissionFormData,
  arraySearchByKeyValuePair,
  isOverFileUploadLimit,
  blobMaxUploadSize,
  blobAcceptedType,
} from '../../util/helpers';
import styles from './index.module.scss';
import {
  IUrlParams,
  IIdeaStatus,
  IFormFieldType,
  ITextAreaFormField,
  IRangeFormField,
  IBlobFormField,
  IBlob,
  ISubmissionField,
  IIdeaVisibility,
  IBlobType,
  ReactQueryKey,
  IModalType,
  IBlobOrigin,
} from '../../util/types';
import { MAINTENANCE_STATUS_CODE, ERROR_MESSAGES, NAME_PLACEHOLDER, DESC_PLACEHOLDER } from '../../util/constants';
import PageWrapper from '../../components/PageWrapper';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import SubmitIdeaCongratulationModal from '../../components/SubmitIdeaCongratulationModal';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { queryClient } from '../../util/client';
import { useBlobFieldsSubmission } from '../../hooks/useBlobFieldsSubmission';
import IdeaCardPreview from '../../components/IdeaCardPreview';
import FullScreenLoading from '../../components/FullScreenLoading';
import { useFilestack } from '../../hooks/useFilestack';
import TextAreaFormField from '../../components/TextAreaFormField';

const UserSubmissionFormPage = () => {
  const dispatch = useDispatch();
  const { openFilestack } = useFilestack();

  const formRef = useRef<HTMLFormElement>(null);

  const { challengeId, submissionId } = useParams<IUrlParams>();

  const {
    data: challenge,
    isLoading: isChallengeLoading,
    isError: isChallengeError,
  } = useQuery([ReactQueryKey.Challenge, challengeId], () => getChallenge(challengeId));
  const {
    data: submission,
    isLoading: isSubmissionLoading,
    isError: isSubmissionError,
  } = useQuery(
    [ReactQueryKey.Submission, submissionId],
    () => getSubmission({ ideaId: submissionId }),
    // The window re-focus on Filestack upload success (the Filestack modal opens & closes).
    // the useQuery by default refetch on window focus, which reset the local state blobSubmissionFields
    // to the current submission's fields.
    { refetchOnWindowFocus: false },
  );

  const [isCongratulationModalVisible, setIsCongratulationModalVisible] = useState(false);
  const { findBlobSubmissionField, onBlobUploadSuccess, handleRemoveSubmissionField, setBlobSubmissionFields } =
    useBlobFieldsSubmission();

  useEffect(() => {
    if (submission) {
      const savedBlobSubmissionFields = submission.fields?.filter((field) => field.value.url).map((value) => value);
      if (savedBlobSubmissionFields) setBlobSubmissionFields(savedBlobSubmissionFields);
    }
  }, [submission]);

  if (isChallengeLoading || isSubmissionLoading) {
    return <FullScreenLoading />;
  }

  if (isChallengeError || isSubmissionError || !challenge || !submission) {
    dispatch(
      openMessageModal({
        messageModalTitle: ERROR_MESSAGES.ERROR,
        messageModalContent: 'The submission form does not exist',
        messageModalType: IModalType.Error,
        messageModalButtonText: 'I understand',
        messageModalButtonAction: () => history.replace('/challenges'),
      }),
    );

    return null;
  }

  const publishSubmission = () => {
    if (
      submission.idea.image?.url &&
      submission.idea.name !== NAME_PLACEHOLDER &&
      submission.idea.description !== DESC_PLACEHOLDER
    ) {
      postSubmission(IIdeaStatus.Published);
      return;
    }

    // There is no image yet, or no name/description of idea
    // -> save Draft then go to previous step
    dispatch(
      openMessageModal({
        messageModalTitle: ERROR_MESSAGES.ERROR,
        messageModalContent: ERROR_MESSAGES.ASSURE_NAME_DESC_IMAGE_BEFORE,
        messageModalType: IModalType.Error,
        messageModalButtonText: 'I understand',
        messageModalButtonAction: () => postSubmission(IIdeaStatus.Draft, true),
      }),
    );
    return;
  };

  const saveDraftSubmission = () => {
    postSubmission(IIdeaStatus.Draft);
  };

  const postSubmission = async (status: IIdeaStatus, goPreviousStep = false) => {
    if (!submissionId) {
      return;
    }

    dispatch(startLoading());

    let fields = collectSubmissionFormData(formRef);
    // TODO: Temporary fix: needs rework on how we "collect" the submission form data since
    // we are taking in Blob value.
    // The submission field containing the video blob (ISubmissionField) does not match the
    // fields value (IUpdateSubmissionData) when updating a submission. Therefore, we mutate
    // the existing blobSubmissionField to fit the Blob shape.
    const blobSubmissionFields: ISubmissionField[] = map(
      fields,
      (value?: ISubmissionField) => value?.value?.url && value,
    ).filter((value) => value);
    blobSubmissionFields.forEach((submissionField) => {
      if (!submissionField.fieldId === fields[submissionField.fieldId].fieldId) return;

      fields[submissionField.fieldId] = findBlobSubmissionField(submissionField.fieldId)
        ? { url: submissionField.value.url }
        : null;
    });

    if (status === IIdeaStatus.Published) {
      // Frontend validation: show message if any mandatory field is null (only for Publication)
      const fieldKeys = Object.keys(fields);
      const fieldValues = Object.values(fields);

      // Create mandatory fields info from challenge's form data
      const mandatoryFieldsInfo = {} as any;
      if (challenge.forms) {
        challenge.forms[0].fields?.map((item) => {
          if (item.id) {
            mandatoryFieldsInfo[item.id] = item.isRequired;
          }
        });
      }

      for (let i = 0; i <= fieldValues.length; i++) {
        if (mandatoryFieldsInfo[fieldKeys[i]] === true && fieldValues[i] === null) {
          dispatch(endLoading());

          dispatch(
            openMessageModal({
              messageModalTitle: ERROR_MESSAGES.ERROR,
              messageModalContent: ERROR_MESSAGES.ALL_MANDATORY_FIELDS,
              messageModalType: IModalType.Error,
              messageModalButtonText: 'I understand',
              messageModalButtonAction: null,
            }),
          );
          return;
        }
      }
    }

    const updatedSubmission = await updateSubmission(submissionId, { fields, status }).catch((err: any) => {
      dispatch(endLoading());

      // Error from API: not ready for publication (not enough mandatory fields)
      if (err.error.data[0].errorCode === ERROR_MESSAGES.NOT_READY_FOR_PUBLICATION) {
        dispatch(
          openMessageModal({
            messageModalTitle: ERROR_MESSAGES.ERROR,
            messageModalContent: ERROR_MESSAGES.ALL_MANDATORY_FIELDS,
            messageModalType: IModalType.Error,
            messageModalButtonText: 'I understand',
            messageModalButtonAction: null,
          }),
        );
        return;
      }

      const errorContext = err.error.status === MAINTENANCE_STATUS_CODE ? '' : ERROR_MESSAGES.SUBMISSION_ERROR_CONTEXT;
      const errMsg = err.error.data.map((item: any) => item.errorCode).join('\n');
      dispatch(
        openMessageModal({
          messageModalTitle: ERROR_MESSAGES.ERROR,
          messageModalContent: `${errorContext}${errMsg}`,
          messageModalType: IModalType.Error,
          messageModalButtonText: 'I understand',
          messageModalButtonAction: null,
        }),
      );
      return;
    });

    dispatch(endLoading());

    if (updatedSubmission) {
      queryClient.invalidateQueries(ReactQueryKey.Submission);

      if (goPreviousStep) {
        history.push(`/challenges/${challengeId}/submission/${submissionId}`);
      } else {
        if (status === IIdeaStatus.Published) {
          setIsCongratulationModalVisible(true);
        } else {
          history.replace('/my/ideas?tab=DRAFT');
        }
      }
    }
  };

  const onCongratulationModalDismiss = () => {
    setIsCongratulationModalVisible(false);
    queryClient.invalidateQueries(ReactQueryKey.Submissions);

    if (challenge.defaultSubmissionVisibility === IIdeaVisibility.PermissionOnly) {
      history.replace('/my/ideas');
    } else {
      history.replace(`/ideas?challenge=${challengeId}`);
    }
  };

  const onFileSelected = (response: any, blobType: IBlobType) => {
    if (isOverFileUploadLimit(response.size, blobType)) {
      dispatch(
        openMessageModal({
          messageModalTitle: ERROR_MESSAGES.ERROR,
          messageModalContent: 'Image size cannot exceed 100MB',
          messageModalType: IModalType.Error,
          messageModalButtonText: 'I understand',
          messageModalButtonAction: null,
        }),
      );
    }
  };

  const handleUploadBlob = (event: SyntheticEvent, blobField: IBlobFormField) => {
    event.preventDefault();

    openFilestack({
      originType: IBlobOrigin.Field,
      originId: blobField.id,
      onFileUploadFinished: (response: IBlob) => onBlobUploadSuccess(response),
      onFileSelected: (response: IBlob) => onFileSelected(response, blobField.blobType),
      maxSize: blobMaxUploadSize(blobField.blobType),
      accept: blobAcceptedType(blobField.blobType),
    });
  };

  const handleRemoveBlobSubmissionField = (submissionField?: ISubmissionField) => {
    submissionField && handleRemoveSubmissionField(submissionField);
  };

  return (
    <PageWrapper>
      <div>
        <Row>
          <Col md={8}>
            <div className={styles.sectionTitle} data-cy="idea-submission-step-title">
              Step 2: {challenge && challenge.forms && challenge.forms[0].shortDescription}
            </div>
          </Col>
        </Row>
        <form ref={formRef}>
          {challenge &&
            challenge.forms &&
            challenge.forms.map((form, index) => {
              if (!form.fields || form.fields.length === 0) {
                return null;
              }

              return (
                <Fragment key={form.id}>
                  <Row key={`form-${index}`} className={styles.formSection}>
                    <Col md={8}>
                      <div className={styles.sectionDescription}>{form.longDescription}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                      {form.fields.map((field) => {
                        if (!field || !field.id || !submission || !submission.fields) {
                          return null;
                        }

                        // Get previous value based on field's id
                        const searchResults = arraySearchByKeyValuePair({
                          array: submission.fields,
                          searchKey: 'fieldId',
                          searchValue: field.id,
                        });
                        const submissionFieldData = searchResults?.length > 0 ? searchResults[0].value : null;

                        switch (field.type) {
                          case IFormFieldType.TextArea:
                            const textField = field as ITextAreaFormField;

                            return (
                              <Row key={`field-${textField.id}`} className={styles.questionContainer}>
                                <Col>
                                  <div className={styles.questionTitle}>
                                    {textField.label}
                                    {textField.isRequired && <span className={styles.mandatory}> (*)</span>}
                                  </div>
                                  <div className={styles.questionDescription}>{textField.description}</div>
                                  <textarea
                                    data-fieldid={textField.id}
                                    data-fieldtype={textField.type}
                                    className={styles.textAreaInput}
                                    placeholder={(textField.placeHolder ||= 'Type here')}
                                    rows={5}
                                    defaultValue={(submissionFieldData || '') as string}
                                  />
                                </Col>
                              </Row>
                            );
                          case IFormFieldType.Range:
                            const rangeField = field as IRangeFormField;
                            return (
                              <Row key={`field-${rangeField.id}`} className={styles.questionContainer}>
                                <Col>
                                  <div className={styles.questionTitle}>{rangeField.label}</div>
                                  <div className={styles.questionDescription}>{rangeField.description}</div>
                                  <div className={styles.rangeContainer}>
                                    <input
                                      data-fieldid={rangeField.id}
                                      data-fieldtype={rangeField.type}
                                      className={styles.rangeSlider}
                                      type="range"
                                      min={rangeField.from}
                                      max={rangeField.to}
                                      defaultValue={(submissionFieldData || rangeField.fieldDefault) as number}
                                      step="1"
                                    />
                                    <div className={styles.sliderLabels}>
                                      <p>{rangeField.fromLabel}</p>
                                      <p>{rangeField.toLabel}</p>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            );
                          case IFormFieldType.Blob:
                            const blobField = field as IBlobFormField;
                            const blobSubmissionField = findBlobSubmissionField(blobField.id);

                            return (
                              <Row key={`field-${blobField.id}`} className={styles.questionContainer}>
                                <Col>
                                  <div className={styles.questionTitle}>
                                    {blobField.label}
                                    {blobField.isRequired && <span className={styles.mandatory}> (*)</span>}
                                  </div>
                                  <div className={styles.questionDescription}>{blobField.description}</div>
                                  <SecondaryButton onClick={(e) => handleUploadBlob(e, blobField)}>
                                    Upload a {blobField.blobType === IBlobType.Video ? 'Video' : 'File'}
                                  </SecondaryButton>
                                  <input
                                    hidden
                                    data-fieldid={blobField.id}
                                    data-fieldtype={blobField.type}
                                    value={JSON.stringify(blobSubmissionField)}
                                  />
                                  {blobSubmissionField && (
                                    <div className={styles.blobPreviewContainer}>
                                      {blobField.blobType === IBlobType.Video ? (
                                        <>
                                          <video
                                            controls
                                            data-cy="preview-video-thumbnail"
                                            data-testid="preview-video-thumbnail"
                                            className={styles.videoThumbnail}
                                            src={blobSubmissionField.value.url}
                                          />
                                          <CloseIcon
                                            className={styles.videoThumbnailCloseIcon}
                                            onClick={() => handleRemoveBlobSubmissionField(blobSubmissionField)}
                                          />
                                        </>
                                      ) : (
                                        <div style={{ display: 'flex' }}>
                                          <a
                                            className={styles.documentName}
                                            href={blobSubmissionField.value.url}
                                            target="_"
                                          >
                                            {blobSubmissionField.value.filename || 'uploaded file'}
                                          </a>
                                          <CloseIcon
                                            className={styles.documentCloseIcon}
                                            onClick={() => handleRemoveBlobSubmissionField(blobSubmissionField)}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            );

                          default:
                            return null;
                        }
                      })}
                    </Col>
                    {/* TODO: Comment out until related bugs are address and IdeaCard
                              with carousel video asset is addressed in on idea library  */}
                    {/* <Col md={4} className={styles.previewArea}>
                       <div className={styles.caption}>Preview</div>
                       {submission?.idea.name && submission?.idea.description && submission?.idea.image && (
                         <IdeaCardPreview
                         name={submission.idea.name}
                         description={submission.idea.description}
                         imageUrl={submission.idea.image.url}
                         videoUrls={
                           uploadResponses?.map((response) => response.url) ||
                           blobSubmissionFields?.map((field) => field?.value.url)
                          }
                          />
                          )}
                        </Col> */}
                    {/* TODO: Temporary solution before enabling the IdeaCardPreview */}
                    {/* This ensure the page renders with the lasted request from useQuery IDEA_DETAIL. */}
                    <div style={{ display: 'none' }}>
                      <div data-cy="idea-name">{submission.idea.name}</div>
                      <div data-cy="idea-description">{submission.idea.description}</div>
                    </div>
                  </Row>
                </Fragment>
              );
            })}
        </form>
      </div>

      <Row>
        <Col md={8} className={styles.buttonArea}>
          <PrimaryButton data-cy="submit-idea-button" onClick={publishSubmission}>
            Submit
          </PrimaryButton>
          <SecondaryButton onClick={saveDraftSubmission}>Save draft</SecondaryButton>
        </Col>
      </Row>

      <SubmitIdeaCongratulationModal isVisible={isCongratulationModalVisible} dismiss={onCongratulationModalDismiss} />
    </PageWrapper>
  );
};

export default UserSubmissionFormPage;
