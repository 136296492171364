import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Switch from 'react-switch';
import classnames from 'classnames';
import { openMessageModal } from '../../redux/actions';
import { ReactComponent as TiltedRocketIcon } from '../../assets/icons/tiltedRocket.svg';
import { ReactComponent as RocketIcon } from '../../assets/icons/rocket.svg';
import { ReactComponent as QuestionMarkCircle } from '../../assets/icons/questionMarkCircle.svg';
import { queryClient } from '../../util/client';
import { IChallenge, IModalType, ReactQueryKey } from '../../util/types';
import { updateChallenge } from '../../util/api';
import styles from './index.module.scss';
import { useError } from '../../hooks/useError';

interface IProps {
  challenge: IChallenge;
}

const ChallengePublishToggle = ({ challenge }: IProps) => {
  const dispatch = useDispatch();
  const { handleError } = useError();

  const setPublishedAtDate = async (date: Date | null) => {
    const { id, createdAt, forms, evaluationForms, publishedSubmissions, ...challengeData } = challenge;

    await updateChallenge(challenge.id, { ...challengeData, publishedAt: date })
      .then(() => queryClient.invalidateQueries([ReactQueryKey.Challenge, { challengeId: challenge.id }]))
      .catch(handleError);
  };

  const publishChallenge = () => {
    dispatch(
      openMessageModal({
        messageModalTitle: 'You are about to publish the challenge',
        messageModalContent:
          'By selecting to publish this challenge, this challenge will become visible to all or selected users in SMAP. Do you want to proceed?',
        // TODO: Create a generic modal type
        messageModalType: IModalType.ChangeIdeaVisibility,
        messageModalButtonText: 'Confirm',
        messageModalButtonAction: () => setPublishedAtDate(new Date()),
      }),
    );
    return;
  };

  const unPublishChallenge = () => {
    dispatch(
      openMessageModal({
        messageModalTitle: 'You are about to un-publish a challenge',
        messageModalContent:
          'By selecting to un-publish this challenge, this challenge will no longer be visible to users. Do you want to proceed?',
        // TODO: Create a generic modal type
        messageModalType: IModalType.ChangeIdeaVisibility,
        messageModalButtonText: 'Confirm',
        messageModalButtonAction: () => setPublishedAtDate(null),
      }),
    );
    return;
  };

  const handleToggleIdeaVisibility = () => {
    Boolean(challenge.publishedAt) ? unPublishChallenge() : publishChallenge();
  };

  return (
    <div className={styles.wrapper}>
      {challenge.publishedAt ? (
        <div className={styles.label}>
          <TiltedRocketIcon className={styles.switchLabelIcon} />
          <div>Published</div>
        </div>
      ) : (
        <div className={classnames(styles.label, styles.grayOut)}>
          <RocketIcon className={styles.switchLabelIcon} />
          <div>Unpublished</div>
        </div>
      )}
      <Switch
        checked={Boolean(challenge.publishedAt)}
        onChange={handleToggleIdeaVisibility}
        className={styles.switch}
        width={60}
        offColor="#E5E7EB"
        onColor="#2E7FEC"
        handleDiameter={34}
        checkedIcon={false}
        uncheckedIcon={false}
        boxShadow="inset 0 0 0 1px #E5E7EB, 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
        activeBoxShadow="0 0 1px 2px #2E7FEC"
      />
      <>
        <QuestionMarkCircle className={styles.questionMarkCircleIcon} data-tip="custom show" data-event="click focus" />
        <ReactTooltip globalEventOff="click" effect="solid" place="bottom">
          <ul className={styles.switchTooltipContent}>
            <li>Marking this challenge as Published will make it visible to all or selected users in SMAP.</li>
            <li>Marking it as Unpublished remove will make it invisible to all users in SMAP.</li>
          </ul>
        </ReactTooltip>
      </>
    </div>
  );
};

export default ChallengePublishToggle;
