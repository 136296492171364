import { Link } from 'react-router-dom';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { IUserPermission } from '../../util/types';
import AdminPageTitle from '../../components/AdminPageTitle';
import { Button } from '../../components/Button';
import UserGroups from '../../components/UserGroups';
import styles from './index.module.scss';

const AdminUserGroupsPage = () => {
  const { hasPermission } = useCurrentUser();

  return (
    <div className={styles.wrapper}>
      <AdminPageTitle text="User Groups Management" />
      {hasPermission(IUserPermission.CanManageUserGroups) && (
        <Link to="/admin/user-groups/create" className={styles.link}>
          <Button className={styles.button} variant="black">
            New User Group
          </Button>
        </Link>
      )}
      <div className={styles.container}>
        <UserGroups />
      </div>
    </div>
  );
};

export default AdminUserGroupsPage;
