import { useState } from 'react';
import { useQuery } from 'react-query';
import { assignChallenge, getChallenges, getUserGroupChallenges } from '../../util/api';
import { queryClient } from '../../util/client';
import { IUserGroup, ReactQueryKey, Uuid } from '../../util/types';
import { Button } from '../Button';
import ChallengeSelect from '../ChallengeSelect';
import styles from './index.module.scss';

interface IProps {
  userGroup: IUserGroup;
  onSubmit?: () => void;
}

const ChallengesAssignForm = ({ userGroup, onSubmit }: IProps) => {
  const [selectedChallengeId, setSelectedChallengeId] = useState<Uuid | null>();
  const { data: allChallengesData } = useQuery(ReactQueryKey.Challenges, getChallenges);
  const { data: userGroupChallengesData } = useQuery([ReactQueryKey.Challenges, { userGroupId: userGroup.id }], () =>
    getUserGroupChallenges(userGroup.id),
  );

  const allChallenges = allChallengesData?.data;
  const userGroupChallenges = userGroupChallengesData?.data;

  const challengesToSelect = allChallenges?.filter(
    (challenge) => !userGroupChallenges?.some((c) => c.id === challenge.id),
  );

  const handleAssignChallenge = async () => {
    if (selectedChallengeId) {
      await assignChallenge(userGroup.id, selectedChallengeId).then(() => {
        queryClient.invalidateQueries([ReactQueryKey.Challenges, { userGroupId: userGroup.id }]);
        onSubmit && onSubmit();
      });
    }
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>Assign Challenge</h3>
      <p>
        Select a challenge from the dropdown menu and press the 'Assign' button to assign it to user group '
        {userGroup.name}'. Assigning a challenge to this user group will mean that users in the group will have access
        to this challenge.
      </p>
      {challengesToSelect && (
        <ChallengeSelect
          challenges={challengesToSelect}
          onChange={(option) => setSelectedChallengeId(option?.value)}
          placeholder="Select a challenge"
        />
      )}

      <Button className={styles.button} variant="black" onClick={handleAssignChallenge}>
        Assign
      </Button>
    </div>
  );
};

export default ChallengesAssignForm;
