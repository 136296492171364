import { FieldValues, UseFormRegister } from 'react-hook-form';
import { ISubmissionField, ITextAreaFormField } from '../../util/types';
import styles from './index.module.scss';

interface IProps {
  field: ITextAreaFormField;
  register: UseFormRegister<FieldValues>;
  defaultValue?: ISubmissionField;
}

// TODO: Replace with HookFormTextInput
const TextAreaFormFieldInput = ({ field, register, defaultValue }: IProps) => {
  const defaultTextAreaValue = defaultValue?.value;

  return (
    <textarea
      {...register(field.id, { required: field.isRequired && 'this field is required' })}
      defaultValue={defaultTextAreaValue}
      className={styles.textarea}
      rows={field.rows}
      placeholder={field.placeHolder}
      style={{ width: '100%', padding: '0.5rem' }}
    />
  );
};

export default TextAreaFormFieldInput;
