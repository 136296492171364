import { useForm } from 'react-hook-form';
import { createBlobFormField } from '../../util/api';
import { queryClient } from '../../util/client';
import {
  IBlobType,
  IForm,
  IFormFieldBlobFormInputs,
  IFormFieldType,
  ISelectOption,
  ReactQueryKey,
} from '../../util/types';
import { Button } from '../Button';
import HookFormSelect from '../HookFormSelect';
import HookFormSwitch from '../HookFormSwitch';
import HookFormTextInput from '../HookFormTextInput';
import styles from './index.module.scss';

interface IProps {
  form: IForm;
  onSubmit?: () => void;
}

const BlobFormFieldForm = ({ form, onSubmit }: IProps) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toSubmit = async (data: IFormFieldBlobFormInputs) => {
    const { label, description, isRequired, blobType } = data;

    await createBlobFormField({
      formId: form.id,
      type: IFormFieldType.TextArea,
      label,
      description: description || undefined,
      blobType: blobType.value,
      isRequired: Boolean(isRequired),
    })
      .then(() => {
        queryClient.invalidateQueries([ReactQueryKey.Challenge, { challengeId: form.challengeId }]);
        onSubmit && onSubmit();
      })
      .catch((e: any) => console.error(e));
  };

  const blobTypeOptions: ISelectOption<IBlobType>[] = Object.values(IBlobType).map((blobType) => ({
    label: blobType as string,
    value: blobType,
  }));

  return (
    <form data-testid="blob-field-form" className={styles.form}>
      <HookFormTextInput
        name="label"
        label="Label"
        rules={{ required: 'label is required' }}
        register={register}
        errors={errors}
        dataTestId="blob-label-input"
      />
      <HookFormTextInput
        name="description"
        label="Description"
        register={register}
        errors={errors}
        dataTestId="blob-description-input"
      />
      <HookFormSwitch
        name="isRequired"
        label="Required"
        control={control}
        errors={errors}
        dataTestId="blob-isRequired-switch"
      />
      <HookFormSelect
        name="blobType"
        label="File Type"
        rules={{ required: 'required' }}
        placeholder="Select one"
        options={blobTypeOptions}
        control={control}
        errors={errors}
      />
      <Button variant="black" onClick={handleSubmit(toSubmit)} className={styles.addFormFieldButton}>
        Add form field
      </Button>
    </form>
  );
};

export default BlobFormFieldForm;
