import { useInfiniteQuery } from 'react-query';
import { getMemos } from '../../util/api';
import { ReactQueryKey, Uuid } from '../../util/types';
import { Button } from '../Button';
import Memo from '../Memo';
import styles from './index.module.scss';

interface IProps {
  submissionId: Uuid;
}

const Memos = ({ submissionId }: IProps) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    [ReactQueryKey.Memos, { submissionId }],
    ({ pageParam = null }) => getMemos(submissionId, pageParam),
    { getNextPageParam: (lastPage) => lastPage.links?.next },
  );

  const memos = data?.pages.flatMap((page) => page.data) || [];

  if (isLoading) return <div>loading...</div>;

  return (
    <div className={styles.memos}>
      {memos && memos.length > 0 ? (
        <div className={styles.memos__container}>
          {memos.map((memo) => (
            <Memo key={memo.id} memo={memo} />
          ))}
        </div>
      ) : (
        <div className={styles.memos__emptyState}>No memos yet</div>
      )}
      {hasNextPage ? (
        <Button variant="outline" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
          Load more
        </Button>
      ) : null}
    </div>
  );
};

export default Memos;
