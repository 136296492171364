import { useInfiniteQuery } from 'react-query';
import { getSubmissions } from '../util/api';
import { DEFAULT_SUBMISSIONS_FILTERS } from '../util/constants';
import { ISubmissionsFilters, ReactQueryKey } from '../util/types';

interface IProps {
  filters?: Partial<ISubmissionsFilters>;
}

export const useSubmissions = ({ filters }: IProps) => {
  const withFilters = { ...DEFAULT_SUBMISSIONS_FILTERS, ...filters };

  return useInfiniteQuery(
    [ReactQueryKey.Submissions, { filters }],
    ({ pageParam = null }) => getSubmissions(withFilters, pageParam),
    { getNextPageParam: (lastPage) => lastPage.links?.next },
  );
};
