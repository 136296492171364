import { Control, Controller, FieldValues } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Select from '../Select';
import styles from './index.module.scss';
import QuestionMarkToolTip from '../QuestionMarkTooltip';

interface IProps {
  label: string;
  control: Control<FieldValues, object>;
  name: string;
  errors: { [x: string]: any };
  // See: https://react-hook-form.com/api/usecontroller/controller for Validation rules
  rules?: object;
  placeholder: string;
  options: { value: string; label: string }[];
  tooltip?: string;
}

const HookFormSelect = ({ name, control, label, errors, rules, placeholder, options, tooltip }: IProps) => {
  return (
    <div>
      <div className={styles.labelContainer}>
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
        {tooltip && <QuestionMarkToolTip text={tooltip} />}
      </div>
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({ field }) => {
          const { ref, ...props } = field;
          return (
            <Select {...props} name={field.name} inputId={field.name} placeholder={placeholder} options={options} />
          );
        }}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <span style={{ color: 'red' }}>*{message}</span>}
      />
    </div>
  );
};

export default HookFormSelect;
