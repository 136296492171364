import classnames from 'classnames';
import { formatDate, transformImage } from '../../util/helpers';
import { IIdea } from '../../util/types';
import IdeaBadges from '../IdeaBadges';
import IdeaOwner from '../IdeaOwner';
import IdeaOwnerProfile from '../IdeaOwnerProfile';
import styles from './index.module.scss';

interface IProps {
  idea: IIdea;
}

const SubmissionIdea = ({ idea }: IProps) => {
  return (
    <div className={styles.wrapper}>
      <IdeaBadges idea={idea} />
      {idea.publishedAt && (
        <div className={styles.postedAt}>IDEA POSTED | {formatDate(idea.publishedAt, 'MM.dd.yyyy')}</div>
      )}
      <div className={classnames(styles.main, idea.pitched && styles.isPitchedLayout)}>
        <div>
          <img
            className={styles.image}
            src={transformImage({ imageUrl: idea.image?.url, size: 'banner' })}
            alt="idea photo"
          />
          <p className={styles.description}>{idea.description}</p>
        </div>
        {idea.pitched ? <IdeaOwnerProfile user={idea.author} /> : <IdeaOwner ideaAuthor={idea.author} />}
      </div>

      {idea.pitched && (
        <div className={styles.callout}>
          {idea.selected || (idea.selected && idea.folded)
            ? `Congratulations ${idea.author.givenName} for being invited to share your idea at our pitch event and for being
          selected as a Moon venture!`
            : `Congratulations ${idea.author.givenName} for being invited to share your idea at our pitch event. We want to
            recognize you for your great efforts in sharing your idea with Moon.`}
        </div>
      )}
    </div>
  );
};

export default SubmissionIdea;
