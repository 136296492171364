import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import FullScreenLoading from '../FullScreenLoading';
import { useAcceptAgreements } from '../../hooks/useAcceptAgreements';
import { ILocationState } from '../../util/types';
import { useAuthenticate } from '../../hooks/useAuthenticate';
import useAutoPopulateEmail from '../../hooks/useAutoPopulateEmail';

interface Props {
  children: any;
  requireSession: boolean;
}

const Authenticated = ({ children, requireSession }: Props) => {
  const history = useHistory();
  const location = useLocation<ILocationState>();
  const { data: currentUser, isLoading } = useCurrentUser();
  useAuthenticate();
  useAcceptAgreements();
  useAutoPopulateEmail(currentUser?.emailAddress);

  if (!requireSession) {
    return <>{children}</>;
  }

  if (isLoading) {
    return <FullScreenLoading />;
  }

  if (currentUser && !currentUser?.approved) {
    history.replace('/signup-approval');
  }

  if (currentUser) {
    return <>{children}</>;
  } else {
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  }
};

export default Authenticated;
