import { useForm } from 'react-hook-form';
import { createForm } from '../../util/api';
import { queryClient } from '../../util/client';
import { IFormFormData, IFormType, ISelectOption, ReactQueryKey, Uuid } from '../../util/types';
import { useError } from '../../hooks/useError';
import HookFormSelect from '../HookFormSelect';
import HookFormTextInput from '../HookFormTextInput';
import HookFormTextAreaInput from '../HookFormTextAreaInput';
import { Button } from '../Button';
import styles from './index.module.scss';

interface IProps {
  challengeId: Uuid;
  onSubmit?: () => void;
}

const FormCreateForm = ({ challengeId, onSubmit }: IProps) => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { handleError } = useError();

  const typeOptions: ISelectOption<IFormType>[] = Object.values(IFormType).map((type) => ({
    label: type as string,
    value: type,
  }));

  const toCreateForm = async (data: IFormFormData) => {
    const { title, type, shortDescription, longDescription } = data;
    await createForm({
      challengeId,
      title,
      type: type.value,
      shortDescription,
      longDescription: longDescription || undefined,
    })
      .then(() => {
        queryClient.invalidateQueries([ReactQueryKey.Challenge, { challengeId }]);
        onSubmit && onSubmit();
      })
      .catch(handleError);
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>Create a Form</h3>
      <form className={styles.form} data-testid="form-create-form">
        <HookFormSelect
          name="type"
          label="Type"
          rules={{ required: 'type is required' }}
          options={typeOptions}
          placeholder="Select one"
          control={control}
          errors={errors}
        />
        <HookFormTextInput
          name="title"
          label="Title"
          rules={{ required: 'title is required' }}
          register={register}
          errors={errors}
          dataTestId="form-title-input"
        />
        <HookFormTextInput
          name="shortDescription"
          label="Short Description"
          rules={{ required: 'short description is required' }}
          register={register}
          errors={errors}
          dataTestId="form-short-description-input"
        />
        <HookFormTextAreaInput
          name="longDescription"
          label="Long Description"
          rows={3}
          register={register}
          errors={errors}
          dataTestId="form-long-description-input"
        />
      </form>
      <Button className={styles.createButton} variant="black" onClick={handleSubmit(toCreateForm)}>
        Create form
      </Button>
    </div>
  );
};

export default FormCreateForm;
