// Inspired by https://usehooks.com/useLocalStorage/
import { useEffect, useState } from 'react';

const SMAP_STORED_EMAIL_KEY = 'smapStoredEmail';

const useAutoPopulateEmail = (initialValue?: string) => {
  const [storedEmail, setEmail] = useState<string | undefined>(() => {
    const email = window.localStorage.getItem(SMAP_STORED_EMAIL_KEY);
    return email ? email : (initialValue = '');
  });

  useEffect(() => {
    if (initialValue) {
      setStoredEmail(initialValue);
    }
  }, [initialValue]);

  const setStoredEmail = (email: string) => {
    setEmail(email);
    window.localStorage.setItem(SMAP_STORED_EMAIL_KEY, email);
  };

  return [storedEmail, setStoredEmail] as const;
};

export default useAutoPopulateEmail;
