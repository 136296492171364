import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { openMessageModal } from '../../redux/actions';
import { getChallenge } from '../../util/api';
import { IUrlParams, ReactQueryKey, IModalType } from '../../util/types';
import { CHALLENGE_TITLES, ERROR_MESSAGES } from '../../util/constants';
import FullScreenLoading from '../../components/FullScreenLoading';
import OpenCallChallengeDetailPage from '../../components/OpenCallChallengeDetailPage';
import MoonOriginalChallengePage from '../MoonOriginalChallengePage';
import DefaultChallengeDetailPage from '../../components/DefaultChallengeDetailPage';
import IndividualIdeaApplicationPage from '../../components/IndividualIdeaApplicationPage';

const UserChallengeDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { challengeId } = useParams<IUrlParams>();
  const {
    data: challenge,
    isLoading,
    isError,
  } = useQuery([ReactQueryKey.Challenge, { challengeId }], () => getChallenge(challengeId));

  if (isLoading) {
    return <FullScreenLoading />;
  }

  if (isError || !challenge) {
    dispatch(
      openMessageModal({
        messageModalTitle: ERROR_MESSAGES.ERROR,
        messageModalContent: 'The challenge does not exist',
        messageModalType: IModalType.Error,
        messageModalButtonText: 'I understand',
        messageModalButtonAction: () => history.replace('/challenges'),
      }),
    );

    return null;
  }

  switch (challenge.title) {
    case CHALLENGE_TITLES.openCallTitle:
      return <OpenCallChallengeDetailPage challenge={challenge} />;
    case CHALLENGE_TITLES.moonOriginalTitle:
      return <MoonOriginalChallengePage challengeId={challenge.id} />;
    case CHALLENGE_TITLES.individualIdeaApplicationTitle:
      return <IndividualIdeaApplicationPage challenge={challenge} />;
    default:
      return <DefaultChallengeDetailPage challenge={challenge} />;
  }
};

export default UserChallengeDetailPage;
