import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { openMessageModal } from '../../redux/actions';
import { getSubmission } from '../../util/api';
import { ERROR_MESSAGES } from '../../util/constants';
import { ideaUrl } from '../../util/helpers';
import { IUrlParams, ReactQueryKey, IModalType } from '../../util/types';
import FullScreenLoading from '../FullScreenLoading';

const RedirectToIdeaUrl = () => {
  const { ideaId } = useParams<IUrlParams>();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    data: submission,
    isLoading,
    isError,
  } = useQuery([ReactQueryKey.Submission, { submissionId: ideaId }], () => getSubmission({ ideaId }));

  if (isLoading) return <FullScreenLoading />;

  if (isError || !submission) {
    dispatch(
      openMessageModal({
        messageModalTitle: ERROR_MESSAGES.ERROR,
        messageModalContent: 'This idea no longer exists',
        messageModalType: IModalType.Error,
        messageModalButtonText: 'I understand',
        messageModalButtonAction: () => history.replace('/ideas'),
      }),
    );
    return null;
  }

  const idea = submission.idea;

  return <Redirect to={ideaUrl(idea)} />;
};

export default RedirectToIdeaUrl;
