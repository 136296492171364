import { ErrorMessage } from '@hookform/error-message';
import { Controller, FieldValues, UseFormReturn } from 'react-hook-form';
import { IIdeaVisibility, ISelectOption } from '../../util/types';
import DatePicker from '../DatePicker';
import HookFormTextInput from '../HookFormTextInput';
import HookFormSelect from '../HookFormSelect';
import HookFormRichTextInput from '../HookFormRichTextInput';
import HookFormBlobInput from '../HookFormBlobInput';
import styles from './index.module.scss';

interface IProps {
  form: UseFormReturn<FieldValues, object>;
}

const ChallengeCreateForm = ({ form }: IProps) => {
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const ideaVisibilityOptions: ISelectOption<IIdeaVisibility>[] = Object.values(IIdeaVisibility).map(
    (ideaVisibility) => ({
      label: ideaVisibility as string,
      value: ideaVisibility,
    }),
  );

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>Challenge Description</h3>
      <form className={styles.form} data-testid="create-challenge-form">
        <section>
          <HookFormTextInput
            name="title"
            label="Title"
            rules={{ required: 'title is required' }}
            register={register}
            errors={errors}
            dataTestId="title-input"
          />
        </section>

        <section>
          <HookFormRichTextInput
            label="Content"
            name="content"
            rules={{ required: 'content is required' }}
            control={control}
            errors={errors}
            dataTestId="content-input"
            isSanitized={false}
          />
        </section>

        <section>
          <HookFormSelect
            name="defaultSubmissionVisibility"
            label="Submission Visibility"
            rules={{ required: 'submission visibility is required' }}
            placeholder="Select one"
            options={ideaVisibilityOptions}
            control={control}
            errors={errors}
            tooltip="The default visibility for a challenge's submissions. Submissions to a challenge can be visible to everyone or
            only to people with the permission. there is an option to make a submission visible to everyone despite the
            challenge has a Permission Only default submission visibility."
          />
        </section>

        <section>
          <label className={styles.label}>Start Date</label>
          <Controller
            control={control}
            name="startsAt"
            render={({ field }) => {
              const { ref, ...props } = field;
              return (
                <DatePicker
                  {...props}
                  name={field.name}
                  selected={field.value}
                  onChange={field.onChange}
                  dataTestId="starts-at-date-picker"
                />
              );
            }}
          />
          <ErrorMessage
            errors={errors}
            name="startsAt"
            render={({ message }) => <span style={{ color: 'red' }}>*{message}</span>}
          />
        </section>

        <section>
          <label className={styles.label}>End Date</label>
          <Controller
            control={control}
            rules={{
              validate: {
                hasStartsAt: (value) => !value || watch('startsAt') || 'Must have a Start date',
                afterStartsAt: (value) =>
                  !value ||
                  (watch('startsAt') && new Date(value) > new Date(watch('startsAt'))) ||
                  'End date must be after Start date',
              },
            }}
            name="endsAt"
            render={({ field }) => {
              const { ref, ...props } = field;
              return (
                <DatePicker
                  {...props}
                  name={field.name}
                  selected={field.value}
                  onChange={field.onChange}
                  dataTestId="ends-at-date-picker"
                />
              );
            }}
          />
          <ErrorMessage
            errors={errors}
            name="endsAt"
            render={({ message }) => <span style={{ color: 'red' }}>*{message}</span>}
          />
        </section>
        <section>
          <HookFormBlobInput
            name="image"
            label="Image"
            register={register}
            watch={watch}
            errors={errors}
            setValue={setValue}
          />
        </section>
      </form>
    </div>
  );
};

export default ChallengeCreateForm;
