import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from '../../util/client';
import { IForm, IUpdateFormData, ReactQueryKey } from '../../util/types';
import { deleteForm, updateForm } from '../../util/api';
import HookFormTextInput from '../HookFormTextInput';
import { useError } from '../../hooks/useError';
import FormFieldCreateForm from '../FormFieldCreateForm';
import HookFormTextAreaInput from '../HookFormTextAreaInput';
import AlertModal from '../AlertModal';
import FormField from '../FormField';
import Modal from '../Modal';
import { Button } from '../Button';
import styles from './index.module.scss';

interface IProps {
  form: IForm;
  onSubmit?: () => void;
}

const FormEditForm = ({ form, onSubmit }: IProps) => {
  const { handleError } = useError();
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false);
  const [isCreateFormFieldModalVisible, setIsCreateFormFieldModalVisible] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const invalidateCurrentChallengeQuery = () =>
    queryClient.invalidateQueries([ReactQueryKey.Challenge, { challengeId: form.challengeId }]);

  const handleDeleteForm = async () =>
    await deleteForm(form.id)
      .then(() => {
        invalidateCurrentChallengeQuery();
        setIsAlertModalVisible(false);
      })
      .catch(handleError);

  const toUpdateForm = async (data: IUpdateFormData) => {
    await updateForm(form.id, data)
      .then(() => {
        invalidateCurrentChallengeQuery();
        onSubmit && onSubmit();
      })
      .catch(handleError);
  };

  return (
    <>
      <div className={styles.formEditForm}>
        <div className={styles.formEditForm__controls}>
          <Button variant="red" onClick={() => setIsAlertModalVisible(true)}>
            Delete
          </Button>
          <Button variant="outline" onClick={onSubmit}>
            Cancel
          </Button>
          <Button variant="black" onClick={handleSubmit(toUpdateForm)}>
            Save
          </Button>
        </div>

        <div className={styles.formEditForm__formInputs}>
          <HookFormTextInput
            name="title"
            label="Title"
            rules={{ required: 'title is required' }}
            register={register}
            errors={errors}
            dataTestId="form-title-input"
            defaultValue={form.title}
          />
          <HookFormTextInput
            name="shortDescription"
            label="Short Description"
            rules={{ required: 'short description is required' }}
            register={register}
            errors={errors}
            dataTestId="form-short-description-input"
            defaultValue={form.shortDescription}
          />
          <HookFormTextAreaInput
            name="longDescription"
            label="Long Description"
            rows={3}
            register={register}
            errors={errors}
            dataTestId="form-long-description-input"
            defaultValue={form.longDescription}
          />
        </div>

        {form.fields && form.fields.length !== 0 && (
          <div className={styles.formEditForm__formFields}>
            {form.fields.map((field) => (
              <FormField field={field} key={field.id} onDelete={invalidateCurrentChallengeQuery} />
            ))}
          </div>
        )}
        <Button
          className={styles.formEditForm__addFieldButton}
          variant="black"
          onClick={() => setIsCreateFormFieldModalVisible(true)}
        >
          Add Field
        </Button>
      </div>

      <AlertModal
        title="Delete form"
        body="Are you sure you want to delete this form?"
        isVisible={isAlertModalVisible}
        cancel={{ text: 'Cancel', onClick: () => setIsAlertModalVisible(false) }}
        confirm={{ text: 'Delete', onClick: handleDeleteForm }}
      />

      <Modal isVisible={isCreateFormFieldModalVisible} dismiss={() => setIsCreateFormFieldModalVisible(false)}>
        <FormFieldCreateForm form={form} onSubmit={() => setIsCreateFormFieldModalVisible(false)} />
      </Modal>
    </>
  );
};

export default FormEditForm;
