import React, { memo, useEffect, useState } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import ReactGA from 'react-ga';
import { IMainAppState } from '../../util/types';
import styles from './index.module.scss';
import { MAINTENANCE_ROUTE, ROUTES_WITHOUT_COOKIE_FORM } from '../../util/constants';
import { isIE, clearGACookies, clearIntercomCookies, cookieExists } from '../../util/helpers';
import FullScreenLoading from '../FullScreenLoading';
import FullScreenOpacity from '../FullScreenOpacity';
import BrowserNotSupported from '../../pages/BrowserNotSupported';
import Router from '../Router';
import CookieConsent from '../CookieConsent';
import ScrollToTop from '../ScrollToTop';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import GlobalMessageModal from '../GlobalMessageModal';
import ActsOnBeHalfOfChecker from '../ActsOnBeHalfOfChecker';

const CONSENT_GIVEN_COOKIE_NAME = 'rcl_consent_given';

const AppBootstrap = () => {
  const { data: currentUser } = useCurrentUser();

  const isLoading = useSelector((state: IMainAppState) => state.common.isLoading);
  const { boot: bootIntercom, shutdown: shutDownIntercom } = useIntercom();

  const [cookieFormIsShown, setCookieFormIsShown] = useState(!cookieExists(CONSENT_GIVEN_COOKIE_NAME));

  useEffect(() => {
    const currentRoute = `${location.pathname}${location.search}`;

    // Track GA Pageview:
    ReactGA.pageview(currentRoute);
    const route = location.pathname;

    if (route === MAINTENANCE_ROUTE) {
      // just proceed to show the route without auth-checking
      return;
    }
  }, [location.pathname]);

  const onAcceptCookies = () => {
    setCookieFormIsShown(false);
  };

  const onAcceptFunctional = () => {
    if (currentUser) {
      bootIntercom({
        name: currentUser.givenName,
        email: currentUser.emailAddress,
        createdAt: currentUser.createdAt.toDateString(),
      });

      return;
    }

    shutDownIntercom();
  };

  const onDeclineFunctional = () => {
    shutDownIntercom();
    clearIntercomCookies();
  };

  const onAcceptPerformance = () => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID || ''); // blank string so it passes typescript check
  };

  const onDeclinePerformance = () => {
    clearGACookies();
  };

  if (isIE) {
    return <BrowserNotSupported />;
  }

  return (
    <div className={styles.wrapper} data-cy="app-wrapper">
      {/* Main Router */}
      <Router />

      {/* Global Loading Screen */}
      {isLoading && <FullScreenLoading />}

      <ScrollToTop />
      <GlobalMessageModal />
      <ActsOnBeHalfOfChecker />

      {/* Cookie consent form */}
      {!ROUTES_WITHOUT_COOKIE_FORM.includes(location.pathname) && (
        <>
          <CookieConsent
            onAccept={onAcceptCookies}
            onAcceptFunctional={onAcceptFunctional}
            onDeclineFunctional={onDeclineFunctional}
            onAcceptPerformance={onAcceptPerformance}
            onDeclinePerformance={onDeclinePerformance}
          />
          {cookieFormIsShown && <FullScreenOpacity />}
        </>
      )}

      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  );
};

export default memo(AppBootstrap);
