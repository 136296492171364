import styles from './index.module.scss';
import classnames from 'classnames';

export type IBadgeColors = 'blue' | 'green' | 'purple' | 'yellow' | 'black' | 'grey';

interface IProps {
  text: string;
  variant?: IBadgeColors;
  className?: string;
}

const Badge = ({ text, variant = 'blue', className, ...props }: IProps) => {
  const color = {
    blue: { background: '#E0F2FE', text: '#1E40AF' },
    green: { background: '#D1FAE5', text: '#065F46' },
    purple: { background: '#EDE9FE', text: '#5B21B6' },
    yellow: { background: '#FEF3C7', text: '#92400E' },
    black: { background: '#000000', text: '#ffffff' },
    grey: { background: '#828282', text: '#ffffff' },
  }[variant];

  return (
    <div style={{ backgroundColor: color.background }} className={classnames(styles.wrapper, className)} {...props}>
      <div style={{ color: color.text }} className={styles.text}>
        {text}
      </div>
    </div>
  );
};

export default Badge;
