import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { Modal as ReactBootstrapModal } from 'react-bootstrap';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import styles from './index.module.scss';

interface Props {
  isVisible: boolean;
  dismiss: () => any;
  dialogClassName?: string;
  containerClassName?: string;
  children: ReactNode;
}

const Modal = ({ isVisible, dismiss, dialogClassName, containerClassName, children }: Props) => {
  return (
    <ReactBootstrapModal
      dialogClassName={classnames(styles.modalDialog, dialogClassName)}
      contentClassName={styles.modalContent}
      centered
      show={isVisible}
      onHide={dismiss}
    >
      <div className={classnames(styles.container, containerClassName)}>{children}</div>
      <CloseIcon onClick={dismiss} className={styles.closeIcon} />
    </ReactBootstrapModal>
  );
};

export default Modal;
