import { useState } from 'react';
import { ISubmission } from '../../util/types';
import { Button } from '../Button';
import Modal from '../Modal';
import PitchFeedbackForm from '../PitchFeedbackForm';

interface IProps {
  submission: ISubmission;
}

const EditPitchFeedback = ({ submission }: IProps) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Button variant="black" onClick={() => setIsVisible(true)}>
        Edit Pitch Feedback
      </Button>
      <Modal isVisible={isVisible} dismiss={() => setIsVisible(false)}>
        <PitchFeedbackForm submission={submission} onSubmit={() => setIsVisible(false)} />
      </Modal>
    </>
  );
};

export default EditPitchFeedback;
