import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { openMessageModal } from '../../redux/actions';
import { createEvaluation, updateEvaluation } from '../../util/api';
import { queryClient } from '../../util/client';
import { ERROR_MESSAGES } from '../../util/constants';
import { transformSubmissionFormData } from '../../util/helpers';
import {
  ICheckBoxFormField,
  IForm,
  IFormFieldType,
  IRangeFormField,
  ISubmissionFormData,
  ITextAreaFormField,
  IUrlParams,
  ReactQueryKey,
  IModalType,
} from '../../util/types';
import { Button } from '../Button';
import CheckedBoxFormField from '../CheckedBoxFormField';
import EvaluationFormHeader from '../EvaluationFormHeader';
import RangeFormFieldInput from '../RangeFormFieldInput';
import TextAreaFormFieldInput from '../TextAreaFormFieldInput';
import styles from './index.module.scss';

interface IProps {
  form: IForm;
}

const EvaluationCreateForm = ({ form }: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { ideaId } = useParams<IUrlParams>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleError = (error: any) => {
    // TODO: Add a submit evaluation error model
    let errorCode;
    if (Array.isArray(error.error.data)) {
      errorCode = error.error.data.map((item: any) => item.errorCode).join('\n');
    } else {
      errorCode = error.error.data.errorCode;
    }

    dispatch(
      openMessageModal({
        messageModalTitle: ERROR_MESSAGES.ERROR,
        messageModalContent: `${ERROR_MESSAGES.SOMETHING_WENT_WRONG}: ${errorCode}`,
        messageModalType: IModalType.Error,
        messageModalButtonText: 'I understand',
        messageModalButtonAction: null,
      }),
    );

    return null;
  };

  const onConfirm = async (data: ISubmissionFormData) => {
    // TODO: add a useServerErrorHandling hook to handle server request error in a later PR
    try {
      const createdEvaluation = await createEvaluation({ submissionId: ideaId });
      await updateEvaluation(createdEvaluation.id, { fields: transformSubmissionFormData(data, form.fields) });

      queryClient.invalidateQueries([ReactQueryKey.Evaluation, { evaluationId: createdEvaluation.id }]);
      history.push(`/admin/ideas/${ideaId}`);
    } catch (error: any) {
      handleError(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.form}>
        <EvaluationFormHeader
          title={form.title}
          shortDescription={form.shortDescription}
          longDescription={form.longDescription}
        />

        <div className={styles.formFields}>
          {form.fields.map((field) => (
            <div key={field.id}>
              <label className={styles.label}>
                {field.label}
                {field.isRequired && <span className={styles.isRequiredIndicator}> (*)</span>}
              </label>
              {field.description && <div className={styles.description}>{field.description}</div>}
              {
                {
                  [IFormFieldType.TextArea]: (
                    <TextAreaFormFieldInput field={field as ITextAreaFormField} register={register} />
                  ),
                  [IFormFieldType.Range]: <RangeFormFieldInput field={field as IRangeFormField} register={register} />,
                  [IFormFieldType.CheckBox]: (
                    <CheckedBoxFormField field={field as ICheckBoxFormField} register={register} />
                  ),
                  // TODO: Add the blob field in a later PR
                  [IFormFieldType.Blob]: null,
                }[field.type]
              }
              <ErrorMessage
                errors={errors}
                name={field.id}
                render={({ message }) => <span style={{ color: 'red' }}>*{message}</span>}
              />
            </div>
          ))}
        </div>
      </form>

      <div className={styles.actions}>
        <Button variant="outline" className={styles.button} onClick={() => history.push(`/admin/ideas/${ideaId}`)}>
          Cancel
        </Button>
        <Button variant="black" className={styles.button} onClick={handleSubmit(onConfirm)}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default EvaluationCreateForm;
