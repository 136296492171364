import { Link } from 'react-router-dom';
import {
  isChallengeNotStarted,
  isChallengeClosed,
  transformImage,
  sortedIdeasBySpotlightTag,
} from '../../util/helpers';
import { IChallenge, IDefaultSubmissionTag, IIdeaStatus } from '../../util/types';
import { useSubmissions } from '../../hooks/useSubmissions';
import { Button } from '../Button';
import Badge from '../Badge';
import PageWrapper from '../PageWrapper';
import RichTextPreview from '../RichTextPreview';
import IdeasGrid from '../IdeasGrid';
import styles from './index.module.scss';

interface IProps {
  challenge: IChallenge;
}

const DefaultChallengeDetailPage = ({ challenge }: IProps) => {
  const { data: spotlightIdeasData } = useSubmissions({
    filters: { status: IIdeaStatus.Published, challenge: challenge.id, tag: IDefaultSubmissionTag.Spotlight },
  });
  const {
    data: ideasData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useSubmissions({
    filters: { status: IIdeaStatus.Published, challenge: challenge.id },
  });
  const spotlightIdeas = spotlightIdeasData?.pages.flatMap((page) => page.data) || [];
  const allIdeas = ideasData?.pages.flatMap((page) => page.data) || [];
  const sortedIdeas = sortedIdeasBySpotlightTag(spotlightIdeas, allIdeas);

  return (
    <PageWrapper>
      <div className={styles.defaultChallengeDetailPage}>
        {isChallengeClosed(challenge) && (
          <Badge
            text={`${challenge.title} is now closed`}
            variant="grey"
            className={styles.defaultChallengeDetailPage__closedTag}
          />
        )}
        <h1>{challenge.title}</h1>
        <div className={styles.defaultChallengeDetailPage__body}>
          <img
            className={styles.defaultChallengeDetailPage__bannerImage}
            src={transformImage({ imageUrl: challenge.image?.url, size: 'banner' })}
          />
          <RichTextPreview
            className={styles.defaultChallengeDetailPage__description}
            value={challenge.content}
            isSanitized={false}
          />
          <div className={styles.defaultChallengeDetailPage__sidebar}>
            {isChallengeNotStarted(challenge) || isChallengeClosed(challenge) ? (
              <Button disabled className={styles.defaultChallengeDetailPage__closedNotice}>
                {isChallengeNotStarted(challenge) ? 'Submissions Have Not Started' : 'Submission Closed'}
              </Button>
            ) : (
              <Link
                className={styles.defaultChallengeDetailPage__startSubmissionButton}
                to={`/challenges/${challenge.id}/submission/create`}
              >
                <Button data-cy="submit-idea-button">Start your Submission</Button>
              </Link>
            )}
          </div>
        </div>
        {sortedIdeas && sortedIdeas.length !== 0 && (
          <div className={styles.defaultChallengeDetailPage__ideas} data-testid="ideas">
            <h1>Ideas</h1>
            <IdeasGrid ideas={sortedIdeas} />
            {hasNextPage && (
              <Button variant="outline" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
                Load more
              </Button>
            )}
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default DefaultChallengeDetailPage;
