import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrowLeft.svg';
import { getSubmission } from '../../util/api';
import { openMessageModal } from '../../redux/actions';
import { IUrlParams, ReactQueryKey, IModalType } from '../../util/types';
import { ERROR_MESSAGES } from '../../util/constants';
import FullScreenLoading from '../../components/FullScreenLoading';
import styles from './index.module.scss';
import EvaluationCreateForms from '../../components/EvaluationCreateForms';

const AdminIdeaEvaluationCreatePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { ideaId } = useParams<IUrlParams>();
  const { data: submission, isLoading } = useQuery([ReactQueryKey.Submission, { submissionId: ideaId }], () =>
    getSubmission({ ideaId }),
  );

  if (isLoading) return <FullScreenLoading />;

  if (!submission) {
    dispatch(
      openMessageModal({
        messageModalTitle: ERROR_MESSAGES.ERROR,
        messageModalContent: 'Idea not found',
        messageModalType: IModalType.Error,
        messageModalButtonText: 'I understand',
        messageModalButtonAction: () => history.push('/admin'),
      }),
    );

    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.controlPanel}>
        <div className={styles.backButtonContainer} onClick={() => history.push(`/admin/ideas/${ideaId}`)}>
          <ArrowLeftIcon className={styles.arrowLeftIcon} />
          <span>Back</span>
        </div>
      </div>

      <h1 className={styles.headerContainer}>
        <span className={styles.header}>Idea evaluation</span> /{' '}
        <span className={styles.ideaName}>{submission.idea.name}</span>
      </h1>

      <EvaluationCreateForms challengeId={submission.idea.challengeId} />
    </div>
  );
};

export default AdminIdeaEvaluationCreatePage;
