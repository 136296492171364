import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import AdminIdeas from '../../components/AdminIdeas';
import AdminPageTitle from '../../components/AdminPageTitle';
import ChallengeSelect, { IChallengeSelectOption } from '../../components/ChallengeSelect';
import FullScreenLoading from '../../components/FullScreenLoading';
import useChallengeFilters from '../../hooks/useChallengeFilters';
import { getChallenges } from '../../util/api';
import {
  IIdeaStatus,
  ISubmissionFindDirection,
  ISubmissionFindOrder,
  ISubmissionPhaseFilter,
  ReactQueryKey,
} from '../../util/types';

const INITIAL_FILTER = {
  status: IIdeaStatus.Published,
  includeRestrictedChallenges: true,
  includeInvisibleSubmissions: true,
  includeEvaluations: true,
  phase: ISubmissionPhaseFilter.All,
  order: ISubmissionFindOrder.Interesting,
  direction: ISubmissionFindDirection.Desc,
};

const AdminIdeasPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [filters, setFilters] = useChallengeFilters({ initialFilters: INITIAL_FILTER, defaultPath: '/admin/ideas' });
  const { data: challengesData, isLoading } = useQuery(ReactQueryKey.Challenges, getChallenges);
  const challenges = challengesData?.data;

  useEffect(() => {
    if (location.search) history.replace(location.search);
  }, []);

  if (isLoading) return <FullScreenLoading />;

  const handleOnChallengeSelectChange = (event: IChallengeSelectOption | null) =>
    event?.value ? setFilters({ ...filters, challenge: event.value }) : setFilters(INITIAL_FILTER);

  return (
    <div>
      <AdminPageTitle text="Submissions Management" />
      {challenges &&
        ((challenges.length === 0 && <div>No challenges found</div>) || (
          <div data-testid="challenge-select">
            <ChallengeSelect
              isClearable
              value={filters.challenge}
              onChange={handleOnChallengeSelectChange}
              challenges={challenges}
              placeholder="Select a challenge"
            />
            <AdminIdeas filters={filters} />
          </div>
        ))}
    </div>
  );
};

export default AdminIdeasPage;
