import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IChallenge, IUserGroup, ReactQueryKey } from '../../util/types';
import { queryClient } from '../../util/client';
import { unassignChallenge } from '../../util/api';
import { ReactComponent as TrashBinIcon } from '../../assets/icons/trashBin.svg';
import AlertModal from '../AlertModal';
import styles from './index.module.scss';

interface IProps {
  userGroup: IUserGroup;
  challenge: IChallenge;
  isEditable: boolean;
}

const UserGroupChallenge = ({ userGroup, challenge, isEditable }: IProps) => {
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false);

  const handleUnassignedChallenge = async () =>
    await unassignChallenge(userGroup.id, challenge.id).then(() =>
      queryClient.invalidateQueries([ReactQueryKey.Challenges, { userGroupId: userGroup.id }]),
    );

  return (
    <div className={styles.userGroupChallenge}>
      <Link to={`/admin/challenges/${challenge.id}`} target="_blank">
        {challenge.title}
      </Link>

      {isEditable && (
        <>
          <TrashBinIcon onClick={() => setIsAlertModalVisible(true)} />
          <AlertModal
            title="Unassign challenge"
            body="Are you sure you want to unassign this challenge from the user group?"
            isVisible={isAlertModalVisible}
            cancel={{ text: 'Cancel', onClick: () => setIsAlertModalVisible(false) }}
            confirm={{ text: 'Delete', onClick: handleUnassignedChallenge }}
          />
        </>
      )}
    </div>
  );
};

export default UserGroupChallenge;
