import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FullScreenLoading from '../../components/FullScreenLoading';
import { useActsOnBehalfOf } from '../../hooks/useActsOnBehalfOf';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { IUserPermission } from '../../util/types';
import styles from './index.module.scss';

// TODO: Temporary page until User List Page and User Detail Page is supported
const OnBehalfOfPage = () => {
  const history = useHistory();
  const { data: currentUser, isLoading, hasPermission } = useCurrentUser();
  const [email, setEmail] = useState('');
  const { values, actsOnBehalfOf, dismissActsOnBehalfOf } = useActsOnBehalfOf();

  if (isLoading) {
    return <FullScreenLoading />;
  }

  const canActsOnBehalfOf = hasPermission(IUserPermission.CanActOnBehalfOfOtherUser);

  if (!canActsOnBehalfOf) {
    history.replace('/404');
  }

  const handleActsOnBehalfOf = () => {
    if (email.trim().length !== 0) {
      actsOnBehalfOf(email);
    }
  };

  return (
    <div className={styles.wrapper}>
      {values ? (
        <div className={styles.container}>
          <h1>You are acting on behalf of "{currentUser?.givenName}"</h1>
          <button className={styles.button} onClick={dismissActsOnBehalfOf}>
            Dismiss
          </button>
        </div>
      ) : (
        <div className={styles.container}>
          <h1>You, "{currentUser?.givenName}", has God like power</h1>
          <p>Act on behalf of this poor person:</p>
          <input
            className={styles.input}
            type="text"
            placeholder="i.e. bryan@mooncreativelab.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className={styles.button} onClick={handleActsOnBehalfOf}>
            Do it
          </button>
        </div>
      )}
    </div>
  );
};

export default OnBehalfOfPage;
