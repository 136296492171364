import React, { memo } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import styles from './index.module.scss';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useAuthenticate } from '../../hooks/useAuthenticate';
import FullScreenLoading from '../../components/FullScreenLoading';

const SignUpApprovalPage = () => {
  const history = useHistory();
  const { logOut } = useAuthenticate();
  const { data: currentUser, isLoading } = useCurrentUser();

  if (isLoading) {
    return <FullScreenLoading />;
  }

  if (currentUser && currentUser.approved) {
    history.replace('/home');
  }

  const handleSignUp = async () => {
    history.replace('/signup-thankyou');
  };

  const decline = () => {
    logOut();
  };

  return (
    <div className={styles.wrapper}>
      <NavLink exact to="/home">
        <div className={styles.brand}>SMAP</div>
      </NavLink>
      <div className={styles.container}>
        <p className={styles.messageTitle}>
          You don’t yet have an account on SMAP.
          <br />
          Do you wish to create one?
        </p>
        <div className={styles.actionButtons}>
          <Button className={styles.agreeButton} onClick={handleSignUp}>
            Sign Up
          </Button>
          <Button className={styles.declineButton} variant="secondary" onClick={decline}>
            Decline
          </Button>
        </div>
        <p className={styles.smallMessage}>
          By clicking on "Sign Up" you confirm and accept&nbsp;
          <a className={styles.privacyLink} href="/privacy-policy" target="__blank">
            Moon’s Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};

export default memo(SignUpApprovalPage);
