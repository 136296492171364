import { useQuery } from 'react-query';
import { getUserGroupChallenges } from '../../util/api';
import { IUserGroup, ReactQueryKey } from '../../util/types';
import UserGroupChallenge from '../UserGroupChallenge';
import styles from './index.module.scss';

interface IProps {
  userGroup: IUserGroup;
  isEditable?: boolean;
}

const UserGroupChallenges = ({ userGroup, isEditable = false }: IProps) => {
  const { data: userGroupChallengesData } = useQuery([ReactQueryKey.Challenges, { userGroupId: userGroup.id }], () =>
    getUserGroupChallenges(userGroup.id),
  );

  const userGroupChallenges = userGroupChallengesData?.data;

  return (
    <div className={styles.userGroupChallenges}>
      <h3>Accessible Challenges</h3>
      {userGroupChallenges && (
        <div className={styles.userGroupChallenges__list} data-testid="user-group-challenges">
          {userGroupChallenges.map((challenge) => (
            <UserGroupChallenge
              key={challenge.id}
              userGroup={userGroup}
              challenge={challenge}
              isEditable={isEditable}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default UserGroupChallenges;
