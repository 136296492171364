import { takeEvery } from 'redux-saga/effects';
import { START_LOADING, END_LOADING } from '../actionConstants';
import { IBaseAction } from '../../util/types';

export function* startLoadingHandler(action: IBaseAction) {
  console.log('startLoading');
}

export function* endLoadingHandler(action: IBaseAction) {
  console.log('endLoading');
}

export default function* authSaga() {
  yield takeEvery(START_LOADING, startLoadingHandler);
  yield takeEvery(END_LOADING, endLoadingHandler);
}
