import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import useOnClickOutside from 'use-onclickoutside';
import { openMessageModal } from '../../redux/actions';
import { queryClient } from '../../util/client';
import { adminChangeIdeaPhase } from '../../util/api';
import { IIdea, IIdeaPhase, IModalType, ReactQueryKey } from '../../util/types';
import { capitalizeWord } from '../../util/helpers';
import styles from './index.module.scss';
import { useError } from '../../hooks/useError';

interface IProps {
  idea: IIdea;
}

const IdeaPhaseSelect = ({ idea }: IProps) => {
  const dispatch = useDispatch();
  const { handleError } = useError();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null as HTMLDivElement | null);
  useOnClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  const changeIdeaPhase = async (phase: IIdeaPhase) => {
    // TODO: add a useServerErrorHandling hook to handle server request error in a later PR
    await adminChangeIdeaPhase(idea.id, phase)
      .then(() => queryClient.invalidateQueries([ReactQueryKey.Submission, { submissionId: idea.id }]))
      .catch(handleError);
  };

  const confirmChangeIdeaPhase = (phase: IIdeaPhase) => {
    dispatch(
      openMessageModal({
        messageModalTitle: 'You are about to change idea status',
        messageModalContent: 'You are about to change the phase of this idea. Do you want to proceed?',
        messageModalType: IModalType.ChangeIdeaPhase,
        messageModalButtonText: 'Confirm',
        messageModalButtonAction: () => changeIdeaPhase(phase),
      }),
    );
    return;
  };

  const validPhases = {
    [IIdeaPhase.Initial]: [IIdeaPhase.Folded, IIdeaPhase.Pitched, IIdeaPhase.Selected],
    [IIdeaPhase.Folded]: [IIdeaPhase.Initial],
    [IIdeaPhase.Pitched]: [IIdeaPhase.Initial, IIdeaPhase.Folded, IIdeaPhase.Selected],
    [IIdeaPhase.Selected]: [IIdeaPhase.Initial, IIdeaPhase.Folded],
  }[idea.phase];

  return (
    <div
      className={classnames(styles.phase, styles[idea.phase])}
      onClick={() => setIsDropdownOpen(true)}
      data-testid="idea-phase-select"
    >
      <span>{capitalizeWord(idea.phase)}</span>
      <span className={styles.downarrow}></span>
      {/* Custom dropdown list */}
      {isDropdownOpen && (
        <div ref={dropdownRef} className={styles.dropdownWrapper}>
          <div className={classnames(styles.item, styles[idea.phase])} onClick={() => setIsDropdownOpen(false)}>
            <span>{capitalizeWord(idea.phase)}</span>
            <span className={styles.downarrow}></span>
          </div>
          <div data-testid="idea-phase-select-dropdown" className={styles.dropdownContainer}>
            {validPhases.map((phase) => (
              <div
                key={phase}
                className={classnames(styles.item, styles[phase.toUpperCase()])}
                onClick={() => confirmChangeIdeaPhase(phase)}
              >
                <span>{capitalizeWord(phase)}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default IdeaPhaseSelect;
