import { IForm } from '../../util/types';
import { Button } from '../Button';
import FormField from '../FormField';
import styles from './index.module.scss';

interface IProps {
  form: IForm;
}

const ChallengeFormPreview = ({ form }: IProps) => {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.header}>{form.title}</h3>
      <div className={styles.sections}>
        <div className={styles.descriptions}>
          <p className={styles.paragraph}>{form.shortDescription}</p>
          {form.longDescription && <p className={styles.paragraph}>{form.longDescription}</p>}
        </div>
        {form.fields && form.fields.length != 0 && (
          <div className={styles.formFields}>
            {form.fields.map((field) => (
              <FormField field={field} key={field.id} isPreview />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChallengeFormPreview;
