import { useEffect, useState } from 'react';
import { isEqual, difference } from 'lodash-es';
import { useQuery } from 'react-query';
import { ReactComponent as PenIcon } from '../../assets/icons/pen.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { submissionTag } from '../../util/helpers';
import { ISelectOption, ReactQueryKey, Uuid } from '../../util/types';
import { applySubmissionTag, deleteSubmissionTag, getTags } from '../../util/api';
import { queryClient } from '../../util/client';
import Select from '../Select';
import Badge from '../Badge';
import { Button } from '../Button';
import styles from './index.module.scss';

interface IProps {
  tags: string[];
  submissionId: Uuid;
  isEditable?: boolean;
}

const SubmissionTags = ({ tags, submissionId, isEditable = false }: IProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTags, setSelectedTags] = useState<ISelectOption<Uuid>[]>([]);
  const [deletingTags, setDeletingTags] = useState<ISelectOption<Uuid>[]>([]);
  const { data: tagsData } = useQuery([ReactQueryKey.Tags, { submissionId }], getTags);

  const tagOptions: ISelectOption<Uuid>[] =
    tagsData?.data.map((tag) => ({
      label: tag.name,
      value: tag.id,
    })) || [];
  const defaultValue = tagOptions.filter((tagOption) => tags.includes(tagOption.label));
  const isChanged = !isEqual(selectedTags, defaultValue);

  useEffect(() => {
    if (defaultValue) setSelectedTags(defaultValue);
  }, [tagsData]);

  const updateTags = async (
    tags: ISelectOption<Uuid>[],
    updaterFn: (submissionId: Uuid, tagId: Uuid) => Promise<any>,
  ) => {
    const tagIds = tags.map((tag) => tag.value);
    Promise.all(
      tagIds.map(
        async (tagId) =>
          await updaterFn(submissionId, tagId).then(() => {
            queryClient.invalidateQueries([ReactQueryKey.Tags, { submissionId }]);
            queryClient.invalidateQueries([ReactQueryKey.Submission, { submissionId }]);
            setIsEditing(false);
            setDeletingTags([]);
          }),
      ),
    );
  };

  const handleSave = async () => {
    if (!isChanged) return;
    if (deletingTags.length) updateTags(deletingTags, deleteSubmissionTag);
    updateTags(selectedTags, applySubmissionTag);
  };

  const handleOnChange = async (option: any) => {
    if (defaultValue.length > option.length) setDeletingTags(difference(defaultValue, option));
    setSelectedTags(option);
  };

  return (
    <>
      <div className={styles.submissionTags}>
        <h2>Tags</h2>

        {isEditing && isEditable ? (
          <>
            <CloseIcon className={styles.submissionTags__icon} onClick={() => setIsEditing(false)} />
            <Select
              defaultValue={defaultValue}
              isMulti
              isClearable={false}
              name="submission-tags"
              inputId="submissionTags"
              options={tagOptions}
              placeholder=""
              onChange={handleOnChange}
              width="100%"
            />
            <Button className={styles.button} variant="black" onClick={handleSave} disabled={!isChanged}>
              Save
            </Button>
          </>
        ) : (
          <>
            {isEditable ? <PenIcon className={styles.submissionTags__icon} onClick={() => setIsEditing(true)} /> : null}
            <div className={styles.submissionTags__tags} data-testid="submission-tags">
              {tags.length !== 0 ? (
                tags.map((tag) => <Badge key={tag} text={submissionTag(tag).text} variant={submissionTag(tag).color} />)
              ) : (
                <div>None yet</div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SubmissionTags;
