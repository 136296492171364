import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { useQuery } from 'react-query';
import { deleteUserGroup, getUserGroup, updateUserGroup } from '../../util/api';
import { IUrlParams, IUserGroupFormInputs, IUserPermission, ReactQueryKey } from '../../util/types';
import { queryClient } from '../../util/client';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import AdminPageTitle from '../../components/AdminPageTitle';
import BackButton from '../../components/BackButton';
import { Button } from '../../components/Button';
import UserGroupEditForm from '../../components/UserGroupEditForm';
import AlertModal from '../../components/AlertModal';
import Modal from '../../components/Modal';
import ChallengesAssignForm from '../../components/ChallengesAssignForm';
import styles from './index.module.scss';
import UserGroupChallenges from '../../components/UserGroupChallenges';
import { useError } from '../../hooks/useError';

const AdminUserGroupEditPage = () => {
  const history = useHistory();
  const { userGroupId } = useParams<IUrlParams>();
  const { hasPermission } = useCurrentUser();
  const updateUserGroupForm = useForm({ reValidateMode: 'onSubmit' });
  const [isDeleteAlertModalVisible, setIsDeleteAlertModalVisible] = useState(false);
  const [isAssignChallengesFormVisible, setIsAssignChallengesFormVisible] = useState(false);
  const [unassignedEmails, setUnassignedEmails] = useState<string[]>([]);

  const { data: userGroup, isLoading } = useQuery([ReactQueryKey.UserGroup, userGroupId], () =>
    getUserGroup(userGroupId),
  );

  // TODO: Add a submit evaluation error model
  const { handleError } = useError();

  if (!hasPermission(IUserPermission.CanManageUserGroups)) {
    // TODO: show modal to explain no permission and redirect to user groups page
    return <div>No permission to manage user groups!</div>;
  }

  const toUpdateUserGroup = async (data: IUserGroupFormInputs) => {
    // TODO: add a useServerErrorHandling hook to handle server request error in a later PR
    const { name, description, code, userEmails } = data;
    await updateUserGroup(userGroupId, {
      name,
      description,
      code,
      userEmails: userEmails ? userEmails.split(',').map((email: string) => email.trim()) : [],
    })
      .then((res) => {
        if (res.unassignedEmails.length > 0) {
          setUnassignedEmails(res.unassignedEmails);
        } else {
          completeTransaction();
        }
      })
      .catch(handleError);
  };

  const completeTransaction = () => {
    setUnassignedEmails([]);
    queryClient.invalidateQueries([ReactQueryKey.Challenges, ReactQueryKey.UserGroup, userGroupId]);
    history.push(`/admin/user-groups/${userGroupId}`);
  };

  const handleDeleteUserGroup = async () => {
    await deleteUserGroup(userGroupId)
      .then(() => {
        queryClient.invalidateQueries(ReactQueryKey.UserGroups);
        history.push('/admin/user-groups');
      })
      .catch(handleError);
  };

  return (
    <div>
      <AdminPageTitle text="User Groups Management" />
      <div className={styles.container}>
        {isLoading && <p>loading...</p>}
        {userGroup && (
          <>
            <div className={styles.actions}>
              <Link to={`/admin/user-groups/${userGroupId}`} className={styles.backButton} data-testid="back-icon">
                <BackButton />
              </Link>
              <div className={styles.control}>
                <Button
                  className={styles.button}
                  style={{ backgroundColor: '#EF4444' }}
                  onClick={() => setIsDeleteAlertModalVisible(true)}
                >
                  Delete User Group
                </Button>
                <Button
                  className={styles.button}
                  variant="black"
                  onClick={() => setIsAssignChallengesFormVisible(true)}
                >
                  Assign Challenges
                </Button>
                <Button
                  className={styles.button}
                  variant="black"
                  onClick={updateUserGroupForm.handleSubmit(toUpdateUserGroup)}
                >
                  Update User Group
                </Button>
              </div>
            </div>
            <UserGroupEditForm userGroup={userGroup} form={updateUserGroupForm} />
            <UserGroupChallenges userGroup={userGroup} isEditable />
            {/* TODO: Refactor the component to display UserShortsList from User Group Detail Page */}
            {/* <UserShortsList /> */}

            <AlertModal
              title={`Delete the User Group: ${userGroup.name}`}
              body={<div>Are you sure you want to delete the user group?</div>}
              isVisible={isDeleteAlertModalVisible}
              cancel={{ text: 'Cancel', onClick: () => setIsDeleteAlertModalVisible(false) }}
              confirm={{ text: 'Yes, delete it', onClick: handleDeleteUserGroup }}
            />

            <Modal isVisible={isAssignChallengesFormVisible} dismiss={() => setIsAssignChallengesFormVisible(false)}>
              <ChallengesAssignForm userGroup={userGroup} onSubmit={() => setIsAssignChallengesFormVisible(false)} />
            </Modal>

            <Modal isVisible={unassignedEmails.length > 0} dismiss={completeTransaction}>
              <div>
                <div>{`${unassignedEmails.length} unassigned emails: `}</div>
                {unassignedEmails.map((email) => (
                  <li key={email}>{email}</li>
                ))}
              </div>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminUserGroupEditPage;
