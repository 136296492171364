import React, { memo, ReactNode } from 'react';
import { Redirect } from 'react-router';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { IUserPermission } from '../../util/types';
import AdminUserProfile from '../../components/AdminUserProfile';
import AdminSideMenu from '../../components/AdminSideMenu';
import styles from './index.module.scss';

interface IProps {
  children: ReactNode;
}

const AdminLayout = ({ children }: IProps) => {
  const { data: currentUser } = useCurrentUser();

  const canSeeAdmin = Boolean(
    currentUser?.permissions && currentUser.permissions.includes(IUserPermission.CanSeeAdminUI),
  );

  if (!canSeeAdmin || !currentUser) {
    return <Redirect to="/404" />;
  }

  return (
    <div className={styles.wrapper}>
      <AdminSideMenu />
      <div className={styles.mainContent}>
        <AdminUserProfile user={currentUser} />
        {children}
      </div>
    </div>
  );
};

export default memo(AdminLayout);
