import ReactTooltip from 'react-tooltip';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { IIdea, IIdeaStatus, IIdeaVisibility } from '../../util/types';
import Badge from '../Badge';
import styles from './index.module.scss';

interface IProps {
  idea: IIdea;
}

const IdeaBadges = ({ idea }: IProps) => {
  const { data: currentUser } = useCurrentUser();

  const isAuthorCurrentUser = idea.author.id === currentUser?.id;
  const isDraftIdea = idea.status === IIdeaStatus.Draft;
  const isVisibilityToAll = idea.visibilityOverride
    ? idea.visibilityOverride === IIdeaVisibility.All
    : idea.challengeVisibilityDefault === IIdeaVisibility.All;
  const isPublishedIdeaPubliclyVisibleToAll = !isDraftIdea && idea.publishedAt && isVisibilityToAll;

  return (
    <div className={styles.wrapper}>
      {idea.pitched && <Badge text="Pitched" variant="green" />}
      {idea.selected && <Badge text="Selected" variant="blue" />}
      {isDraftIdea && <Badge text="Draft" variant="grey" />}
      {idea.status === IIdeaStatus.Published && isAuthorCurrentUser && (
        <>
          <Badge
            text={isPublishedIdeaPubliclyVisibleToAll ? 'Public' : 'Private'}
            variant="black"
            data-tip
            data-for="ideaVisibilityBadge"
          />
          <ReactTooltip id="ideaVisibilityBadge" effect="solid">
            {isPublishedIdeaPubliclyVisibleToAll
              ? 'visible to all or selected users in smap.'
              : 'invisible to all users but visible to the smap team.'}
          </ReactTooltip>
        </>
      )}
    </div>
  );
};

export default IdeaBadges;
