import { Control, Controller, FieldValues } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Switch from '../Switch';
import styles from './index.module.scss';
import QuestionMarkTooltip from '../QuestionMarkTooltip';

interface IProps {
  label: string;
  control: Control<FieldValues, object>;
  name: string;
  errors: { [x: string]: any };
  // See: https://react-hook-form.com/api/usecontroller/controller for Validation rules
  rules?: object;
  dataTestId?: string;
  tooltip?: string;
}

const HookFormSwitch = ({ name, control, label, errors, rules, dataTestId, tooltip }: IProps) => {
  return (
    <div>
      <div className={styles.labelContainer}>
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
        {tooltip && <QuestionMarkTooltip text={tooltip} />}
      </div>
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({ field: { onChange, value, name } }) => (
          <Switch name={name} onChange={onChange} checked={value || false} dataTestId={dataTestId} />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <span style={{ color: 'red' }}>*{message}</span>}
      />
    </div>
  );
};

export default HookFormSwitch;
