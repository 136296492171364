import { FieldValues, UseFormRegister } from 'react-hook-form';
import { IRangeFormField, ISubmissionField } from '../../util/types';
import styles from './index.module.scss';

interface IProps {
  field: IRangeFormField;
  register: UseFormRegister<FieldValues>;
  defaultValue?: ISubmissionField;
}

const RangeFormFieldInput = ({ field, register, defaultValue }: IProps) => {
  const stepCount = field.to - field.from + 1;
  const steps = Array.from({ length: stepCount }, (_, key) => key + 1);
  const defaultRangeValue = defaultValue?.value;

  return (
    <div className={styles.wrapper}>
      <div>{field.fromLabel}</div>
      <div className={styles.steps}>
        {steps.map((step) => (
          <div key={step} className={styles.step}>
            <input
              {...register(field.id, { required: field.isRequired && 'this field is required' })}
              type="radio"
              value={step}
              defaultChecked={step === defaultRangeValue || step === field.fieldDefault}
            />
            <label data-testid={`range-step-${step}`}>{step}</label>
          </div>
        ))}
      </div>
      <div>{field.toLabel}</div>
    </div>
  );
};

export default RangeFormFieldInput;
