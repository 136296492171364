import { useQuery } from 'react-query';
import { getChallenge } from '../../util/api';
import { ReactQueryKey, Uuid } from '../../util/types';
import EvaluationForm from '../EvaluationForm';
import FullScreenLoading from '../FullScreenLoading';
import styles from './index.module.scss';

interface IProps {
  challengeId: Uuid;
  evaluationId: Uuid;
}

const EvaluationForms = ({ challengeId, evaluationId }: IProps) => {
  const { data: challenge, isLoading: isLoading } = useQuery([ReactQueryKey.Challenge, { challengeId }], () =>
    getChallenge(challengeId),
  );

  if (isLoading || !challenge) {
    return <FullScreenLoading />;
  }

  const evaluationForms = challenge.evaluationForms;
  const hasEvaluationForms = evaluationForms && evaluationForms.length !== 0;

  return (
    <div>
      {hasEvaluationForms && (
        <div className={styles.formsContainer}>
          {evaluationForms.map((form) => (
            <div key={form.id} data-testid={`form-${form.id}`}>
              <EvaluationForm form={form} evaluationId={evaluationId} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EvaluationForms;
