import React, { memo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import styles from './index.module.scss';

const MaintenancePage = () => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();

  if (currentUser) {
    history.replace('/home');
  }

  return (
    <div className={styles.wrapper}>
      <NavLink exact to="/home">
        <div className={styles.brand}>SMAP</div>
      </NavLink>
      <div className={styles.container}>
        <p className={styles.messageTitle}>Under Maintenance - Sorry!</p>
        <p className={styles.messageText}>Please come back later!</p>
      </div>
    </div>
  );
};

export default memo(MaintenancePage);
