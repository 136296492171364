import DayPickerInput from 'react-day-picker/DayPickerInput';
import dateFnsFormat from 'date-fns/format';
import 'react-day-picker/lib/style.css';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import styles from './index.module.scss';

interface IProps {
  name: string;
  selected: Date;
  onChange: (date: Date) => void;
  dataTestId?: string;
}

const DatePicker = ({ name, selected, onChange, dataTestId }: IProps) => {
  const FORMAT = 'MM/dd/yyyy';
  const formatDate = (date: Date, format: string, locale: any) => dateFnsFormat(date, format, { locale });

  const Calendar = ({ children, ...props }: any) => {
    return (
      <div {...props} style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            bottom: '2.25rem',
            backgroundColor: '#fff',
            border: '1px solid #e5e7eb',
            borderRadius: '0.25rem',
          }}
        >
          {children}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <DayPickerInput
          overlayComponent={Calendar}
          format={FORMAT}
          formatDate={formatDate}
          placeholder="Select date"
          value={selected}
          inputProps={{
            name: name,
            ['data-testid']: dataTestId,
            className: styles.input,
          }}
          onDayChange={onChange}
        />
        <CalendarIcon className={styles.icon} />
      </div>
    </div>
  );
};

export default DatePicker;
