import { capitalizeWord } from '../../util/helpers';
import { IBlobFormField } from '../../util/types';
import { Button } from '../Button';
import styles from './index.module.scss';

interface IProps {
  field: IBlobFormField;
}

const BlobFormField = ({ field }: IProps) => (
  <Button variant="black" className={styles.button}>
    Upload {capitalizeWord(field.blobType)}
  </Button>
);

export default BlobFormField;
