import { useState } from 'react';
import { IBlob, ISubmissionField, Uuid } from '../util/types';

export const useBlobFieldsSubmission = () => {
  const [blobSubmissionFields, setBlobSubmissionFields] = useState<ISubmissionField[] | null>(null);

  const findBlobSubmissionField = (blobFieldId: Uuid): ISubmissionField | undefined =>
    blobSubmissionFields?.find((blobSubmissionField) => blobSubmissionField?.fieldId === blobFieldId);

  // Transform uploaded response into ISubmissionField shape.
  // If there is an existing blobSubmissionField with the same fieldId, replace it.
  // else, keep it as is.
  const onBlobUploadSuccess = (response: IBlob) => {
    const newBlobField = { fieldId: response.uploadTags?.originId, value: response } as ISubmissionField;

    setBlobSubmissionFields((prev) => {
      if (prev) {
        const sameBlob = prev.find((blobSubmissionField) => blobSubmissionField.fieldId === newBlobField.fieldId);
        if (sameBlob) {
          return prev.map((field) => (field.fieldId === newBlobField.fieldId ? newBlobField : field));
        } else {
          return [...prev, newBlobField];
        }
      } else {
        return prev;
      }
    });
  };

  const handleRemoveUploadedVideo = (video: IBlob) => {
    setBlobSubmissionFields(
      (prev) =>
        prev &&
        prev.filter((prevBlobSubmissionField) => prevBlobSubmissionField?.fieldId !== video?.uploadTags?.originId),
    );
  };

  const handleRemoveSubmissionField = (video: ISubmissionField) =>
    setBlobSubmissionFields(
      (prev) => prev && prev.filter((prevBlobSubmissionField) => prevBlobSubmissionField?.fieldId !== video.fieldId),
    );

  return {
    findBlobSubmissionField,
    blobSubmissionFields,
    onBlobUploadSuccess,
    handleRemoveSubmissionField,
    handleRemoveUploadedVideo,
    setBlobSubmissionFields,
  };
};
