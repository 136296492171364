import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { openMessageModal } from '../../redux/actions';
import { getUserGroups } from '../../util/api';
import { ERROR_MESSAGES } from '../../util/constants';
import { formatDate } from '../../util/helpers';
import { IModalType, ReactQueryKey } from '../../util/types';
import Grid from '../Grid';
import styles from './index.module.scss';

const UserGroups = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: userGroupsData, isLoading } = useQuery(ReactQueryKey.UserGroups, getUserGroups);

  if (isLoading) {
    return <p>loading...</p>;
  }

  const userGroups = userGroupsData?.data;

  // TODO: Better handle server error
  if (!userGroups) {
    dispatch(
      openMessageModal({
        messageModalTitle: ERROR_MESSAGES.ERROR,
        messageModalContent: 'No user groups found',
        messageModalType: IModalType.Error,
        messageModalButtonText: 'I understand',
        messageModalButtonAction: () => history.push('/admin'),
      }),
    );

    return null;
  }

  const columns = [<span>User Groups</span>, <span>Code</span>, <span>Number of Users</span>, <span>Created At</span>];

  const rows = userGroups.map((userGroup) => [
    <span className={styles.userGroupColumn}>
      <Link to={`/admin/user-groups/${userGroup.code === 'default' ? '' : userGroup.id}`}>
        <div className={styles.name}>{userGroup.name}</div>
        {userGroup.description && <div className={styles.description}>{userGroup.description}</div>}
      </Link>
    </span>,
    <span>{userGroup.code}</span>,
    <span>{userGroup.numberOfUsers}</span>,
    <span>{userGroup.createdAt ? formatDate(userGroup.createdAt) : '-'}</span>,
  ]);

  return <Grid columns={columns} rows={rows} />;
};

export default UserGroups;
