import { useQuery } from 'react-query';
import { IUserShort, ReactQueryKey } from '../../util/types';
import styles from './index.module.scss';
import FullScreenLoading from '../FullScreenLoading';
import ProfileAvatar from '../ProfileAvatar';
import { getUserIdeas, getUserProfile } from '../../util/api';
import { ReactComponent as BulbIcon } from '../../assets/icons/bulb.svg';

interface IProps {
  user: IUserShort;
}

const IdeaOwnerProfile = ({ user }: IProps) => {
  const { data: userIdeas } = useQuery([ReactQueryKey.UserIdeas, { userId: user.id }], () => getUserIdeas(user.id));
  const { data: userProfile } = useQuery([ReactQueryKey.UserProfile, { userId: user.id }], () =>
    getUserProfile(user.id),
  );

  if (!userIdeas || !userProfile) return null;

  const ideaSubmittedText = userIdeas.data.length > 1 ? `${userIdeas.data.length} ideas submitted` : '1 idea submitted';
  const userName = userProfile.givenName + ' ' + userProfile.familyName;

  return (
    <div className={styles.wrapper}>
      <div className={styles.userBasicInfo}>
        <ProfileAvatar large avatarUrl={userProfile.avatar?.url} firstName={userProfile.givenName} />
        <div>
          <div className={styles.name}>{userName}</div>
          <div className={styles.role}>{userProfile.role}</div>
          <div className={styles.organization}>{userProfile.organization}</div>
        </div>
      </div>
      <div className={styles.ideaSubmitted}>
        <BulbIcon />
        <span>{ideaSubmittedText}</span>
      </div>
      <div className={styles.bio}>{userProfile.bio}</div>
      <div className={styles.info}>
        {userProfile.location && (
          <>
            <div>LOCATION</div>
            <div>{userProfile.location}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default IdeaOwnerProfile;
