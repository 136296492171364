import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ISubmission, ReactQueryKey } from '../../util/types';
import { updateSubmissionFeedback } from '../../util/api';
import { queryClient } from '../../util/client';
import HookFormRichTextInput from '../HookFormRichTextInput';
import { Button } from '../Button';
import styles from './index.module.scss';
import { useError } from '../../hooks/useError';

interface IProps {
  submission: ISubmission;
  onSubmit: () => void;
}

const PitchFeedbackForm = ({ submission, onSubmit }: IProps) => {
  const { control, formState, handleSubmit, setValue } = useForm();
  const { handleError } = useError();

  useEffect(() => {
    setValue('prepitchFeedback', submission.idea.prepitchFeedback);
    setValue('pitchIOComment', submission.idea.pitchIOComment);
    setValue('pitchFeedback', submission.idea.pitchFeedback);
    setValue('ioTestimonial', submission.idea.ioTestimonial);
  }, []);

  const toUpdateFeedback = async (data: any) => {
    const { prepitchFeedback, pitchIOComment, pitchFeedback, ioTestimonial } = data;

    await updateSubmissionFeedback(submission.idea.id, {
      prepitchFeedback: prepitchFeedback || undefined,
      pitchIOComment: pitchIOComment || undefined,
      pitchFeedback: pitchFeedback || undefined,
      ioTestimonial: ioTestimonial || undefined,
    })
      .then(() => {
        queryClient.invalidateQueries([ReactQueryKey.Submission, { submissionId: submission.idea.id }]);
        onSubmit();
      })
      .catch(handleError);
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>Idea Owner Feedback Form</h3>
      <form className={styles.form} data-testid="idea-owner-feedback-form">
        <HookFormRichTextInput
          label="Prepitch Feedback"
          name="prepitchFeedback"
          control={control}
          errors={formState.errors}
          height={150}
          dataTestId="prepitch-feedback-input"
        />
        <HookFormRichTextInput
          label="Pitch Comment"
          name="pitchIOComment"
          control={control}
          errors={formState.errors}
          height={150}
          dataTestId="pitch-io-comment-input"
        />
        <HookFormRichTextInput
          label="Pitch Feedback"
          name="pitchFeedback"
          control={control}
          errors={formState.errors}
          height={150}
          dataTestId="pitch-feedback-input"
        />
        <HookFormRichTextInput
          label="Testimonial"
          name="ioTestimonial"
          control={control}
          errors={formState.errors}
          height={150}
          dataTestId="io-testimonial-input"
        />
      </form>
      <Button className={styles.button} variant="black" onClick={handleSubmit(toUpdateFeedback)}>
        Submit
      </Button>
    </div>
  );
};

export default PitchFeedbackForm;
