import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './index.module.scss';

const LoginApprovalPage = () => {
  return (
    <div className={styles.wrapper}>
      <NavLink exact to="/home">
        <div className={styles.brand}>SMAP</div>
      </NavLink>
      <div className={styles.container}>
        <p className={styles.messageTitle}>Looks like your organization is not yet on SMAP</p>
        <p className={styles.messageText}>
          Get in touch with Moon via&nbsp;
          <a className={styles.emailLink} href="mailto:support@mooncreativelab.com">
            support@mooncreativelab.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default memo(LoginApprovalPage);
