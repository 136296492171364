import React, { memo, useCallback, useState, useEffect, FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
import { startLoading, endLoading, openMessageModal } from '../../redux/actions';
import { createSubmission, updateSubmission, getSubmission, getChallenge } from '../../util/api';
import styles from './index.module.scss';
import {
  IUrlParams,
  ISubmission,
  IBlob,
  Uuid,
  IError,
  IIdeaStatus,
  FileStackMaxSize,
  FileStackFromSource,
  ReactQueryKey,
  IModalType,
  IBlobOrigin,
} from '../../util/types';
import { NAME_PLACEHOLDER, DESC_PLACEHOLDER, ERROR_MESSAGES, MAINTENANCE_STATUS_CODE } from '../../util/constants';
import IdeaCardPreview from '../../components/IdeaCardPreview';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import PageWrapper from '../../components/PageWrapper';
import { SecondaryButton } from '../../components/Button';
import SubmitIdeaCongratulationModal from '../../components/SubmitIdeaCongratulationModal';
import { useFilestack } from '../../hooks/useFilestack';
import { useQuery } from 'react-query';
import FullScreenLoading from '../../components/FullScreenLoading';
import { queryClient } from '../../util/client';

const UserSubmissionIdeaPage = () => {
  const { openFilestack } = useFilestack();
  const dispatch = useDispatch();
  const history = useHistory();

  const { challengeId, submissionId } = useParams<IUrlParams>();
  const { data: challenge, isLoading } = useQuery([ReactQueryKey.Challenge, { challengeId }], () =>
    getChallenge(challengeId),
  );

  const [processedSubmissionId, setProcessedSubmissionId] = useState<Uuid>(submissionId);

  const [imageUrl, setImageUrl] = useState('');
  const [image, setImage] = useState('');
  const [filestackObj, setFilestackObj] = useState<IBlob | null>(null);
  const [ideaName, setIdeaName] = useState('');
  const [ideaDescription, setIdeaDescription] = useState('');
  const [isCongratulationModalVisible, setIsCongratulationModalVisible] = useState(false);

  useEffect(() => {
    if (!challengeId) {
      return;
    }

    if (processedSubmissionId) {
      getSubmission({ ideaId: processedSubmissionId }).then((submissionDetail: ISubmission) => {
        if (submissionDetail && submissionDetail.idea) {
          let imageName = submissionDetail.idea.image?.filename || '';
          if (!imageName) {
            const urlSplitArray = submissionDetail.idea.image?.url.split('/');
            if (Array.isArray(urlSplitArray)) {
              imageName = urlSplitArray[urlSplitArray.length - 1];
            }
          }
          setImage(imageName);
          setImageUrl(submissionDetail.idea.image?.url || '');
          setIdeaName(submissionDetail.idea.name === NAME_PLACEHOLDER ? '' : submissionDetail.idea.name);

          if (submissionDetail.idea?.description) {
            setIdeaDescription(
              submissionDetail.idea.description === DESC_PLACEHOLDER ? '' : submissionDetail.idea.description,
            );
          } else {
            setIdeaDescription('');
          }
        }
      });
    }
  }, [challengeId, processedSubmissionId]);

  const uploadSuccess = (filestackResponse: IBlob) => {
    let imageName = filestackResponse.filename;
    if (!imageName) {
      const urlSplitArray = filestackResponse.url.split('/');
      imageName = urlSplitArray[urlSplitArray.length - 1];
    }
    setImage(imageName);
    setImageUrl(filestackResponse.url);
    setFilestackObj(filestackResponse);
  };

  const onFileSelected = useCallback((file) => {
    if (file.size > FileStackMaxSize.Image) {
      dispatch(
        openMessageModal({
          messageModalTitle: ERROR_MESSAGES.ERROR,
          messageModalContent: ERROR_MESSAGES.FILESTACK_MAX_SIZE_ERROR,
          messageModalType: IModalType.Error,
          messageModalButtonText: 'I understand',
          messageModalButtonAction: null,
        }),
      );
    }
  }, []);

  const handlingError = (err: any) => {
    dispatch(endLoading());

    const error = err.error as IError;
    let errorMessage = '';
    const errorContext = error.status === MAINTENANCE_STATUS_CODE ? '' : ERROR_MESSAGES.SUBMISSION_ERROR_CONTEXT;

    if (Array.isArray(error.data)) {
      errorMessage = error.data.map((item: any) => item.errorCode).join('\n');
    } else {
      errorMessage = error.data.message;
    }

    dispatch(
      openMessageModal({
        messageModalTitle: ERROR_MESSAGES.ERROR,
        messageModalContent: `${errorContext}${errorMessage}`,
        messageModalType: IModalType.Error,
        messageModalButtonText: 'I understand',
        messageModalButtonAction: null,
      }),
    );
  };

  const uploadPhoto = useCallback(async () => {
    if (!processedSubmissionId) {
      // submission-id is REQUIRED by the API -> must create basic idea-submission first
      // TODO: Properly handle createIdea error instead of return void.
      const createdSubmissionId = await createIdea({ proceedNext: false });
      if (createdSubmissionId) {
        setProcessedSubmissionId(createdSubmissionId);
        openFilestack({
          originType: IBlobOrigin.Idea,
          originId: createdSubmissionId,
          onFileUploadFinished: uploadSuccess,
          onFileSelected: onFileSelected,
          fromSources: [
            FileStackFromSource.LocalFileSystem,
            FileStackFromSource.Url,
            FileStackFromSource.Unsplash,
            FileStackFromSource.GoogleDrive,
          ],
        });
      }

      return;
    }

    openFilestack({
      originType: IBlobOrigin.Idea,
      originId: processedSubmissionId,
      onFileUploadFinished: uploadSuccess,
      onFileSelected: onFileSelected,
      fromSources: [
        FileStackFromSource.LocalFileSystem,
        FileStackFromSource.Url,
        FileStackFromSource.Unsplash,
        FileStackFromSource.GoogleDrive,
      ],
    });
  }, [processedSubmissionId, ideaName, ideaDescription]);

  const resetPhoto = useCallback(() => {
    setImageUrl('');
    setImage('');
    setFilestackObj(null);
  }, []);

  const handleTitleChange = useCallback((event: FormEvent<HTMLInputElement>) => {
    setIdeaName(event.currentTarget.value);
  }, []);

  const handleDescriptionChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIdeaDescription(event.target.value);
  }, []);

  const createIdea = useCallback(
    async ({ proceedNext = true }) => {
      if (!challengeId) {
        return;
      }

      dispatch(startLoading());

      let submissionIdForNextStep: Uuid;

      if (!processedSubmissionId) {
        // Create new basic submission first to hold the idea
        const ideaSubmission = await createSubmission({
          name: ideaName || NAME_PLACEHOLDER,
          description: ideaDescription || DESC_PLACEHOLDER,
          image: filestackObj || { url: imageUrl },
          challengeId,
        }).catch(handlingError);

        if (!ideaSubmission || !ideaSubmission.id) {
          return;
        }

        submissionIdForNextStep = ideaSubmission.id;
        setProcessedSubmissionId(submissionIdForNextStep);

        // improve UX: set name & description to dummy values so users can go next step
        if (!ideaName) {
          setIdeaName(NAME_PLACEHOLDER);
        }
        if (!ideaDescription) {
          setIdeaDescription(DESC_PLACEHOLDER);
        }
      } else {
        // Edit basic idea info
        const editSubmission = await updateSubmission(processedSubmissionId, {
          idea: {
            name: ideaName || NAME_PLACEHOLDER,
            description: ideaDescription || DESC_PLACEHOLDER,
            image: filestackObj || { url: imageUrl },
          },
        }).catch(handlingError);

        if (!editSubmission) {
          return;
        }

        submissionIdForNextStep = processedSubmissionId;
      }

      dispatch(endLoading());

      if (proceedNext) {
        if (!hasChallengeForms) {
          history.replace('/my/ideas?tab=DRAFT');
        } else {
          // Replace here first, so on the next Form page, when browser's back button is clicked -> Not create new blank submission
          history.replace(`/challenges/${challengeId}/submission/${submissionIdForNextStep}`);
          history.push(`/challenges/${challengeId}/submission/${submissionIdForNextStep}/form`);
        }
      }

      return submissionIdForNextStep;
    },
    [challengeId, ideaName, ideaDescription, image, imageUrl, processedSubmissionId, filestackObj, challenge],
  );

  if (isLoading || !challenge) {
    return <FullScreenLoading />;
  }

  const hasChallengeForms = challenge.forms.length > 0;

  const onCongratulationModalDismiss = () => {
    setIsCongratulationModalVisible(false);
    queryClient.invalidateQueries(ReactQueryKey.Submissions);
    history.replace(`/ideas?challenge=${challengeId}`);
  };

  const publishIdea = async (submissionId: Uuid) => {
    try {
      await updateSubmission(submissionId, {
        idea: { name: ideaName, description: ideaDescription, image: { url: imageUrl } },
        status: IIdeaStatus.Published,
      });
      setIsCongratulationModalVisible(true);
    } catch (error) {
      handlingError(error);
    }
  };

  const handleSubmitIdea = async () => {
    if (!imageUrl || ideaName === NAME_PLACEHOLDER || ideaDescription === DESC_PLACEHOLDER) {
      dispatch(
        openMessageModal({
          messageModalTitle: ERROR_MESSAGES.ERROR,
          messageModalContent: ERROR_MESSAGES.ASSURE_NAME_DESC_IMAGE_BEFORE,
          messageModalType: IModalType.Error,
          messageModalButtonText: 'I understand',
        }),
      );
      return;
    }

    if (processedSubmissionId) {
      publishIdea(processedSubmissionId);
    } else {
      try {
        const ideaSubmission = await createSubmission({
          name: ideaName,
          description: ideaDescription,
          image: { url: imageUrl },
          challengeId,
        });
        publishIdea(ideaSubmission.id);
      } catch (error) {
        handlingError(error);
      }
    }
  };

  const handleSaveDraft = async () => {
    if (processedSubmissionId) {
      try {
        await updateSubmission(processedSubmissionId, {
          idea: {
            name: ideaName || NAME_PLACEHOLDER,
            description: ideaDescription || DESC_PLACEHOLDER,
            image: { url: imageUrl },
          },
          status: IIdeaStatus.Draft,
        });
        history.replace('/my/ideas?tab=DRAFT');
      } catch (error) {
        handlingError(error);
      }
    } else {
      try {
        await createSubmission({
          name: ideaName || NAME_PLACEHOLDER,
          description: ideaDescription || DESC_PLACEHOLDER,
          image: { url: imageUrl },
          challengeId,
        });
        history.replace('/my/ideas?tab=DRAFT');
      } catch (error) {
        handlingError(error);
      }
    }
  };

  return (
    <PageWrapper>
      <div>
        <Row>
          <Col md={8}>
            <div className={styles.sectionTitle}>Step 1: Let’s start by sharing a little bit about your idea</div>
            <div className={styles.sectionDescription}>
              This cover page is how your idea will be introduced to Moon. No need to get fancy here, we just encourage
              you to use a little imagination to help you visualize your idea.
            </div>
          </Col>
        </Row>
        <Row className={styles.ideaCreationArea}>
          <Col md={8} className={styles.leftPart}>
            <Row className={styles.questionContainer}>
              <Col>
                <div className={styles.questionTitle}>1. Give your idea a name</div>
                <div className={styles.questionDescription}>
                  All ideas come to life by giving them a memorable name.
                </div>
                <input
                  className={styles.textInput}
                  placeholder="Type project name"
                  onChange={handleTitleChange}
                  value={ideaName}
                  data-cy="idea-name-input"
                />
              </Col>
            </Row>
            <Row className={styles.questionContainer}>
              <Col>
                <div className={styles.questionTitle}>
                  2. Take a moment to briefly describe your idea in 1-2 sentences
                </div>
                <div className={styles.questionDescription}>
                  If you had 10 seconds to pitch your idea to a friend, what would you say?
                </div>
                <textarea
                  className={styles.textAreaInput}
                  placeholder="Description"
                  rows={3}
                  onChange={handleDescriptionChange}
                  value={ideaDescription}
                  data-cy="idea-description-input"
                />
              </Col>
            </Row>
            {/* Idea's image area */}
            <Row className={styles.imgUploadArea}>
              <Col>
                <div className={styles.questionTitle}>3. Select a cover image for your idea</div>
                <div className={styles.questionDescription}>
                  Upload any picture that helps you express your idea visually. (Check out pexels.com or unsplash.com
                  for royalty free images to use.)
                </div>
                <div className={styles.uploadBtnContainer}>
                  <SecondaryButton
                    data-cy="idea-photo-upload-button"
                    className={styles.uploadButton}
                    onClick={uploadPhoto}
                  >
                    Upload a Photo
                  </SecondaryButton>
                  {image && (
                    <div className={styles.imageNameContainer}>
                      <div data-cy="idea-image-name" className={styles.imageName}>
                        {image}
                      </div>
                      <CloseIcon onClick={resetPhoto} className={styles.hasHoverEffect} />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            {/* End */}
          </Col>
          <Col md={4} className={styles.previewArea}>
            <div className={styles.caption}>Preview</div>
            <IdeaCardPreview name={ideaName} description={ideaDescription} imageUrl={imageUrl} />
          </Col>
        </Row>

        <div className={styles.buttonArea}>
          {hasChallengeForms ? (
            <Button className={styles.saveButton} data-cy="create-idea" variant="primary" onClick={createIdea}>
              Save & Continue
            </Button>
          ) : (
            <>
              <Button
                className={styles.saveButton}
                data-cy="create-idea"
                variant="outline-primary"
                onClick={handleSaveDraft}
              >
                Save Draft
              </Button>
              <Button className={styles.submitButton} onClick={handleSubmitIdea}>
                Submit
              </Button>
            </>
          )}
        </div>
      </div>

      <SubmitIdeaCongratulationModal isVisible={isCongratulationModalVisible} dismiss={onCongratulationModalDismiss} />
    </PageWrapper>
  );
};

export default memo(UserSubmissionIdeaPage);
