import { FieldValues, UseFormRegister } from 'react-hook-form';
import { ICheckBoxFormField, ISubmissionField } from '../../util/types';

interface IProps {
  field: ICheckBoxFormField;
  register: UseFormRegister<FieldValues>;
  defaultValue?: ISubmissionField;
}

const CheckedBoxFormField = ({ field, register, defaultValue }: IProps) => {
  const defaultCheckedValue = defaultValue?.value;

  return <input {...register(field.id)} type="checkbox" defaultChecked={defaultCheckedValue || field.defaultChecked} />;
};

export default CheckedBoxFormField;
