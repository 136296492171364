import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openMessageModal } from '../redux/actions';
import { acceptIpConsent, acceptPrivacyPolicy, acceptTermsOfUsage } from '../util/api';
import { queryClient } from '../util/client';
import { ERROR_MESSAGES } from '../util/constants';
import { hasAcceptedAgreements } from '../util/helpers';
import { ReactQueryKey, IModalType } from '../util/types';
import { useCurrentUser } from './useCurrentUser';

export const useAcceptAgreements = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['agreements']);
  const { data: currentUser } = useCurrentUser();

  const [termsAgreed, setTermsAgreed] = useState(false);
  const [ipConsentAgreed, setIpConsentAgreed] = useState(false);
  const [policyAgreed, setPolicyAgreed] = useState(false);

  // Redirect to the Agreement Page once behind auth unless
  // the user accepted the agreements
  useEffect(() => {
    if (currentUser && !hasAcceptedAgreements(currentUser)) {
      history.replace('/agreements');
    }
  }, [currentUser]);

  const acceptAgreements = async () => {
    if (!termsAgreed || !ipConsentAgreed || !policyAgreed) {
      dispatch(
        openMessageModal({
          messageModalTitle: ERROR_MESSAGES.ERROR,
          messageModalContent: t('agreements:mustAgreeAll'),
          messageModalType: IModalType.Error,
          messageModalButtonText: 'I understand',
          messageModalButtonAction: null,
        }),
      );

      return;
    }

    try {
      await acceptTermsOfUsage();
      await acceptIpConsent();
      await acceptPrivacyPolicy();
    } catch (error) {
      console.error(error);
    } finally {
      queryClient.invalidateQueries(ReactQueryKey.CurrentUser);
      history.replace('/home');
    }
  };

  return {
    setTermsAgreed,
    setIpConsentAgreed,
    setPolicyAgreed,
    acceptAgreements,
  };
};
