import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import noImage from '../../assets/img/noImage.png';
import { openMessageModal } from '../../redux/actions';
import { getChallenges } from '../../util/api';
import { ERROR_MESSAGES } from '../../util/constants';
import { formatDate, humanizeChallengeDefaultSubmissionVisibility } from '../../util/helpers';
import { IModalType, ReactQueryKey } from '../../util/types';
import Grid from '../Grid';
import RichTextPreview from '../RichTextPreview';
import styles from './index.module.scss';

const AdminChallenges = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: challengesData, isLoading } = useQuery(ReactQueryKey.Challenges, getChallenges);

  if (isLoading) {
    return <p>loading...</p>;
  }

  const challenges = challengesData?.data;

  // TODO: Better handle server error
  if (!challenges) {
    dispatch(
      openMessageModal({
        messageModalTitle: ERROR_MESSAGES.ERROR,
        messageModalContent: 'No challenges found',
        messageModalType: IModalType.Error,
        messageModalButtonText: 'I understand',
        messageModalButtonAction: () => history.push('/admin'),
      }),
    );

    return null;
  }

  const columns = [
    <span>Challenges</span>,
    <span>Submissions</span>,
    <span>Published</span>,
    <>
      <span data-tip data-for="visibilityColumn">
        Visibility
      </span>
      <ReactTooltip id="visibilityColumn" effect="solid" place="bottom" offset={{ bottom: 10 }}>
        <div className={styles.tooltipContent}>
          The default visibility for a challenge's submissions. Submissions to a challenge can be visible to everyone or
          only to people with the permission. there is an option to make a submission visible to everyone despite the
          challenge has a Permission Only default submission visibility.
        </div>
      </ReactTooltip>
    </>,
    <span>Duration</span>,
  ];

  const rows = challenges.map((challenge) => [
    <span className={styles.challengeColumn}>
      <img className={styles.image} src={challenge.image?.url || noImage} alt="challenge image" />
      <div className={styles.titleAndContent}>
        <Link to={`/admin/challenges/${challenge.id}`}>
          <div className={styles.title}>{challenge.title}</div>
          <RichTextPreview className={styles.content} value={challenge.content} />
        </Link>
      </div>
    </span>,
    <span>{challenge.publishedSubmissions}</span>,
    <span>{challenge.publishedAt ? formatDate(challenge.publishedAt) : '-'}</span>,
    <div>{humanizeChallengeDefaultSubmissionVisibility(challenge.defaultSubmissionVisibility)}</div>,
    <span>
      {challenge.startsAt ? formatDate(challenge.startsAt) : '-'} ~{' '}
      {challenge.endsAt ? formatDate(challenge.endsAt) : '-'}
    </span>,
  ]);

  return <Grid columns={columns} rows={rows} />;
};

export default AdminChallenges;
