import { ReactComponent as ChartIcon } from '../../assets/icons/chart.svg';
import { ISubmission } from '../../util/types';
import RichTextPreview from '../RichTextPreview';
import VideoBanner from '../VideoBanner';
import styles from './index.module.scss';

interface IProps {
  submission: ISubmission;
}

const PitchFeedback = ({ submission }: IProps) => {
  const idea = submission.idea;
  const attachments = submission.attachments;

  const pitchVideo = attachments.find((attachment) => attachment.id === idea.pitchVideoAttachmentId);
  const pitchDeck = attachments.find((attachment) => attachment.id === idea.pitchDeckAttachmentId);
  const hasPitch = Boolean(idea.pitchIOComment || pitchVideo || pitchDeck);

  return (
    <div className={styles.wrapper}>
      {idea.prepitchFeedback && (
        <div>
          <h2 className={styles.sectionHeader}>Workshop feedbacks and suggestions</h2>
          <RichTextPreview className={styles.markdownContent} value={idea.prepitchFeedback} />
        </div>
      )}

      {hasPitch && (
        <div>
          <h2 className={styles.sectionHeader}>Pitch</h2>
          {idea.pitchIOComment && (
            <div>
              <h3 className={styles.subSectionHeader}>Idea Owner's Comment</h3>
              <RichTextPreview className={styles.markdownContent} value={idea.pitchIOComment} />
            </div>
          )}

          {(pitchVideo || pitchDeck) && (
            <div className={styles.pitchAttachments}>
              {pitchVideo && (
                <div>
                  <div className={styles.attachmentTitle}>{pitchVideo.title}</div>
                  <div className={styles.videoWrapper}>
                    <VideoBanner embedUrl={pitchVideo.blob.url} title={pitchVideo.description} />
                  </div>
                </div>
              )}
              {pitchDeck && (
                <div>
                  <ChartIcon className={styles.chartIcon} />
                  <a href={pitchDeck.blob.url} target="_">
                    <span className={styles.pitchDeckText}>{pitchDeck.title}</span>
                  </a>
                </div>
              )}
            </div>
          )}

          {idea.pitchFeedback && (
            <div>
              <h3 className={styles.subSectionHeader}>Feedback</h3>
              <RichTextPreview className={styles.markdownContent} value={idea.pitchFeedback} />
            </div>
          )}
        </div>
      )}
      {idea.ioTestimonial && (
        <div>
          <h2 className={styles.sectionHeader}>{(idea.author.givenName ||= 'Idea Owner')}'s Experience</h2>{' '}
          <RichTextPreview className={styles.markdownContent} value={idea.ioTestimonial} />
        </div>
      )}
    </div>
  );
};

export default PitchFeedback;
