import { FieldValues, Controller, Control } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import RichTextInput from '../RichTextInput';
import styles from './index.module.scss';

interface IProps {
  label?: string;
  control: Control<FieldValues, object>;
  name: string;
  errors: { [x: string]: any };
  // See: https://react-hook-form.com/api/usecontroller/controller for Validation rules
  rules?: object;
  dataTestId?: string;
  height?: number;
  isSanitized?: boolean;
}

const HookFormRichTextInput = ({ name, label, control, rules, dataTestId, errors, height, isSanitized }: IProps) => {
  return (
    <div>
      {label && <label className={styles.label}>{label}</label>}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <RichTextInput
            onChange={onChange}
            value={value}
            dataTestId={dataTestId}
            height={height}
            isSanitized={isSanitized}
          />
        )}
      />
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => <span style={{ color: 'red' }}>*{message}</span>}
      />
    </div>
  );
};

export default HookFormRichTextInput;
