import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import useOnClickOutside from 'use-onclickoutside';
import { ReactComponent as ClipboardIcon } from '../../assets/icons/clipboard.svg';
import { IEvaluation } from '../../util/types';
import styles from './index.module.scss';

interface IProps {
  evaluations: IEvaluation[];
}

const EvaluationSelect = ({ evaluations }: IProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const selectRef = useRef(null);
  useOnClickOutside(selectRef, () => setIsVisible(false));
  const history = useHistory();

  const isEmptyEvaluations = evaluations.length === 0;

  return (
    <div className={styles.container}>
      <ClipboardIcon
        className={styles.clipboardIcon}
        onClick={() => setIsVisible(true)}
        data-tip
        data-for="othersEvaluationSelect"
        data-testid="others-evaluation-select"
      />
      <ReactTooltip id="othersEvaluationSelect" effect="solid">
        <span>See Others Evaluation</span>
      </ReactTooltip>

      {isVisible && (
        <div ref={selectRef} className={styles.optionsWrapper}>
          <div className={styles.optionsContainer}>
            <span className={styles.indicator}>▾</span>
            {isEmptyEvaluations ? (
              <div>No evaluations yet</div>
            ) : (
              <>
                {evaluations.map((evaluation) => (
                  <div
                    className={styles.item}
                    key={evaluation.id}
                    onClick={() => history.push(`/admin/ideas/${evaluation.submissionId}/evaluations/${evaluation.id}`)}
                    data-testid="evaluator"
                  >
                    {evaluation.author.givenName} {evaluation.author.familyName}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EvaluationSelect;
