import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { createChallenge } from '../../util/api';
import { IChallengeFormData, ReactQueryKey } from '../../util/types';
import { queryClient } from '../../util/client';
import AdminPageTitle from '../../components/AdminPageTitle';
import { Button } from '../../components/Button';
import ChallengeCreateForm from '../../components/ChallengeCreateForm';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import { useError } from '../../hooks/useError';

const AdminChallengeCreatePage = () => {
  const history = useHistory();
  const { handleError } = useError();
  // TODO: have the revalidate option as onSubmit until we have a robust
  // onBlur validation for start and end date.
  const createChallengeForm = useForm({ reValidateMode: 'onSubmit' });

  const { mutateAsync } = useMutation(createChallenge);

  const toCreateChallenge = async (data: IChallengeFormData) => {
    // TODO: add a useServerErrorHandling hook to handle server request error in a later PR
    const { title, content, restrictSubmissions, defaultSubmissionVisibility, startsAt, endsAt, image } = data;
    await mutateAsync({
      title,
      content,
      restrictSubmissions,
      defaultSubmissionVisibility: defaultSubmissionVisibility.value,
      startsAt: startsAt ? new Date(startsAt) : undefined,
      endsAt: endsAt ? new Date(endsAt) : undefined,
      image: image ? image : undefined,
    })
      .then(() => {
        queryClient.invalidateQueries(ReactQueryKey.Challenges);
        history.push('/admin/challenges');
      })
      .catch(handleError);
  };

  return (
    <div className={styles.wrapper}>
      <AdminPageTitle text="Challenges Management" />
      <div className={styles.actions}>
        <Link to="/admin/challenges" className={styles.backButton} data-testid="back-icon">
          <BackButton />
        </Link>
        <Button
          className={styles.createChallengeButton}
          variant="black"
          onClick={createChallengeForm.handleSubmit(toCreateChallenge)}
        >
          Create Challenge
        </Button>
      </div>
      <div className={styles.container}>
        <ChallengeCreateForm form={createChallengeForm} />
      </div>
    </div>
  );
};

export default AdminChallengeCreatePage;
