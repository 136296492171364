import { useActsOnBehalfOf } from '../../hooks/useActsOnBehalfOf';
import styles from './index.module.scss';

// Automatically dimiss acts on behalf after 30 minutes.
const ActsOnBeHalfOfChecker = () => {
  const { values, dismissActsOnBehalfOf } = useActsOnBehalfOf();

  if (!values) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <span className={styles.text}>
          <strong>WARNING:</strong> You are acting on behalf of: <strong>{values.email}</strong>
        </span>
        <button className={styles.dismissButton} onClick={dismissActsOnBehalfOf}>
          Dismiss
        </button>
      </div>
    </div>
  );
};

export default ActsOnBeHalfOfChecker;
