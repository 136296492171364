import { Link } from 'react-router-dom';
import { IUserGroup } from '../../util/types';
import styles from './index.module.scss';

interface IProps {
  userGroup: IUserGroup;
}

const ChallengeUserGroup = ({ userGroup }: IProps) => (
  <div className={styles.wrapper}>
    <Link className={styles.link} to={`/admin/user-groups/${userGroup.id}`} target="_blank">
      {userGroup.name}
    </Link>
  </div>
);

export default ChallengeUserGroup;
