import { UseFormRegister, FieldValues } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import styles from './index.module.scss';

interface IProps extends React.ComponentPropsWithoutRef<'input'> {
  label: string;
  register: UseFormRegister<FieldValues>;
  name: string;
  errors: { [x: string]: any };
  // See: https://react-hook-form.com/api/usecontroller/controller for Validation rules
  rules?: object;
  dataTestId?: string;
}

const HookFormTextInput = ({ name, label, register, rules, dataTestId, errors, ...props }: IProps) => {
  const { ...rest } = props;

  return (
    <div>
      <label className={styles.label}>{label}</label>
      <input {...register(name, rules)} className={styles.input} data-testid={dataTestId} {...rest} />
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => <span style={{ color: 'red' }}>*{message}</span>}
      />
    </div>
  );
};

export default HookFormTextInput;
