import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FieldValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { openMessageModal } from '../../redux/actions';
import { isOverFileUploadLimit } from '../../util/helpers';
import { ERROR_MESSAGES } from '../../util/constants';
import { IBlob, IBlobOrigin, IBlobType, IModalType } from '../../util/types';
import { useFilestack } from '../../hooks/useFilestack';
import { Button } from '../Button';
import styles from './index.module.scss';

interface IProps {
  label: string;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  name: string;
  errors: { [x: string]: any };
  // See: https://react-hook-form.com/api/usecontroller/controller for Validation rules
  rules?: object;
  dataTestId?: string;
}

const HookFormBlobInput = ({ register, name, setValue, label, errors, rules, watch, dataTestId }: IProps) => {
  const dispatch = useDispatch();
  const { openFilestack } = useFilestack();

  useEffect(() => {
    register(name, rules);
  }, [register]);

  const onFileSelected = (response: any, blobType: IBlobType) => {
    if (isOverFileUploadLimit(response.size, blobType)) {
      dispatch(
        openMessageModal({
          messageModalTitle: ERROR_MESSAGES.ERROR,
          messageModalContent: 'Image size cannot exceed 100MB',
          messageModalType: IModalType.Error,
          messageModalButtonText: 'I understand',
          messageModalButtonAction: null,
        }),
      );
    }
  };

  const handleImageUpload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    openFilestack({
      originType: IBlobOrigin.Challenge,
      originId: 'new-entity',
      onFileUploadFinished: (response: IBlob) => setValue(name, response, { shouldValidate: true }),
      onFileSelected: (response: IBlob) => onFileSelected(response, IBlobType.Image),
    });
  };

  return (
    <div>
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
      {watch(name)?.url && (
        <div className={styles.imagePreviewContainer}>
          <img className={styles.imagePreview} src={watch(name).url} role="image" data-testid={dataTestId} />
          <CloseIcon onClick={() => setValue(name, null)} className={styles.closeIcon} />
        </div>
      )}
      <Button variant="black" className={styles.imageInput} onClick={handleImageUpload}>
        Upload
      </Button>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <span style={{ color: 'red' }}>*{message}</span>}
      />
    </div>
  );
};

export default HookFormBlobInput;
