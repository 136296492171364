import { ReactComponent as TiltedRocketIcon } from '../../assets/icons/tiltedRocket.svg';
import { ReactComponent as RocketIcon } from '../../assets/icons/rocket.svg';
import styles from './index.module.scss';
import classnames from 'classnames';

interface IProps {
  isVisibleToNonAuthors: boolean;
}

const IdeaVisibilityStatus = ({ isVisibleToNonAuthors }: IProps) => {
  return isVisibleToNonAuthors ? (
    <div className={styles.wrapper}>
      <TiltedRocketIcon className={styles.icon} />
      <div>Published</div>
    </div>
  ) : (
    <div className={classnames(styles.wrapper, styles.grayOut)}>
      <RocketIcon className={styles.icon} />
      <div>Unpublished</div>
    </div>
  );
};

export default IdeaVisibilityStatus;
