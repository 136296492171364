import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { openMessageModal } from '../redux/actions';
import { AUTH_DOMAIN_WHITELIST, ERROR_MESSAGES } from '../util/constants';
import { deleteSession } from '../util/api';
import { queryClient } from '../util/client';
import { ILocationState, IModalType } from '../util/types';
import useAutoPopulateEmail from './useAutoPopulateEmail';
import { ACTS_ON_BEHALF_OF_KEY } from './useActsOnBehalfOf';

export const useAuthenticate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state: locationState } = useLocation<ILocationState>();
  const [email, setEmail] = useState('');
  const [_, setStoredEmail] = useAutoPopulateEmail();

  useEffect(() => {
    const { smapAuth, authError } = queryString.parse(location.search);

    if (!smapAuth && !authError) {
      return;
    }

    if (smapAuth === 'true') {
      redirectToTargetUrl();
    } else {
      handleAuthError(authError);
    }
  }, []);

  const redirectToTargetUrl = () => {
    const targetUrl = queryString.exclude(window.location.href, ['smapAuth']);
    window.location.href = targetUrl;
  };

  const handleAuthError = (authError: string | string[] | null) => {
    if (authError === ERROR_MESSAGES.USER_NEEDS_APPROVAL) {
      history.replace('/signup-approval');
    } else {
      dispatch(
        openMessageModal({
          messageModalTitle: ERROR_MESSAGES.ERROR,
          messageModalContent: `Something went wrong error code: ${authError}`,
          messageModalType: IModalType.Error,
          messageModalButtonText: 'I understand',
          messageModalButtonAction: null,
        }),
      );
    }
  };

  const isValidEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const authenticate = (domain: string) => {
    const { from } = locationState || { from: { pathname: '/home' } };

    const { redirectUrl, learnWorldsInitiated } = queryString.parse(location.search);

    if (redirectUrl && learnWorldsInitiated && learnWorldsInitiated === 'true') {
      location.href = `${process.env.REACT_APP_API_HOSTNAME}/api/v0.1/authenticate?domain=${domain}&redirectUrl=${redirectUrl}&learnWorldsInitiated=true`;
    } else {
      location.href = `${process.env.REACT_APP_API_HOSTNAME}/api/v0.1/authenticate?domain=${domain}&redirectUrl=${location.origin}${from.pathname}`;
    }
  };

  const login = () => {
    if (!isValidEmail(email)) {
      dispatch(
        openMessageModal({
          messageModalTitle: ERROR_MESSAGES.ERROR,
          messageModalContent: 'Please enter a valid email',
          messageModalType: IModalType.Error,
          messageModalButtonText: 'I understand',
          messageModalButtonAction: null,
        }),
      );

      return;
    }
    const domain = email.split('@')[1];

    if (AUTH_DOMAIN_WHITELIST.includes(domain)) {
      authenticate(domain);
    } else {
      history.push('/login-approval');
    }
  };

  const logOut = async () => {
    setStoredEmail('');
    await deleteSession();
    localStorage.removeItem(ACTS_ON_BEHALF_OF_KEY);
    queryClient.clear();
    history.push('/');
  };

  return { email, setEmail, login, logOut };
};
