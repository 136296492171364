import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getSubmission } from '../../util/api';
import { IUrlParams, ReactQueryKey } from '../../util/types';
import EvaluationEditForms from '../../components/EvaluationEditForms';
import AdminPageTitle from '../../components/AdminPageTitle';
import BackButton from '../../components/BackButton';
import styles from './index.module.scss';

const AdminIdeaEvaluationEditPage = () => {
  const { ideaId, evaluationId } = useParams<IUrlParams>();
  const { data: submission, isLoading } = useQuery([ReactQueryKey.Submission, { submissionId: ideaId }], () =>
    getSubmission({ ideaId }),
  );

  return (
    <div>
      <AdminPageTitle text="Submissions Management" />
      {isLoading && <p>loading...</p>}
      {submission && (
        <div>
          <div className={styles.actions}>
            <Link to={`/admin/ideas/${ideaId}/evaluations/${evaluationId}`} data-testid="back-icon">
              <BackButton />
            </Link>
          </div>
          <h1 className={styles.headerContainer}>
            <span className={styles.header}>Idea evaluation</span> /{' '}
            <span className={styles.ideaName}>{submission.idea.name}</span>
          </h1>

          <EvaluationEditForms challengeId={submission.idea.challengeId} evaluationId={evaluationId} />
        </div>
      )}
    </div>
  );
};

export default AdminIdeaEvaluationEditPage;
