import { useState } from 'react';
import { IUserPermission, IPermissionInfo, IUser, ReactQueryKey } from '../../util/types';
import classnames from 'classnames';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as BanIcon } from '../../assets/icons/ban.svg';

import styles from './index.module.scss';
import { useQuery } from 'react-query';
import { getPermissions } from '../../util/api';
import Switch from '../Switch';

interface IProps {
  user: IUser;
  reset: boolean;
  canList: boolean;
  canChange: boolean;
  onPermissionsChange: (permissions: IUserPermission[], isChanged: boolean) => void;
}

const AdminUserInfoPermissions = ({ user, reset, canList, canChange, onPermissionsChange }: IProps) => {
  const [fetchData, setToFetch] = useState(true);
  const [inputStates, setInputStates] = useState([false]);
  const [isEditing, setIsEditing] = useState(false);

  const getNewPermissions = (states: boolean[], allPermissions: IPermissionInfo[]) => {
    return allPermissions.map((permissionInfo) => permissionInfo.name).filter((_, i) => states[i]);
  };

  const isOwned = (permissionInfo: IPermissionInfo) => user.permissions.includes(permissionInfo.name);

  const handleChange = (checked: boolean, index: number, allPermissionsData: IPermissionInfo[]) => {
    let isOriginal = true;
    const newInputStates = inputStates.map((inputState, i) => {
      const newState = i === index ? checked : inputState;
      if (newState !== isOwned(allPermissionsData[i])) {
        isOriginal = false;
      }
      return newState;
    });
    setInputStates(newInputStates);
    onPermissionsChange(getNewPermissions(newInputStates, allPermissionsData), !isOriginal);
  };

  const onSuccess = (data: IPermissionInfo[]) => {
    setToFetch(false);
    setInputStates(data.map((permissionInfo) => isOwned(permissionInfo)));
    onPermissionsChange(getNewPermissions(inputStates, data), false);
  };

  const { data, isLoading } = useQuery(ReactQueryKey.Permissions, getPermissions, {
    onSuccess,
    enabled: fetchData,
  });

  const onToggleEdit = () => {
    setToFetch(true);
    setIsEditing(!isEditing);
  };

  if (reset && !fetchData) {
    setToFetch(true);
  }

  if (isLoading) {
    return <div className={styles.title}>Permissions loading...</div>;
  }
  if (!data) {
    return <div className={styles.title}>No permissions data found!</div>;
  }

  return !data || !canList ? null : (
    <>
      <div className={styles.header}>
        <div className={styles.title}>{'Permissions:'}</div>
        {canChange ? (
          <button data-testid="edit-button" onClick={onToggleEdit}>
            {isEditing ? 'Done' : 'Edit'}
          </button>
        ) : null}
        {isEditing ? (
          <div>Click checkboxes and press save in the popup, but I hope you know what you're doing!</div>
        ) : null}
      </div>
      <div className={styles.permissions} data-testid="permissions">
        {data.map((permission, i) => (
          <div className={classnames(styles.permission, isOwned(permission) && styles.owned)} key={permission.name}>
            <div className={styles.permissionMain}>
              <div>
                {isEditing ? (
                  <Switch
                    name={`${permission.name}-switch`}
                    onChange={(checked) => {
                      handleChange(checked, i, data);
                    }}
                    checked={inputStates[i]}
                    dataTestId="permission-switch"
                  />
                ) : isOwned(permission) ? null : (
                  <BanIcon className={styles.icon} />
                )}
              </div>
              <div className={styles.permissionName} data-testid="permission-name">
                {permission.name}
              </div>
            </div>
            <div className={styles.permissionDesc}>{permission.description}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AdminUserInfoPermissions;
