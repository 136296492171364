import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { useError } from '../../hooks/useError';
import { getEvaluation, updateEvaluation } from '../../util/api';
import { queryClient } from '../../util/client';
import { transformSubmissionFormData } from '../../util/helpers';
import {
  ICheckBoxFormField,
  IForm,
  IFormFieldType,
  IRangeFormField,
  ITextAreaFormField,
  IUrlParams,
  ReactQueryKey,
  Uuid,
} from '../../util/types';
import { Button } from '../Button';
import CheckedBoxFormField from '../CheckedBoxFormField';
import EvaluationFormHeader from '../EvaluationFormHeader';
import RangeFormFieldInput from '../RangeFormFieldInput';
import TextAreaFormFieldInput from '../TextAreaFormFieldInput';
import styles from './index.module.scss';

interface IProps {
  form: IForm;
  evaluationId: Uuid;
}

interface IEvaluationFormData {
  [key: Uuid]: string | number | boolean;
}

const EvaluationEditForm = ({ form, evaluationId }: IProps) => {
  const history = useHistory();
  const { handleError } = useError();
  const { ideaId } = useParams<IUrlParams>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { data: evaluation, isLoading } = useQuery(
    [ReactQueryKey.Evaluation, { evaluationId }],
    () => getEvaluation(evaluationId),
    // This is to prevent auto-refresh in progress form fields on window and on internet reconnect.
    { refetchOnWindowFocus: false, refetchOnReconnect: false },
  );

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const onConfirm = async (data: IEvaluationFormData) => {
    // TODO: add a useServerErrorHandling hook to handle server request error in a later PR
    await updateEvaluation(evaluationId, { fields: transformSubmissionFormData(data, form.fields) }).catch(handleError);
    queryClient.invalidateQueries([ReactQueryKey.Evaluation, { evaluationId }]);

    history.push(`/admin/ideas/${ideaId}`);
  };

  const defaultValue = (formFieldId: Uuid) =>
    evaluation?.fields.find((evaluationField) => evaluationField.fieldId === formFieldId);

  return (
    <div className={styles.wrapper}>
      <form className={styles.form}>
        <EvaluationFormHeader
          title={form.title}
          shortDescription={form.shortDescription}
          longDescription={form.longDescription}
        />
        <div className={styles.formFields}>
          {form.fields.map((field) => (
            <div key={field.id}>
              <label className={styles.label}>
                {field.label}
                {field.isRequired && <span className={styles.isRequiredIndicator}> (*)</span>}
              </label>
              {field.description && <div className={styles.description}>{field.description}</div>}
              {
                {
                  [IFormFieldType.TextArea]: (
                    <TextAreaFormFieldInput
                      field={field as ITextAreaFormField}
                      register={register}
                      defaultValue={defaultValue(field.id)}
                    />
                  ),
                  [IFormFieldType.Range]: (
                    <RangeFormFieldInput
                      field={field as IRangeFormField}
                      register={register}
                      defaultValue={defaultValue(field.id)}
                    />
                  ),
                  [IFormFieldType.CheckBox]: (
                    <CheckedBoxFormField
                      field={field as ICheckBoxFormField}
                      register={register}
                      defaultValue={defaultValue(field.id)}
                    />
                  ),
                  // TODO: Add the blob field in a later PR
                  [IFormFieldType.Blob]: null,
                }[field.type]
              }
              <ErrorMessage
                errors={errors}
                name={field.id}
                render={({ message }) => <span style={{ color: 'red' }}>*{message}</span>}
              />
            </div>
          ))}
        </div>
      </form>

      <div className={styles.actions}>
        <Button variant="outline" className={styles.button} onClick={() => history.push(`/admin/ideas/${ideaId}`)}>
          Cancel
        </Button>
        <Button variant="black" className={styles.button} onClick={handleSubmit(onConfirm)}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default EvaluationEditForm;
