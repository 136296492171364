import { FieldValues, UseFormReturn } from 'react-hook-form';
import HookFormTextAreaInput from '../HookFormTextAreaInput';
import HookFormTextInput from '../HookFormTextInput';
import styles from './index.module.scss';

interface IProps {
  form: UseFormReturn<FieldValues, object>;
}

const ChallengeCreateForm = ({ form }: IProps) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <div className={styles.container}>
      <form className={styles.form} data-testid="create-user-group-form">
        <HookFormTextInput
          name="name"
          label="Name"
          rules={{ required: 'name is required' }}
          register={register}
          errors={errors}
          dataTestId="name-input"
        />
        <HookFormTextInput
          name="description"
          label="Description"
          register={register}
          errors={errors}
          dataTestId="description-input"
        />
        <HookFormTextInput
          name="code"
          label="Code"
          rules={{ required: 'code is required' }}
          register={register}
          errors={errors}
          dataTestId="code-input"
        />
        <HookFormTextAreaInput
          name="userEmails"
          label="User Emails"
          rows={3}
          register={register}
          errors={errors}
          dataTestId="user-emails-input"
        />
      </form>
    </div>
  );
};

export default ChallengeCreateForm;
