import { GET_CHALLENGE_LIST_SUCCESS } from '../actionConstants';
import { IBaseAction, IChallengeState } from '../../util/types';

export const initialState = {
  challengeList: [],
};

export default (state: IChallengeState = initialState, action: IBaseAction) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CHALLENGE_LIST_SUCCESS: {
      const { challengeList } = payload;
      return {
        ...state,
        challengeList,
      };
    }
    default: {
      return state;
    }
  }
};
