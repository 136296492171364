import React from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';

interface IProps {
  avatarUrl?: string;
  firstName?: string;
  small?: boolean;
  large?: boolean;
}

const ProfileAvatar = ({ avatarUrl, firstName, small = false, large = false }: IProps) => {
  if (avatarUrl && avatarUrl !== '') {
    return (
      <img
        className={classnames(styles.avatar, small && styles.small, large && styles.large)}
        src={avatarUrl}
        data-cy="logged-in-avatar-icon"
      />
    );
  } else {
    return (
      <div className={classnames(styles.placeholder, small && styles.small)} data-cy="logged-in-avatar-icon">
        {firstName?.charAt(0)}
      </div>
    );
  }
};

export default ProfileAvatar;
