import React, { memo } from 'react';
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';

const COOKIE_BANNER_MESSAGE =
  'We are using 🍪 By clicking "Accept All Cookies", you agree to the storing of cookies on your device to enhance site navigation, tailor the content and experience, analyze site usage to monitor and improve the performance.';
const ACCEPT_ALL_BUTTON_TEXT = 'Accept All Cookies';
const POLICY_LINK_TEXT = 'Set Cookie Preference';
const POLICY_LINK = '/cookie-preferences';

interface IProps {
  onAccept: () => void;
  onAcceptFunctional: () => void;
  onDeclineFunctional: () => void;
  onAcceptPerformance: () => void;
  onDeclinePerformance: () => void;
}

const AppBootstrap = ({
  onAccept,
  onAcceptFunctional,
  onDeclineFunctional,
  onAcceptPerformance,
  onDeclinePerformance,
}: IProps) => {
  return (
    <CookieBanner
      message={COOKIE_BANNER_MESSAGE}
      showMarketingOption={false}
      showAcceptSelectionButton={false}
      showPreferencesOption={false}
      showStatisticsOption={false}
      acceptAllButtonText={ACCEPT_ALL_BUTTON_TEXT}
      privacyPolicyLinkText={POLICY_LINK_TEXT}
      policyLink={POLICY_LINK}
      onAccept={onAccept}
      onAcceptPreferences={onAcceptFunctional}
      onDeclinePreferences={onDeclineFunctional}
      onAcceptStatistics={onAcceptPerformance}
      onDeclineStatistics={onDeclinePerformance}
    />
  );
};

export default memo(AppBootstrap);
