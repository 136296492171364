import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Uuid } from '../../util/types';

interface IProps {
  ideaId: Uuid;
  evaluationId?: Uuid;
}

const EvaluationButton = ({ ideaId, evaluationId }: IProps) => {
  if (evaluationId) {
    return (
      <Link to={`/admin/ideas/${ideaId}/evaluations/${evaluationId}`}>
        <Button variant="black">View Evaluation</Button>
      </Link>
    );
  } else {
    return (
      <Link to={`/admin/ideas/${ideaId}/evaluations/create`} target="_blank">
        <Button variant="black">Evaluate</Button>
      </Link>
    );
  }
};

export default EvaluationButton;
