import React, { ComponentPropsWithoutRef, memo, ReactNode } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';

// TODO: Deprecate Primary and Secondary Button. Replace with
// the below "Button" component
interface IProps extends ComponentPropsWithoutRef<'button'> {
  specialProp?: string;
}

export const PrimaryButton = memo((props: IProps) => {
  const { className, specialProp, ...rest } = props;

  return <button className={classnames(styles.default, styles.primaryButton, className)} {...rest} />;
});

export const SecondaryButton = memo((props: IProps) => {
  const { className, specialProp, ...rest } = props;

  return <button className={classnames(styles.default, styles.secondaryButton, className)} {...rest} />;
});

// Custom Button component that takes all the props of <button />
// https://react-typescript-cheatsheet.netlify.app/docs/advanced/patterns_by_usecase/#wrappingmirroring
interface Props extends React.ComponentPropsWithoutRef<'button'> {
  specialProp?: string;
  variant?: 'blue' | 'purple' | 'black' | 'outline' | 'red';
}

export const Button = ({ variant = 'blue', ...props }: Props) => {
  const { className, specialProp, style, ...rest } = props;

  const customStyle = {
    red: { backgroundColor: '#EF4444' },
    blue: { backgroundColor: '#2E7FEC' },
    purple: { backgroundColor: '#4F46E5' },
    black: { backgroundColor: '#000000' },
    outline: { backgroundColor: '#ffffff', color: '#000000', border: '1px solid #000000' },
  }[variant];

  return <button className={classnames([styles.button, className])} style={{ ...customStyle, ...style }} {...rest} />;
};
