import React, { memo, ReactNode } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
interface IProps {
  children: ReactNode;
  className?: string;
}

const PageWrapper = memo(({ children, className }: IProps) => {
  return (
    <div className={classnames(styles.pageWrapper, className)}>
      <div className={styles.container}>{children}</div>
    </div>
  );
});

export default memo(PageWrapper);
