import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as ClipboardIcon } from '../../assets/icons/clipboard.svg';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { IEvaluation } from '../../util/types';
import styles from './index.module.scss';

interface IProps {
  evaluations?: IEvaluation[];
}

const EvaluationCountBadge = ({ evaluations }: IProps) => {
  const { data: currentUser } = useCurrentUser();
  const evaluationCount = evaluations?.length || 0;

  const hasCurrentUserEvaluated = Boolean(evaluations?.some((evaluation) => evaluation.author.id === currentUser?.id));
  const showActiveBadge = evaluationCount === 0 || !hasCurrentUserEvaluated;

  return (
    <div className={styles.wrapper} data-tip data-for="evaluationIcon">
      <ClipboardIcon className={styles.icon} />
      {evaluations && (
        <div data-testid="evaluation-count" className={classnames(styles.badge, showActiveBadge && styles.active)}>
          {evaluationCount}
        </div>
      )}
      <ReactTooltip id="evaluationIcon" effect="solid">
        <span>Evaluation count and status for this idea</span>
      </ReactTooltip>
    </div>
  );
};

export default EvaluationCountBadge;
