import { useState } from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as GearIcon } from '../../assets/icons/gear.svg';
import { deleteSubmission } from '../../util/api';
import { queryClient } from '../../util/client';
import { IIdea, ReactQueryKey } from '../../util/types';
import { useError } from '../../hooks/useError';
import AlertModal from '../AlertModal';
import IdeaOptionsTooltip from '../IdeaOptionsTooltip';
import styles from './index.module.scss';

interface IProps {
  idea: IIdea;
}

const IdeaSetting = ({ idea }: IProps) => {
  const history = useHistory();
  const { handleError } = useError();

  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const editIdea = () => {
    history.push(`/challenges/${idea.challengeId}/submission/${idea.id}`);
  };

  const handleDeleteIdea = async () => {
    await deleteSubmission(idea.id)
      .then(() => {
        queryClient.invalidateQueries(ReactQueryKey.Submissions);
        queryClient.invalidateQueries(ReactQueryKey.Submission);
        history.goBack();
      })
      .catch(handleError);
  };

  const showDeleteModal = () => {
    setShowModal(true);
    setShowOptionsMenu(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setShowOptionsMenu(false);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <GearIcon
          data-cy="idea-setting-icon"
          onClick={showOptionsMenu ? () => setShowOptionsMenu(false) : () => setShowOptionsMenu(true)}
        />
        <IdeaOptionsTooltip
          show={showOptionsMenu}
          dismiss={() => setShowOptionsMenu(false)}
          editIdea={editIdea}
          showDeleteModal={showDeleteModal}
        />
      </div>

      <AlertModal
        title="Delete your idea"
        body="Are you sure you want to delete this idea?"
        isVisible={showModal}
        cancel={{ text: 'Cancel', onClick: handleClose }}
        confirm={{ text: 'Delete', onClick: handleDeleteIdea }}
      />
    </>
  );
};

export default IdeaSetting;
