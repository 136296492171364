import axios, { AxiosError } from 'axios';
import {
  IChallenge,
  IForm,
  ISubmission,
  IIdea,
  IUploadRequest,
  IMe,
  IUserSetting,
  IIdeas,
  IChallenges,
  IIdeaComments,
  IIdeaComment,
  IMemo,
  IMemoList,
  ICreateSubmissionData,
  IUpdateSubmissionResults,
  IUploadResponse,
  ICreateTextAreaFormFieldData,
  ICreateRangeFormFieldData,
  ITextAreaFormField,
  IRangeFormField,
  IChallengeData,
  ICreateFormData,
  IUpdateSubmissionData,
  Uuid,
  IUserProfile,
  ISubmissionsFilters,
  IHasSeenRequestData,
  ICreateEvaluationData,
  IEvaluation,
  IEvaluationDetail,
  IEvaluationUpdateResults,
  IUpdateEvaluationData,
  IIdeaVisibility,
  IIdeaPhase,
  IPermissionInfo,
  IUser,
  IUserShorts,
  IPermissionUsersData,
  IUserIdentification,
  ICreateBlobFormFieldData,
  IBlobFormField,
  ICheckBoxFormField,
  ICreateCheckboxFormFieldData,
  IGetUserShortsFiltersRequest,
  IUserGroups,
  IUserGroup,
  IUserGroupFormData,
  IUpdateFormData,
  IUpdateSubmissionPitchData,
  IMemoCreateData,
  ITagList,
  IUserGroupResponse,
} from './types';
import {
  ERROR_MESSAGES,
  MAINTENANCE_STATUS_CODE,
  BAD_SESSION_STATUS_CODE,
  EDITING_API_METHODS,
  EDITING_ROUTE_PATHS,
} from './constants';

import { mutateDateStringToDate } from './helpers';
import { ACTS_ON_BEHALF_OF_KEY } from '../hooks/useActsOnBehalfOf';

const isUserEditing = (method: string, currentRoute: string) => {
  if (!EDITING_API_METHODS.includes(method)) {
    return false;
  }

  for (let i = 0; i < EDITING_ROUTE_PATHS.length; i++) {
    if (currentRoute.match(EDITING_ROUTE_PATHS[i])) {
      return true;
    }
  }

  return false;
};

// globally set withCredentials, which sends the smap session cookie with every request
axios.interceptors.request.use((config) => {
  config.baseURL = `${process.env.REACT_APP_API_HOSTNAME}/api/`;
  config.withCredentials = true;
  const actsOnBehalfOfValues = localStorage.getItem(ACTS_ON_BEHALF_OF_KEY);

  if (actsOnBehalfOfValues) {
    const values = JSON.parse(actsOnBehalfOfValues);

    config.headers = {
      ...config.headers,
      'X-Smap-On-Behalf-Of': values.email,
    };
  }

  if (process.env.REACT_APP_X_SMAP_USER) {
    config.headers = {
      ...config.headers,
      'X-Smap-User': process.env.REACT_APP_X_SMAP_USER,
    };
  }
  return config;
});

axios.interceptors.response.use(
  (res) => {
    // Mutate all Date String field from API response
    mutateDateStringToDate(res.data);
    return res.data;
  },
  (error: AxiosError) => {
    if (error.response) {
      const errObj = error.response;

      // Maintenance
      if (errObj.status === MAINTENANCE_STATUS_CODE) {
        if (errObj.config && process.env.REACT_APP_API_HOSTNAME) {
          const currentRoute = location.pathname;

          if (errObj.config.method && isUserEditing(errObj.config.method, currentRoute)) {
            // if user is editing actively on page: display error, no redirect
            throw {
              error: {
                status: errObj.status,
                data: [
                  {
                    errorCode: ERROR_MESSAGES.MAINTENANCE_WARN_MESSAGE,
                  },
                ],
              },
            };
          }
        }

        if (location.pathname !== '/maintenance') {
          location.href = '/maintenance';
        }
        return;
      }

      // Expired or no session
      if (errObj.status === BAD_SESSION_STATUS_CODE) {
        return;
      }

      // drive which data to be thrown, then caught later (and filtered to show custom error message)
      throw {
        error: {
          status: errObj.status,
          data: errObj.data || [],
        },
      };
    }
  },
);

export const deleteSession = () => axios.delete('v0.1/session');

export const getUser = (): Promise<IMe> => axios.get('v0.1/me');

export const getUserProfile = (userId: Uuid): Promise<IUserProfile> => axios.get(`v0.1/users/${userId}`);

export const getUserSettings = (): Promise<IUserSetting[]> => axios.get('v0.1/settings');

export const editUserSettings = ({ name, value }: { name: string; value: string }): Promise<IUserSetting> =>
  axios.post('v0.1/settings', { name, value });

export const getPermissions = (): Promise<IPermissionInfo[]> => axios.get('v0.1/permissions');

export const updatePermissionsForUsers = (data: IPermissionUsersData) =>
  axios.put('v0.1/users/permissions', JSON.stringify(data));

export const findUsersByIds = (userIds: Uuid[], approvedOnly: boolean = true): Promise<IUser[]> =>
  axios.post(`v0.1/users/find?approvedOnly=${approvedOnly}`, userIds);

export const getUserShorts = (params: IGetUserShortsFiltersRequest): Promise<IUserShorts> => {
  const queryString = Object.keys(params)
    .map((key) => key + '=' + params[key as keyof IGetUserShortsFiltersRequest])
    .join('&');
  return axios.get(`v0.1/users?${queryString}`);
};

export const getUserShortsByGroup = (groupId: Uuid): Promise<IUserShorts> =>
  axios.get(`v0.1/users?inUserGroup=${groupId}`);

export const preapproveUsers = (emailList: String[]) => axios.post('v0.1/acl', emailList);

export const approveUser = (userIdentification: IUserIdentification) => {
  return axios.put('v0.1/users/approve', userIdentification);
};

export const unapproveUser = (userIdentification: IUserIdentification) => {
  return axios.delete('v0.1/users/approve', { data: userIdentification });
};

export const getUserGroups = (): Promise<IUserGroups> => axios.get('v0.1/userGroups');

export const createUserGroup = (data: IUserGroupFormData): Promise<IUserGroupResponse> =>
  axios.post('v0.1/userGroups', data);

export const updateUserGroup = (userGroupId: Uuid, data: any): Promise<IUserGroupResponse> =>
  axios.put(`v0.1/userGroups/${userGroupId}`, data);

export const deleteUserGroup = (userGroupId: Uuid) => axios.delete(`v0.1/userGroups/${userGroupId}`);

export const getUserGroup = (groupId: Uuid): Promise<IUserGroup> => axios.get(`v0.1/userGroups/${groupId}`);

export const assignChallenge = (userGroupId: Uuid, challengeId: Uuid) =>
  axios.put(`v0.1/userGroups/${userGroupId}/challenges/${challengeId}`);

export const unassignChallenge = (userGroupId: Uuid, challengeId: Uuid) =>
  axios.delete(`v0.1/userGroups/${userGroupId}/challenges/${challengeId}`);

export const getUserGroupChallenges = (userGroupId: Uuid): Promise<IChallenges> =>
  axios.get(`v0.1/userGroups/${userGroupId}/challenges`);

export const getChallenges = (): Promise<IChallenges> => axios.get('v0.1/challenges');

export const getChallenge = (challengeId: Uuid): Promise<IChallenge> => axios.get(`v0.1/challenges/${challengeId}`);

export const updateChallenge = (challengeId: Uuid, data: IChallengeData): Promise<IChallenge> =>
  axios.put(`v0.1/challenges/${challengeId}`, data);

export const createChallenge = (data: IChallengeData): Promise<IChallenge> =>
  axios.post('v0.1/challenges/create', data);

export const deleteChallenge = (challengeId: Uuid) => axios.delete(`v0.1/challenges/${challengeId}`);

export const getChallengeUserGroups = (challengeId: Uuid): Promise<IUserGroups> =>
  axios.get(`v0.1/challenges/${challengeId}/userGroups`);

export const createForm = (data: ICreateFormData): Promise<IForm> => axios.post('v0.1/forms/create', data);

export const updateForm = (formId: Uuid, data: IUpdateFormData): Promise<IForm> =>
  axios.put(`v0.1/forms/${formId}`, data);

export const deleteForm = (formId: Uuid) => axios.delete(`v0.1/forms/${formId}`);

export const getSubmission = ({
  ideaId,
  includeEvaluations = false,
}: {
  ideaId: Uuid;
  includeEvaluations?: boolean;
}): Promise<ISubmission> => axios.get(`v0.1/submissions/${ideaId}?includeEvaluations=${includeEvaluations}`);

// Create a basic submission to store a new idea, so the type is IIdea, not ISubmission
export const createSubmission = (data: ICreateSubmissionData): Promise<IIdea> =>
  axios.post('v0.1/submissions/create', data);

export const updateSubmission = (submissionId: Uuid, data: IUpdateSubmissionData): Promise<IUpdateSubmissionResults> =>
  axios.put(`v0.1/submissions/${submissionId}`, data);

export const updateSubmissionFeedback = (submissionId: Uuid, data: IUpdateSubmissionPitchData): Promise<IIdea> =>
  axios.put(`v0.1/submissions/${submissionId}/pitch`, data);

export const createEvaluation = (data: ICreateEvaluationData): Promise<IEvaluation> =>
  axios.post('v0.1/evaluations/create', data);

export const getEvaluation = (evaluationId: Uuid): Promise<IEvaluationDetail> =>
  axios.get(`v0.1/evaluations/${evaluationId}`);

export const updateEvaluation = (evaluationId: Uuid, data: IUpdateEvaluationData): Promise<IEvaluationUpdateResults> =>
  axios.put(`v0.1/evaluations/${evaluationId}`, data);

export const getIdeaEvaluations = (submissionId: Uuid): Promise<IEvaluationDetail[]> =>
  axios.get(`v0.1/submissions/${submissionId}/evaluations`);

export const deleteSubmission = (submissionId: Uuid) => axios.delete(`v0.1/submissions/${submissionId}`);

export const getUserIdeas = async (userId: Uuid): Promise<IIdeas> => axios.get(`v0.1/submissions?author=${userId}`);

export const getSubmissions = (filters: ISubmissionsFilters, pageParam: string | null): Promise<IIdeas> => {
  const queryString = Object.keys(filters)
    .map((key) => key + '=' + filters[key as keyof ISubmissionsFilters])
    .join('&');

  const path = pageParam ? pageParam.replace(/^\/api\//, '') : `v0.1/submissions?pageSize=48&${queryString}`;
  return axios.get(path);
};

export const createTextAreaFormField = (data: ICreateTextAreaFormFieldData): Promise<ITextAreaFormField> =>
  axios.post('v0.1/forms/textAreaField/create', data);

export const createRangeFormField = (data: ICreateRangeFormFieldData): Promise<IRangeFormField> =>
  axios.post('v0.1/forms/rangeField/create', data);

// TODO: change "CheckBox" to "Checkbox"
export const createCheckboxFormField = (data: ICreateCheckboxFormFieldData): Promise<ICheckBoxFormField> =>
  axios.post('v0.1/forms/checkboxField/create', data);

export const createBlobFormField = (data: ICreateBlobFormFieldData): Promise<IBlobFormField> =>
  axios.post('v0.1/forms/blobField/create', data);

export const deleteFormField = (fieldId: Uuid) => axios.delete(`v0.1/fields/${fieldId}`);

export const getIdeaComments = (submissionId: Uuid, pageParam: string | null): Promise<IIdeaComments> => {
  const path = pageParam ? pageParam.replace(/^\/api\//, '') : `v0.1/submissions/${submissionId}/comments`;
  return axios.get(path);
};

export const createIdeaComment = ({
  submissionId,
  content,
}: {
  submissionId: Uuid;
  content: string;
}): Promise<IIdeaComment> =>
  axios.post('v0.1/comments/create', {
    submissionId,
    content,
  });

export const updateIdeaComment = (commentId: Uuid, content: string): Promise<IIdeaComment> =>
  axios.put(`v0.1/comments/${commentId}`, { content });

export const deleteIdeaComment = (commentId?: Uuid) => axios.delete(`v0.1/comments/${commentId}`);

export const uploadBlobRequest = ({ originType, originId }: IUploadRequest): Promise<IUploadResponse> =>
  axios.post('v0.1/uploads/request', {
    originType,
    originId,
  });

export const createMemo = (data: IMemoCreateData): Promise<IMemo> => axios.post('v0.1/memos/create', data);

export const getMemos = (submissionId: Uuid, pageParam: string | null): Promise<IMemoList> => {
  const path = pageParam ? pageParam.replace(/^\/api\//, '') : `v0.1/submissions/${submissionId}/memos`;
  return axios.get(path);
};

export const deleteMemo = (memoId?: Uuid) => axios.delete(`v0.1/memos/${memoId}`);

export const acceptTermsOfUsage = () => axios.post('v0.1/tos/accept?document=TERMS_OF_USAGE');

export const acceptIpConsent = () => axios.post('v0.1/tos/accept?document=IP_CONSENT');

export const acceptPrivacyPolicy = () => axios.post('v0.1/tos/accept?document=PRIVACY_POLICY');

export const hasSeen = (data: IHasSeenRequestData) => axios.put('v0.1/hasseen', data);

export const downloadEvaluationsByChallenge = (challengeId: Uuid): Promise<string> =>
  axios.get(`v0.1/challenges/${challengeId}/evaluations/download`);

export const updateIdeaVisibility = (submissionId: Uuid, ideaVisibility: IIdeaVisibility) => {
  return axios.put(`v0.1/submissions/${submissionId}/visibility?visibility=${ideaVisibility}`);
};

export const adminChangeIdeaPhase = (submissionId: Uuid, phase: IIdeaPhase) =>
  axios.put(`v0.1/submissions/${submissionId}/phase?phase=${phase}`);

export const getTags = (): Promise<ITagList> => axios.get('v0.1/tags');

export const applySubmissionTag = (submissionId: Uuid, tagId: Uuid) =>
  axios.put(`v0.1/submissions/${submissionId}/tag/${tagId}`);

export const deleteSubmissionTag = (submissionId: Uuid, tagId: Uuid) =>
  axios.delete(`v0.1/submissions/${submissionId}/tag/${tagId}`);
