import { Link } from 'react-router-dom';
import { IChallenge, IDefaultSubmissionTag, IIdeaStatus } from '../../util/types';
import {
  isChallengeNotStarted,
  isChallengeClosed,
  transformImage,
  sortedIdeasBySpotlightTag,
} from '../../util/helpers';
import { useSubmissions } from '../../hooks/useSubmissions';
import PageWrapper from '../PageWrapper';
import { Button, PrimaryButton } from '../Button';
import Badge from '../Badge';
import IdeasGrid from '../IdeasGrid';
import styles from './index.module.scss';

interface IProps {
  challenge: IChallenge;
}

// TODO: Temporary solution to Open Call Challenge Page
// because the Open Call page has many hard coded content
const OpenCallChallengeDetailPage = ({ challenge }: IProps) => {
  const { data: spotlightIdeasData } = useSubmissions({
    filters: { status: IIdeaStatus.Published, challenge: challenge.id, tag: IDefaultSubmissionTag.Spotlight },
  });
  const {
    data: ideasData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useSubmissions({
    filters: { status: IIdeaStatus.Published, challenge: challenge.id },
  });
  const spotlightIdeas = spotlightIdeasData?.pages.flatMap((page) => page.data) || [];
  const allIdeas = ideasData?.pages.flatMap((page) => page.data) || [];
  const sortedIdeas = sortedIdeasBySpotlightTag(spotlightIdeas, allIdeas);

  return (
    <PageWrapper>
      <div className={styles.challengeOverview}>
        {isChallengeClosed(challenge) && (
          <Badge text={`${challenge.title} is now closed`} variant="grey" className={styles.closedTag} />
        )}
        <h1 className={styles.title}>Submit an Idea to Get Started</h1>
        <div className={styles.content}>
          <img
            className={styles.bannerImage}
            src={transformImage({ imageUrl: challenge.image?.url, size: 'banner' })}
          />
          <div className={styles.description}>
            <p>
              Ready to start building an idea you’re passionate about? You’ve come to the right place. We know even the
              biggest ideas start small and take time to develop. That's why we’re building a community dedicated to
              helping develop your ideas with you right here on SMAP. Our goal is to help your ideas grow and succeed.
            </p>
            <p>
              You’re not expected to know all the answers on day one. Together with the SMAP community, we can explore
              new ways of visualizing and developing your idea. There is no need to wait or submit by a specific
              deadline. Share your vision and start building the future.
            </p>
            <p>
              Upon submission, all ideas receive ongoing feedback and support from the SMAP community including the Moon
              Operating Team. Your idea will live publicly within the Idea Library where any SMAP community member can
              comment, provide feedback and add suggestions to the concept you share. Think of the Idea Library as the
              refining process to your idea. With the support of others. You can make updates and changes to your idea
              draft at any time.
            </p>
            <p>
              Ideate - Submit an idea on SMAP and your idea will automatically be added to the Idea Library. You will be
              able to track your progress and receive feedback directly from the greater SMAP community. You have the
              opportunity to learn by doing and iterating with others.
            </p>
            <p>
              Collaborate - You will be invited to a 1-on-1 session with a Moon Program Designer. We'll bring together
              the brightest minds, diverse backgrounds and new ways of thinking to invest in and develop your idea with
              you.
            </p>
            <p>
              Grow- Developing an idea can often take on many different shapes, forms and timelines. The Moon team will
              provide resources and connect you to the right people to start formulating a true business plan around
              your idea. We’ll ask you new questions, show you helpful tools and teach new ways of thinking to best
              support your growth.
            </p>
            <p>
              Pitch - If Moon feels that your idea is ready to start building, we will invite you to pitch to the Moon
              Committee for final approval.
            </p>
            <p>
              Don’t worry, your idea doesn’t have to be perfect before you get started. In fact, there is not one type
              of idea or response that we’re looking for. This is your chance to tell us what the world needs, we’re
              here as your support system.
            </p>
          </div>
          <div className={styles.offerList}>
            <p>Once submitted, you’ll receive:</p>
            <ul>
              <li>1-on-1 session with a Moon Program Designer</li>
              <li>Moon Creative Lab Design toolkit</li>
              <li>Feedback from the SMAP community</li>
              <li>Exclusive access to the Ideation:Hub community on Slack </li>
              <li>If selected, the chance to incubate your idea with Moon in Tokyo or Palo Alto</li>
              <li>Opportunity to test your hypothesis and learn by building a live prototype</li>
              <li>Quarterly goal setting to build your business</li>
            </ul>
            {isChallengeNotStarted(challenge) || isChallengeClosed(challenge) ? (
              <PrimaryButton disabled className={styles.closedNotice}>
                {isChallengeNotStarted(challenge) ? 'Submissions Have Not Started' : 'Submission Closed'}
              </PrimaryButton>
            ) : (
              <Link className={styles.buttonWrapper} to={`/challenges/${challenge.id}/submission/create`}>
                <PrimaryButton data-cy="submit-idea-button">Start your Submission</PrimaryButton>
              </Link>
            )}
          </div>
        </div>

        {sortedIdeas && sortedIdeas.length !== 0 ? (
          <div className={styles.ideas}>
            <h1 className={styles.title}>Ideas</h1>
            <IdeasGrid ideas={sortedIdeas} />
            {hasNextPage && (
              <Button variant="outline" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
                Load more
              </Button>
            )}
          </div>
        ) : null}
      </div>
    </PageWrapper>
  );
};

export default OpenCallChallengeDetailPage;
