import classNames from 'classnames';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getChallenges } from '../../util/api';
import { ReactQueryKey } from '../../util/types';
import { challengeUrl, isChallengeClosed, transformImage } from '../../util/helpers';
import Badge from '../Badge';
import { Button } from '../Button';
import FullScreenLoading from '../FullScreenLoading';
import styles from './index.module.scss';
import RichTextPreview from '../RichTextPreview';

const UserChallengeList = () => {
  const { data: challengesData, isLoading } = useQuery(ReactQueryKey.Challenges, getChallenges);

  if (isLoading) {
    return <FullScreenLoading />;
  }

  const challenges = challengesData?.data || [];
  const publishedChallenges = challenges.filter((c) => c.publishedAt && c.publishedAt <= new Date());

  return (
    <div className={styles.wrapper} data-testid="challenge-list">
      {publishedChallenges.map((challenge) => (
        <div
          key={challenge.id}
          className={classNames(styles.container, isChallengeClosed(challenge) && styles.greyOut)}
        >
          <img className={styles.image} src={transformImage({ imageUrl: challenge.image?.url, size: 'card-small' })} />
          <div className={styles.contentWrapper}>
            <h2 className={styles.title}>
              {isChallengeClosed(challenge) && <Badge text="closed" variant="grey" className={styles.closedTag} />}
              {challenge.title}
            </h2>
            <RichTextPreview className={styles.content} value={challenge.content} />
            <Link to={challengeUrl(challenge)}>
              <Button className={styles.cta} variant="black">
                Learn More
              </Button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserChallengeList;
