import { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import styles from './index.module.scss';

interface IProps {
  show: boolean;
  dismiss: () => void;
  editIdea: () => void;
  showDeleteModal: () => void;
}

const IdeaOptionsTooltip = ({ show, dismiss, editIdea, showDeleteModal }: IProps) => {
  const ref = useRef(null);
  useOnClickOutside(ref, dismiss);

  return show ? (
    <div ref={ref} className={styles.optionsModal} onClick={showDeleteModal}>
      <span className={styles.option} onClick={editIdea}>
        Edit idea
      </span>
      <span className={styles.option} onClick={showDeleteModal}>
        Delete idea
      </span>
    </div>
  ) : null;
};

export default IdeaOptionsTooltip;
