import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useAuthenticate } from '../../hooks/useAuthenticate';
import styles from './index.module.scss';

interface IProps {
  dismiss: () => void;
}

const UserNavbarMobileMenu = ({ dismiss }: IProps) => {
  const { logOut } = useAuthenticate();

  const handleLogout = () => {
    dismiss();
    logOut();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <NavLink className={styles.navItem} activeClassName={styles.selected} to="/ideas" onClick={dismiss}>
          Idea Library
        </NavLink>
        <NavLink className={styles.navItem} activeClassName={styles.selected} to="/challenges" onClick={dismiss}>
          Submit an Idea
        </NavLink>
        <NavLink
          className={styles.navItem}
          activeClassName={styles.selected}
          to="/organization-ideas"
          onClick={dismiss}
        >
          Transform your Org
        </NavLink>
        <NavLink className={styles.navItem} activeClassName={styles.selected} to="/my/ideas" onClick={dismiss}>
          My Ideas
        </NavLink>
        <NavLink className={styles.navItem} activeClassName={styles.selected} to="/settings" onClick={dismiss}>
          Settings
        </NavLink>
        <div className={classnames(styles.navItem, styles.signOut)} onClick={handleLogout}>
          Sign Out
        </div>
      </div>
    </div>
  );
};

export default UserNavbarMobileMenu;
