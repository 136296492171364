import React from 'react';
import { sample } from 'lodash-es';
import Modal from '../Modal';
import styles from './index.module.scss';
import { ReactComponent as CatOnRocketIcon } from '../../assets/icons/catOnRocket.svg';
import { ReactComponent as BringIdeaToLifeIcon } from '../../assets/icons/bringIdeaToLife.svg';
import { ReactComponent as ManWatchRocketIcon } from '../../assets/icons/manWatchRocket.svg';
import { ReactComponent as ManYellowShoeIcon } from '../../assets/icons/manYellowShoe.svg';
import { ReactComponent as WomanOnSwingIcon } from '../../assets/icons/womanOnSwing.svg';
import { ReactComponent as RocketLaunchingIcon } from '../../assets/icons/rocketLaunching.svg';
import { isMobile } from '../../util/helpers';

interface Props {
  isVisible: boolean;
  dismiss: () => any;
}

const SubmitIdeaCongratulationModal = ({ isVisible, dismiss }: Props) => {
  const ICON_COMPONENTS = [
    CatOnRocketIcon,
    BringIdeaToLifeIcon,
    ManWatchRocketIcon,
    ManYellowShoeIcon,
    WomanOnSwingIcon,
    RocketLaunchingIcon,
  ];

  const Component = sample(ICON_COMPONENTS);

  return (
    <Modal containerClassName={styles.wrapper} isVisible={isVisible} dismiss={dismiss}>
      <div className={styles.message}>
        <h1 className={styles.header}>Bravo!</h1>
        {isMobile && <div className={styles.image}>{Component && <Component className={styles.icon} />}</div>}
        <div className={styles.description}>
          <div>Your idea has been submitted.</div>
          <div>Moon will review your idea and will reach back after the submission is closed.</div>
        </div>
      </div>
      {!isMobile && <div className={styles.image}>{Component && <Component className={styles.icon} />}</div>}
    </Modal>
  );
};

export default SubmitIdeaCongratulationModal;
