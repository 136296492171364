import ReactTooltip from 'react-tooltip';
import { ReactComponent as QuestionMarkCircle } from '../../assets/icons/questionMarkCircle.svg';
import styles from './index.module.scss';

interface IProps {
  text: string;
}

const QuestionMarkTooltip = ({ text }: IProps) => {
  return (
    <>
      <QuestionMarkCircle className={styles.icon} data-tip="custom show" data-event="click focus" />
      <ReactTooltip globalEventOff="click" effect="solid" place="bottom">
        <div className={styles.tooltipContent}>{text}</div>
      </ReactTooltip>
    </>
  );
};

export default QuestionMarkTooltip;
