import React, { memo } from 'react';
import { IUserShort } from '../../util/types';
import styles from './index.module.scss';
import ProfileAvatar from '../ProfileAvatar';

interface IProps {
  ideaAuthor: IUserShort;
}

const IdeaOwner = ({ ideaAuthor }: IProps) => {
  return (
    <div className={styles.wrapper} data-cy="idea-owner-container">
      <div className={styles.ideaOwnerContainer}>
        <ProfileAvatar avatarUrl={ideaAuthor?.avatar?.url} firstName={ideaAuthor?.givenName} />
        <div className={styles.ideaOwner}>
          <span className={styles.name}>
            {ideaAuthor?.givenName} {ideaAuthor?.familyName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(IdeaOwner);
