// Common
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';

// Challenges
export const GET_CHALLENGE_LIST = 'GET_CHALLENGE_LIST';
export const GET_CHALLENGE_LIST_SUCCESS = 'GET_CHALLENGE_LIST_SUCCESS';
export const GET_CHALLENGE_DETAIL = 'GET_CHALLENGE_DETAIL';
export const CREATE_CHALLENGE = 'CREATE_CHALLENGE';

// Forms
export const CREATE_FORM = 'CREATE_FORM';

// Questions(Fields)
export const CREATE_TEXT_QUESTION = 'CREATE_TEXT_QUESTION';
export const CREATE_RANGE_QUESTION = 'CREATE_RANGE_QUESTION';

// Message Modal
export const OPEN_MESSAGE_MODAL = 'OPEN_MESSAGE_MODAL';
export const CLOSE_MESSAGE_MODAL = 'CLOSE_MESSAGE_MODAL';
