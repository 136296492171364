import { IUserPermission, IUserShort, IUserShorts } from '../../util/types';
import { Link } from 'react-router-dom';
import Grid from '../Grid';
import ProfileAvatar from '../ProfileAvatar';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import styles from './index.module.scss';

interface IUserListProps {
  userShorts: IUserShorts | undefined;
  isLoading: boolean;
  linkToUserPage?: boolean;
}

const UserShorts = ({ userShorts, isLoading, linkToUserPage = false }: IUserListProps) => {
  const { hasPermission } = useCurrentUser();

  const avatar = (userShort: IUserShort) => (
    <ProfileAvatar avatarUrl={userShort.avatar?.url} firstName={userShort.givenName} large />
  );

  const name = (userShort: IUserShort) => (
    <div className={styles.userShortColumn}>
      <div className={styles.name}>{`${userShort.givenName} ${userShort.familyName}`}</div>
    </div>
  );

  const email = (userShort: IUserShort) => (
    <div className={styles.userShortColumn}>
      <div className={styles.email}>{userShort.emailAddress}</div>
    </div>
  );

  const link = (userShort: IUserShort) =>
    hasPermission(IUserPermission.CanListUserPermissions) ? (
      <Link className={styles.userLink} to={`/admin/user/${userShort.id}`} target="_blank">
        <div>Manage user</div>
      </Link>
    ) : (
      <div className={styles.warning}>
        <strong>CAN_LIST_USER_PERMISSIONS</strong> required!
      </div>
    );

  if (!userShorts) {
    return null;
  }

  const columns = linkToUserPage
    ? [<span />, <span>Name</span>, <span>Email Address</span>, <span>User Management</span>]
    : [<span />, <span>Name</span>, <span>Email Address</span>];

  const rows = userShorts.data.map((userShort) =>
    linkToUserPage
      ? [avatar(userShort), name(userShort), email(userShort), link(userShort)]
      : [avatar(userShort), name(userShort), email(userShort)],
  );

  return (
    <div className={styles.container}>
      {isLoading && <p>loading users...</p>}
      <Grid columns={columns} rows={rows} />
    </div>
  );
};

export default UserShorts;
