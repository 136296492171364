import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrowLeft.svg';
import styles from './index.module.scss';

const BackButton = () => {
  return (
    <div className={styles.wrapper}>
      <ArrowLeftIcon className={styles.arrowLeftIcon} />
      <span>Back</span>
    </div>
  );
};

export default BackButton;
