import { useQuery } from 'react-query';
import { getUserShorts } from '../../util/api';
import { IGetUserShortsFiltersRequest, ReactQueryKey } from '../../util/types';
import UserShorts from '../UserShorts';
import styles from './index.module.scss';

interface IProps {
  matchToFind: IGetUserShortsFiltersRequest;
}

const AdminUserSearchResults = ({ matchToFind }: IProps) => {
  const { data: userShorts, isLoading } = useQuery(
    [ReactQueryKey.UserShorts, matchToFind],
    () => getUserShorts(matchToFind),
    { enabled: matchToFind.match !== '' },
  );

  return userShorts ? (
    <>
      <div className={styles.title} data-testid="search-results">{`Search results for '${matchToFind.match}'`}</div>
      <UserShorts userShorts={userShorts} isLoading={isLoading} linkToUserPage />
    </>
  ) : null;
};

export default AdminUserSearchResults;
