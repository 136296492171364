import { useHistory, useParams } from 'react-router';
import { useQuery } from 'react-query';
import { IUrlParams, ReactQueryKey } from '../../util/types';
import { getSubmission } from '../../util/api';
import { useError } from '../../hooks/useError';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import PageWrapper from '../../components/PageWrapper';
import IdeaSubmissionFields from '../../components/IdeaSubmissionFields';
import SubmissionIdea from '../../components/SubmissionIdea';
import PitchFeedback from '../../components/PitchFeedback';
import OtherIdeas from '../../components/OtherIdeas';
import IdeaComments from '../../components/IdeaComments';
import IdeaSetting from '../../components/IdeaSetting';
import styles from './index.module.scss';

const UserIdeaDetailsPage = () => {
  const { ideaId } = useParams<IUrlParams>();
  const {
    data: submission,
    isLoading,
    isError,
    error,
  } = useQuery([ReactQueryKey.Submission, { ideaId }], () => getSubmission({ ideaId }));
  const { data: currentUser } = useCurrentUser();
  const { handleError } = useError();
  const history = useHistory();

  if (isError) handleError(error, () => history.replace('/ideas'));

  const isAuthorCurrentUser = submission?.idea.author.id === currentUser?.id;

  return (
    <PageWrapper>
      {isLoading && <div>loading...</div>}
      {submission ? (
        <>
          <div className={styles.header} data-cy="idea-header">
            <h1>{submission.idea.name}</h1>
            {isAuthorCurrentUser && <IdeaSetting idea={submission.idea} />}
          </div>
          <div className={styles.container}>
            <div className={styles.main}>
              <SubmissionIdea idea={submission.idea} />
              <IdeaSubmissionFields challengeId={submission.idea.challengeId} submissionFields={submission.fields} />
              {submission.idea.pitched && <PitchFeedback submission={submission} />}
              <OtherIdeas currentIdeaId={submission.idea.id} user={submission.idea.author} />
            </div>
            <div className={styles.comments}>
              <IdeaComments ideaId={submission.idea.id} />
            </div>
          </div>
        </>
      ) : null}
    </PageWrapper>
  );
};

export default UserIdeaDetailsPage;
