import React, { FormEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import FullScreenLoading from '../../components/FullScreenLoading';
import { useAuthenticate } from '../../hooks/useAuthenticate';
import { Button } from '../../components/Button';
import useAutoPopulateEmail from '../../hooks/useAutoPopulateEmail';
import styles from './index.module.scss';

const LoginPage = () => {
  const { data: currentUser, isLoading } = useCurrentUser();
  const { email, setEmail, login } = useAuthenticate();
  const history = useHistory();
  const [storedEmail, _] = useAutoPopulateEmail();

  useEffect(() => {
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  if (isLoading) {
    return <FullScreenLoading />;
  }

  if (currentUser) {
    history.replace('/home');
  }

  const handleEmailChange = (event: FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  const handleKeyPressEnterLogin = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') login();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.brandMessageContainer}>
          <div className={styles.welcomeText}>Welcome to</div>
          <h1 className={styles.brandText}>smap</h1>
          <p className={styles.description}>Collaborative Idea Development Platform for Moon Creative Lab</p>
        </div>
        <div className={styles.inputContainer}>
          <input
            data-cy="email-input"
            className={styles.emailInput}
            value={email}
            type="text"
            placeholder="Enter your email"
            onChange={handleEmailChange}
            onKeyPress={handleKeyPressEnterLogin}
          />
          <Button variant="black" className={styles.button} data-cy="login-submit-button" onClick={login}>
            Enter
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
