import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import common from './commonReducers';
import challenge from './challengeReducers';

const appReducer = (history: any) =>
  combineReducers({
    common,
    challenge,
    router: connectRouter(history),
  });

const rootReducer = (history: any) => (state: any, action: any) => {
  return appReducer(history)(state, action);
};

export default rootReducer;
