import { useCurrentUser } from '../../hooks/useCurrentUser';
import { IUserPermission, IUserPreapprovalFormInputs } from '../../util/types';
import AdminPageTitle from '../../components/AdminPageTitle';
import { Button } from '../../components/Button';
import styles from './index.module.scss';
import HookFormTextInput from '../../components/HookFormTextInput';
import { useForm } from 'react-hook-form';
import { preapproveUsers } from '../../util/api';
import { useError } from '../../hooks/useError';
import classnames from 'classnames';
import { useState } from 'react';

const AdminPreapproveUsersPage = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { hasPermission } = useCurrentUser();
  const { handleError } = useError();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ reValidateMode: 'onSubmit' });

  const submitEmails = async (data: IUserPreapprovalFormInputs) => {
    setIsSubmitted(false);
    const { userEmails } = data;
    const splitEmails = userEmails ? userEmails.split(/[, ]/).filter((val) => val !== '') : [];
    await preapproveUsers(splitEmails)
      .then(() => {
        setIsSubmitted(true);
      })
      .catch(handleError);
  };

  return (
    <div>
      <AdminPageTitle text="User Preapproval Management" />
      {hasPermission(IUserPermission.CanApproveUsers) && (
        <>
          <div className={styles.container}>
            <form className={styles.form} data-testid="preapprove-user-emails-form">
              <HookFormTextInput
                name="userEmails"
                label="User Emails to Preapprove:"
                // TODO: rules to validate comma separate emails
                rules={{ required: 'This form is required' }}
                register={register}
                errors={errors}
                dataTestId="user-emails-input"
              />
              <br />
              <div>Enter a list of emails (space or comma-separated) to preapprove</div>
              <div className={styles.control}>
                <Button
                  data-testid="submit"
                  className={styles.button}
                  variant="black"
                  onClick={handleSubmit(submitEmails)}
                >
                  Add new pre-approved email addresses
                </Button>
                <div className={classnames(styles.feedback, isSubmitted && styles.submitted)}>Form submitted</div>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminPreapproveUsersPage;
