import { useForm } from 'react-hook-form';
import { createMemo } from '../../util/api';
import { queryClient } from '../../util/client';
import { IMemoAudience, ReactQueryKey, Uuid } from '../../util/types';
import { useError } from '../../hooks/useError';
import HookFormRichTextInput from '../HookFormRichTextInput';
import { Button } from '../Button';
import styles from './index.module.scss';

interface IProps {
  submissionId: Uuid;
  onSubmit?: () => void;
}

interface IMemoCreateFormInputs {
  title: string;
  content: string;
  // audience: ISelectOption<IMemoAudience>;
}

const MemoCreateForm = ({ submissionId, onSubmit }: IProps) => {
  const { register, control, handleSubmit, formState, setValue } = useForm();
  const { handleError } = useError();

  // const options: ISelectOption<IMemoAudience>[] = Object.values(IMemoAudience).map((type) => ({
  //   label: type as string,
  //   value: type,
  // }));

  const toCreateMemo = async (data: IMemoCreateFormInputs) => {
    await createMemo({ submissionId, title: 'Memo', content: data.content, audience: IMemoAudience.Panel })
      .then(() => {
        queryClient.invalidateQueries(ReactQueryKey.Memos);
        onSubmit && onSubmit();
      })
      .catch(handleError);
  };

  return (
    <div>
      <h3 className={styles.header}>Add a Memo</h3>
      <form onSubmit={handleSubmit(toCreateMemo)} className={styles.form}>
        {/* TODO: Default to Panel until further instruction */}
        {/* <HookFormSelect
          name="audience"
          label="Audience"
          rules={{ required: 'required' }}
          options={options}
          placeholder="Select one"
          control={control}
          errors={formState.errors}
        /> */}
        <HookFormRichTextInput
          name="content"
          rules={{ required: 'required' }}
          control={control}
          errors={formState.errors}
          dataTestId="content-input"
        />
        <Button type="submit" variant="black" className={styles.button}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default MemoCreateForm;
