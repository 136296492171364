import { memo } from 'react';
import Lottie from 'lottie-react';
import styles from './index.module.scss';
import loadingAnimation from '../../assets/lottie-animations/loading.json';

const FullScreenLoading = () => (
  <div data-testid="full-screen-loader" className={styles.fullscreenOpacityLayer}>
    <div className={styles.spinningIconContainer}>
      <Lottie loop={false} animationData={loadingAnimation} />
    </div>
  </div>
);

export default memo(FullScreenLoading);
