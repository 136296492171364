import { START_LOADING, END_LOADING, OPEN_MESSAGE_MODAL, CLOSE_MESSAGE_MODAL } from '../actionConstants';
import { IBaseAction, IMessageInfo } from '../../util/types';

interface ICommonState {
  isLoading: boolean;
  globalMessageModal: IMessageInfo;
}

export const initialState = {
  isLoading: false,
  globalMessageModal: {
    showMessageModal: false,
  },
};

export default (state: ICommonState = initialState, action: IBaseAction) => {
  const { type, payload } = action;
  switch (type) {
    case START_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case END_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case OPEN_MESSAGE_MODAL: {
      return {
        ...state,
        globalMessageModal: {
          ...state.globalMessageModal,
          showMessageModal: true,
          ...payload,
        },
      };
    }
    case CLOSE_MESSAGE_MODAL: {
      return {
        ...state,
        globalMessageModal: {
          ...state.globalMessageModal,
          showMessageModal: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};
