import { Link } from 'react-router-dom';
import { isChallengeNotStarted, isChallengeClosed, sortedIdeasBySpotlightTag } from '../../util/helpers';
import { IChallenge, IDefaultSubmissionTag, IIdeaStatus } from '../../util/types';
import { Button } from '../Button';
import PitchDeckExampleCover from '../../assets/img/pitchDeckExampleCover.png';
import { useSubmissions } from '../../hooks/useSubmissions';
import Badge from '../Badge';
import PageWrapper from '../PageWrapper';
import VideoBanner from '../VideoBanner';
import IdeasGrid from '../IdeasGrid';
import styles from './index.module.scss';

interface IProps {
  challenge: IChallenge;
}

const IndividualIdeaApplicationPage = ({ challenge }: IProps) => {
  const { data: spotlightIdeasData } = useSubmissions({
    filters: { status: IIdeaStatus.Published, challenge: challenge.id, tag: IDefaultSubmissionTag.Spotlight },
  });
  const {
    data: ideasData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useSubmissions({
    filters: { status: IIdeaStatus.Published, challenge: challenge.id },
  });
  const spotlightIdeas = spotlightIdeasData?.pages.flatMap((page) => page.data) || [];
  const allIdeas = ideasData?.pages.flatMap((page) => page.data) || [];
  const sortedIdeas = sortedIdeasBySpotlightTag(spotlightIdeas, allIdeas);

  return (
    <PageWrapper>
      <div className={styles.challengeOverview}>
        {isChallengeClosed(challenge) && (
          <Badge text={`${challenge.title} is now closed`} variant="grey" className={styles.closedTag} />
        )}
        <h1 className={styles.title}>Submit an Idea</h1>
        <div className={styles.content}>
          <div className={styles.bannerImage}>
            <VideoBanner embedUrl="https://player.vimeo.com/video/591314038" />
          </div>
          <div className={styles.description}>
            <p>
              We are accepting applications for new individual ideas from Monday, September 6th (Pacific time)/ Tuesday,
              September 7th(Tokyo Time) ! If you would like to pursue an idea that you’re passionate about, submit your
              application by{' '}
              <b>8:00 pm Thursday, September 29 (Pacific Time)/12:00 pm noon Friday, September 30 (Tokyo time).</b>
            </p>
            <p>
              Remember no idea is perfect! It’s okay if you don’t know all the answers or haven’t figured everything out
              yet. In this new submission process, we’ll be reviewing not only the potential of the idea but also the
              potential of the person as a future Entrepreneur-in-Residence (EIR). That's you! Moon is looking for Idea
              Owners that are passionate about their idea, want to learn, experiment and push forward even against
              challenges and the unknown. Entrepreneurship is a journey and the lessons learned on this journey will
              help you get one step closer to success!
            </p>
            <p>
              <b>Submit your idea in three simple steps:</b>
            </p>
            <ol>
              <li>
                Answer questions about your idea with the submission of a 6 slide presentation deck (not including title
                and end slide). Get started by selecting “Start your submission” tab.
              </li>
              <li>
                Submit a casual video recording in English or Japanese (maximum 1 minute in length) answering the
                following questions:
                <ul>
                  <li>
                    In a few sentences, tell us about your idea and why you think it would be a compelling business.
                  </li>
                  <li>
                    Tell us why you think you’re the best person to pursue this idea. Why are you so passionate about
                    it?
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              <em>
                Video tips: Please make sure that you have good lighting and that we can see and hear you clearly. No
                need to use presentation slides. Keep it casual and shoot it in a single take. No need to edit. Here are
                some examples:
              </em>
            </p>

            <h2 className={styles.sectionHeader}>Pitch Deck Guide with Samples</h2>
            <div>
              <img className={styles.assetWrapper} src={PitchDeckExampleCover} />
              <a
                className={styles.seeGuideCtaWrapper}
                href="https://drive.google.com/file/d/11UaFd4dPA5vR-UGYOK5TbcF40ncb6L09/view?usp=sharing"
                target="_"
              >
                <Button variant="black" className={styles.seeGuideCta}>
                  See Deck Guide
                </Button>
              </a>
            </div>

            <h2 className={styles.sectionHeader}>Video Examples</h2>
            <div className={styles.assetWrapper}>
              <VideoBanner embedUrl="https://player.vimeo.com/video/591314766" title="banner video" />
            </div>
          </div>

          <div className={styles.offerList}>
            <p>What’s New About the Idea Submission Process</p>
            <ul data-testid="info-list">
              <li>
                Applications open at 8:00 pm Monday, September 6 (Pacific Time)/12:00 pm noon Tuesday, September 7
                (Tokyo time)
              </li>
              <li>Applications will be private and will only be reviewed by the Moon Team</li>
              <li>
                Deadline for applications: 8:00 pm Thursday, September 29 (Pacific Time)/12:00 pm noon Friday, September
                30 (Tokyo time)
              </li>
              <li>We’ll be inviting teams to talk to us during the week of October 11</li>
              <li>Teams will be informed if they are selected by Friday, October 15</li>
              <li>
                Selected teams will be asked to present their ideas at Moon’s upcoming event, Illuminate, scheduled for{' '}
                <strong>Friday, November 5</strong>
              </li>
            </ul>
            {isChallengeNotStarted(challenge) || isChallengeClosed(challenge) ? (
              <Button disabled className={styles.closedNotice}>
                {isChallengeNotStarted(challenge) ? 'Submissions Have Not Started' : 'Submission Closed'}
              </Button>
            ) : (
              <Link className={styles.buttonWrapper} to={`/challenges/${challenge.id}/submission/create`}>
                <Button data-cy="submit-idea-button">Start your Submission</Button>
              </Link>
            )}
          </div>
        </div>

        {sortedIdeas && sortedIdeas.length !== 0 ? (
          <div className={styles.ideas}>
            <h1 className={styles.title}>Ideas</h1>
            <IdeasGrid ideas={sortedIdeas} />
            {hasNextPage && (
              <Button variant="outline" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
                Load more
              </Button>
            )}
          </div>
        ) : null}
      </div>
    </PageWrapper>
  );
};

export default IndividualIdeaApplicationPage;
