import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import { useSubmissions } from '../../hooks/useSubmissions';
import { getChallenge } from '../../util/api';
import { isChallengeNotStarted, isChallengeClosed, sortedIdeasBySpotlightTag } from '../../util/helpers';
import { IDefaultSubmissionTag, IIdeaStatus, ReactQueryKey, Uuid } from '../../util/types';
import MoonOriginalChallengeSplash from '../../assets/img/moonOriginalChallengeSplash.svg';
import MoonWellnessSplash from '../../assets/img/moonWellnessSplash2.svg';
import MoonWellnessInfo1 from '../../assets/img/moonWellnessInfo1.png';
import MoonWellnessInfo2 from '../../assets/img/moonWellnessInfo2.png';
import { useError } from '../../hooks/useError';
import { Button } from '../../components/Button';
import IdeasGrid from '../../components/IdeasGrid';
import Badge from '../../components/Badge';
import styles from './index.module.scss';

interface IProps {
  challengeId: Uuid;
}

const MoonOriginalChallengePage = ({ challengeId }: IProps) => {
  const {
    data: challenge,
    isLoading,
    isError,
    error,
  } = useQuery([ReactQueryKey.Challenge, { challengeId }], () => getChallenge(challengeId));
  const { data: spotlightIdeasData } = useSubmissions({
    filters: { status: IIdeaStatus.Published, challenge: challengeId, tag: IDefaultSubmissionTag.Spotlight },
  });
  const {
    data: ideasData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useSubmissions({
    filters: { status: IIdeaStatus.Published, challenge: challengeId },
  });
  const { handleError } = useError();

  const spotlightIdeas = spotlightIdeasData?.pages.flatMap((page) => page.data) || [];
  const allIdeas = ideasData?.pages.flatMap((page) => page.data) || [];
  const sortedIdeas = sortedIdeasBySpotlightTag(spotlightIdeas, allIdeas);

  if (isError || !challenge) {
    handleError(error);
    return null;
  }

  if (isLoading) return <p>loading...</p>;

  return (
    <PageWrapper>
      <div className={styles.challengeOverview}>
        {isChallengeClosed(challenge) && (
          <Badge text={`${challenge.title} is now closed`} variant="grey" className={styles.closedTag} />
        )}
        <h1 className={styles.title}>Welcome to the 1st Moon Original Challenge!</h1>
        <div className={styles.content}>
          <img className={styles.bannerImage} src={MoonOriginalChallengeSplash} />
          <div className={styles.description}>
            <p>
              As part of this challenge, everyone at Moon in both studios (and around the world) will have the
              opportunity to submit potential venture ideas and then work on them in small teams. The goal of this
              challenge is to “Set Our Own Course” and come up with brilliant new ideas that Moon can incubate and
              launch into the world!
            </p>
            <hr />
            <img className={styles.bannerImage} src={MoonWellnessSplash} />
            <div className={styles.sectionTitle}>Focus on Wellness</div>
            <p>
              The COVID global pandemic has shown us that investing in our own personal wellness/well-being is probably
              one of the most important things we can do for livelihood. There is also a growing market in the wellness
              space ($4.2 trillion!) and who better than us at Moon to help think of new products/services/experiences
              to re-define what the future of wellness should look like.
            </p>
            <img className={styles.bannerImage} src={MoonWellnessInfo1} />
            <div className={styles.sectionTitle}>Multi-market Mega Opportunity</div>
            <p>
              According to the Global Wellness Institute (GWI), wellness can be defined as, “the active pursuit of
              activities, choices and lifestyles that lead to a state of holistic health.”
            </p>
            <img className={styles.bannerImage} src={MoonWellnessInfo2} />
            <div className={styles.sectionTitle}>Types of Wellness</div>
            <p>
              There are many types of wellness, from physical and emotional wellness, to environmental and occupational
              wellness. You can read more about the different sectors that make up the wellness economy here:{' '}
              <a
                className={styles.link}
                target="_"
                href="https://globalwellnessinstitute.org/what-is-wellness/wellness-economy-definitions/"
              >
                https://globalwellnessinstitute.org/what-is-wellness/wellness-economy-definitions/
              </a>
            </p>
          </div>
          <div className={styles.offerList}>
            <p>Weekly Schedule:</p>
            <ul>
              <li>Week 1: June 21-25 — Announce Challenge</li>
              <li>Week 2: June 28-July 2 — Submit Ideas</li>
              <li>Week 3: July 5-9 — Shortlist Ideas & Team Sign-Ups</li>
              <li>Week 4: July 12-16 — Team Prep & Challenge Week!</li>
            </ul>
            {isChallengeNotStarted(challenge) || isChallengeClosed(challenge) ? (
              <Button disabled className={styles.closedNotice}>
                {isChallengeNotStarted(challenge) ? 'Submissions Have Not Started' : 'Submission Closed'}
              </Button>
            ) : (
              <Link className={styles.buttonWrapper} to={`/challenges/${challenge.id}/submission/create`}>
                <Button data-cy="submit-idea-button">Start your Submission</Button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <hr />

      {sortedIdeas && sortedIdeas.length !== 0 ? (
        <div className={styles.ideasContainer}>
          <h2 className={styles.title}>Ideas</h2>
          <IdeasGrid ideas={sortedIdeas} />
          {hasNextPage && (
            <Button variant="outline" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
              Load more
            </Button>
          )}
        </div>
      ) : null}
    </PageWrapper>
  );
};

export default MoonOriginalChallengePage;
