import { PickerFileCallback } from 'filestack-react';
import { JsxElement } from 'typescript';
import { v4 as uuidv4 } from 'uuid';

// UUID is a brand of string
// https://www.typescriptlang.org/play#example/nominal-typing
export type Uuid = string & { __uuid: typeof uuidv4 };

export enum ReactQueryKey {
  Tags = 'TAGS',
  Tag = 'TAG',
  Submissions = 'SUBMISSIONS',
  Submission = 'SUBMISSION',
  IdeaEvaluations = 'IDEA_EVALUATIONS',
  Challenges = 'CHALLENGES',
  Challenge = 'CHALLENGE',
  Evaluation = 'EVALUATION',
  IdeaComments = 'IDEA_COMMENTS',
  Memos = 'MEMOS',
  HomePageContent = 'HOME_PAGE_CONTENT',
  Webinars = 'WEBINARS',
  VisionBlog = 'VISION_BLOG',
  VentureNews = 'VENTURE_NEWS',
  Settings = 'SETTINGS',
  CurrentUser = 'CURRENT_USER',
  UserIdeas = 'USER_IDEAS',
  UserProfile = 'USER_PROFILE',
  Courses = 'COURSES',
  Teasers = 'TEASERS',
  Permissions = 'PERMISSIONS',
  Users = 'USERS',
  UserShorts = 'USER_SHORTS',
  UserGroup = 'USER_GROUP',
  UserGroups = 'USER_GROUPS',
}

export interface IMe {
  me: IUser;
  onBehalfOf?: IUser;
}

export interface IUser {
  id: Uuid;
  avatar?: IBlob;
  givenName?: string;
  familyName?: string;
  emailAddress: string;
  permissions: IUserPermission[];
  approved: boolean;
  touAcceptedAt?: Date;
  ipConsentAcceptedAt?: Date;
  privacyPolicyAcceptedAt?: Date;
  createdAt: Date;
}

export interface IUserShort {
  id: Uuid;
  avatar?: IBlob;
  givenName?: string;
  familyName?: string;
  emailAddress: string;
}

export interface IUserProfile {
  id: Uuid;
  avatar?: IBlob;
  givenName?: string;
  familyName?: string;
  role?: string;
  organization?: string;
  bio?: string;
  location?: string;
}

export enum IUserPermission {
  CanSeeAdminUI = 'CAN_SEE_ADMIN_UI',
  CanCreateChallenge = 'CAN_CREATE_CHALLENGE',
  CanUpdateAllChallenges = 'CAN_UPDATE_ALL_CHALLENGES',
  CanDeleteAllChallenges = 'CAN_DELETE_ALL_CHALLENGES',
  CanMemo = 'CAN_MEMO',
  CanCreateRestrictedChallenges = 'CAN_CREATE_RESTRICTED_CHALLENGES',
  CanSubmitToRestrictedChallenges = 'CAN_SUBMIT_TO_RESTRICTED_CHALLENGES',
  CanSeeSubmissionsForRestrictedChallenges = 'CAN_SEE_SUBMISSIONS_FOR_RESTRICTED_CHALLENGES',
  CanApproveUsers = 'CAN_APPROVE_USERS',
  CanTransitionSubmissionPhases = 'CAN_TRANSITION_SUBMISSION_PHASES',
  CanActOnBehalfOfOtherUser = 'CAN_ACT_ON_BEHALF_OF_OTHER_USERS', // todo: change to 'CanActOnBehalfOfOtherUsers'
  CanManageTags = 'CAN_MANAGE_TAGS',
  CanSeeRestrictedVisibilitySubmissions = 'CAN_SEE_RESTRICTED_VISIBILITY_SUBMISSIONS',
  CanChangeIdeaVisibility = 'CAN_CHANGE_IDEA_VISIBILITY',
  CanSeeEvaluationForms = 'CAN_SEE_EVALUATION_FORMS',
  CanSeeEvaluationDetails = 'CAN_SEE_EVALUATION_DETAILS',
  CanEvaluateSubmissions = 'CAN_EVALUATE_SUBMISSIONS',
  CanChangeUserPermissions = 'CAN_CHANGE_USER_PERMISSIONS',
  CanListUserPermissions = 'CAN_LIST_USER_PERMISSIONS',
  CanRunAdvancedUserSearch = 'CAN_RUN_ADVANCED_USER_SEARCH',
  CanManageUserGroups = 'CAN_MANAGE_USER_GROUPS',
}

export interface IPermissionInfo {
  name: IUserPermission;
  description: string;
}

export interface IPermissionUsersData {
  [key: Uuid]: IUserPermission[];
}

export type IUsers = IListResponse<IUser>;

export type IUserShorts = IListResponse<IUserShort>;

export interface IUserSetting {
  id: Uuid;
  name: string;
  value: string;
  createdAt: Date;
}

export interface IUserIdentification {
  userId?: Uuid;
  userEmail?: string;
}

export interface IGetUserShortsFiltersRequest {
  // indexable types required to programmatically access interface values
  // https://www.typescriptlang.org/docs/handbook/interfaces.html#indexable-types
  [index: string]: string | undefined;
  match: string;
  approved?: string;
  touAccepted?: string;
  ppAccepted?: string;
  ipConsentAccepted?: string;
  createdAfter?: string;
  createdBefore?: string;
}

export interface IGetUserShortsFilters {
  match: string;
  approved: string | undefined;
  touAccepted: string | undefined;
  ppAccepted: string | undefined;
  ipConsentAccepted: string | undefined;
  createdAfter: Date | undefined;
  createdBefore: Date | undefined;
}

export interface IUserGroup {
  id: Uuid;
  name: string;
  description?: string;
  code: string;
  numberOfUsers: number;
  canAccessChallenge: Uuid[];
  createdAt: Date;
}

export interface IUserGroupResponse {
  userGroup: IUserGroup;
  unassignedEmails: string[];
}

export type IUserGroups = IListResponse<IUserGroup>;

export enum IIdeaVisibility {
  All = 'ALL',
  PermissionOnly = 'PERMISSION_ONLY',
}

export enum IModalType {
  Error = 'Error',
  ChangeIdeaVisibility = 'ChangeIdeaVisibility',
  ChangeIdeaPhase = 'ChangeIdeaPhase',
}

export interface IChallenge {
  id: Uuid;
  title: string;
  content: string;
  image: IBlob;
  restrictSubmissions: boolean;
  startsAt?: Date;
  endsAt?: Date;
  publishedAt?: Date;
  createdAt: Date;
  forms: IForm[];
  evaluationForms?: IForm[];
  defaultSubmissionVisibility: IIdeaVisibility;
  publishedSubmissions: number;
}

export interface IChallengeData {
  title: string;
  content: string;
  startsAt?: Date | null;
  endsAt?: Date | null;
  image?: IBlob;
  restrictSubmissions: boolean;
  defaultSubmissionVisibility: IIdeaVisibility;
  publishedAt?: Date | null;
}

export type IChallenges = IListResponse<IChallenge>;

export interface IForm {
  id: Uuid;
  fields: (ITextAreaFormField | IRangeFormField | IBlobFormField | ICheckBoxFormField)[];
  challengeId: Uuid;
  title: string;
  shortDescription: string;
  longDescription?: string;
  type: IFormType;
  createdAt: Date;
}

export enum IFormType {
  Submission = 'SUBMISSION',
  Evaluation = 'EVALUATION',
}

export interface ICreateFormData {
  challengeId: Uuid;
  title: string;
  type: IFormType;
  shortDescription: string;
  longDescription?: string;
}

export interface IUpdateFormData {
  title: string;
  shortDescription: string;
  longDescription?: string;
}

export interface IFormField {
  id: Uuid;
  description?: string;
  createdAt: Date;
  formId: Uuid;
  label: string;
  isRequired: boolean;
  type: IFormFieldType;
}

export enum IFormFieldType {
  TextArea = 'TEXTAREA',
  Range = 'RANGE',
  Blob = 'BLOB',
  CheckBox = 'CHECKBOX',
}

export enum IBlobType {
  Video = 'VIDEO',
  Image = 'IMAGE',
  Document = 'DOCUMENT',
}

export interface ITextAreaFormField extends IFormField {
  rows: number;
  placeHolder: string;
}

export interface IRangeFormField extends IFormField {
  from: number;
  to: number;
  fromLabel?: string;
  toLabel?: string;
  fieldDefault?: number;
}

export interface IBlobFormField extends IFormField {
  blobType: IBlobType;
}

export interface ICheckBoxFormField extends IFormField {
  defaultChecked: boolean;
}

export interface IFormFieldData {
  formId: Uuid;
  type: IFormFieldType;
  label: string;
  description?: string;
  isRequired: boolean;
}

export interface ICreateTextAreaFormFieldData extends IFormFieldData {
  placeHolder?: string;
  rows: number;
}

export interface ICreateRangeFormFieldData extends IFormFieldData {
  from: number;
  to: number;
  fieldDefault?: number;
  fromLabel?: string;
  toLabel?: string;
}

export interface ICreateCheckboxFormFieldData extends IFormFieldData {
  defaultChecked: boolean;
}

export interface ICreateBlobFormFieldData extends IFormFieldData {
  blobType: IBlobType;
}

export enum ISubmissionPhaseFilter {
  All = 'ALL',
  FoldedOnly = 'FOLDED_ONLY',
  SelectedOnly = 'SELECTED_ONLY',
  InitialOnly = 'INITIAL_ONLY',
  PitchedOnly = 'PITCHED_ONLY',
}

export enum ISubmissionFindOrder {
  Interesting = 'INTERESTING',
  LastUpdated = 'LAST_UPDATED',
}

export enum ISubmissionFindDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface ISubmissionsFilters {
  author?: Uuid;
  authorAlias?: 'ME';
  // TODO: Support filter by multiple challenges
  challenge?: Uuid;
  status?: IIdeaStatus;
  includeRestrictedChallenges: boolean;
  includeInvisibleSubmissions: boolean;
  includeEvaluations: boolean;
  phase: ISubmissionPhaseFilter;
  order: ISubmissionFindOrder;
  direction: ISubmissionFindDirection;
  tag?: string;
}

export interface ISubmission {
  idea: IIdea;
  fields: ISubmissionField[];
  attachments: IAttachmentShort[];
}

export interface ISubmissionField {
  fieldId: Uuid;
  formId: Uuid;
  value: any;
  createdAt: Date;
  updatedAt: Date;
}

export interface IAttachmentShort {
  id: Uuid;
  title: string;
  description?: string;
  blob: IBlob;
  authorId: Uuid;
}

export interface ICreateSubmissionData {
  name: string;
  description?: string;
  image?: IBlob;
  challengeId: Uuid;
}

export interface IUpdateSubmissionData {
  idea?: {
    name: string;
    description?: string;
    image?: IBlob;
  };
  status?: IIdeaStatus;
  fields?: Record<string, any>;
  newAttachments?: IUpdateAttachmentData[];
  deleteAttachments?: string[];
}

export interface IUpdateSubmissionPitchData {
  prepitchFeedback?: string;
  pitchIOComment?: string;
  pitchFeedback?: string;
  ioTestimonial?: string;
  pitchVideoAttachmentId?: Uuid;
  pitchDeckAttachmentId?: Uuid;
}

export interface IAttachment {
  title: string;
  description?: string;
  blob: IBlob;
}

export interface IUpdateAttachmentData {
  title: string;
  description?: string;
  blob: IBlob;
}

export interface ITag {
  id: Uuid;
  name: string;
  description?: string;
  createdAt: Date;
}

export type ITagList = IListResponse<ITag>;

export enum IDefaultSubmissionTag {
  Spotlight = 'spotlight',
  MoonOriginal = 'moon-original',
}

export interface IUpdateSubmissionResults {
  newFields: string[];
  updatedFields: string[];
  deletedFields: string[];
  newAttachments: string[];
  deletedAttachments: string[];
  baseIdeaUpdated: boolean;
}

export interface IIdea {
  id: Uuid;
  author: IUserShort;
  name: string;
  description?: string;
  image?: IBlob;
  status: IIdeaStatus;
  phase: IIdeaPhase;
  challengeId: Uuid;
  challengeVisibilityDefault: IIdeaVisibility;
  visibilityOverride?: IIdeaVisibility;
  isVisibleToNonAuthors: boolean;
  commentCount: number;
  viewsCount: number;
  folded: boolean;
  foldedAt?: Date;
  selected: boolean;
  selectedAt?: Date;
  pitched: boolean;
  pitchedAt?: Date;
  publishedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  prepitchFeedback?: string;
  pitchIOComment?: string;
  pitchFeedback?: string;
  ioTestimonial?: string;
  pitchVideoAttachmentId?: Uuid;
  pitchDeckAttachmentId?: Uuid;
  tags: string[];
  evaluations?: IEvaluation[];
}

export type IIdeas = IListResponse<IIdea>;

export enum IIdeaPhase {
  Initial = 'INITIAL',
  Folded = 'FOLDED',
  Pitched = 'PITCHED',
  Selected = 'SELECTED',
}

export enum IIdeaStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export interface IIdeaComment {
  id: Uuid;
  content: string;
  user: IUserShort;
  submissionId: Uuid;
  createdAt: Date;
}

export type IIdeaComments = IListResponse<IIdeaComment>;

export interface IEvaluationDetail {
  evaluation: IEvaluation;
  fields: ISubmissionField[];
}

export interface ICreateEvaluationData {
  submissionId: Uuid;
}

export interface IUpdateEvaluationData {
  fields: Record<string, any>;
}

export interface IEvaluationUpdateResults {
  newFields: Uuid[];
  updatedFields: Uuid[];
  deletedFields: Uuid[];
}

export interface IEvaluation {
  id: Uuid;
  author: IUserShort;
  submissionId: Uuid;
  createdAt: Date;
}

export interface IMemoCreateData {
  title: string;
  content: string;
  audience: IMemoAudience;
  submissionId: Uuid;
}

export enum IMemoAudience {
  Panel = 'PANEL',
  Owners = 'OWNERS',
}

export interface IMemo {
  id: Uuid;
  title: string;
  content: string;
  audience: IMemoAudience;
  user: IUserShort;
  submissionId: Uuid;
  // TODO: Add the support to reaction count
  // reactionSummary?: IReactCount;
  seenAt?: Date;
  createdAt: Date;
}

export type IMemoList = IListResponse<IMemo>;

export interface IBaseAction {
  type: string;
  payload?: any;
}

export interface IMainAppState {
  challenge: IChallengeState;
  common: ICommonState;
}

export interface ICommonState {
  isLoading: boolean;
  globalMessageModal: IMessageInfo;
}

export interface IChallengeState {
  challengeList: IChallenge[];
}

export interface IQuestionItem {
  id: number;
  item: JsxElement;
}

export interface IUrlParams {
  userId: Uuid;
  challengeId: Uuid;
  formId: Uuid;
  submissionId: Uuid;
  ideaId: Uuid;
  ideaName: string;
  evaluationId: Uuid;
  webinarId: Uuid;
  visionBlogId: Uuid;
  ventureNewsId: Uuid;
  courseId: Uuid;
  userGroupId: Uuid;
}

export interface IUploadRequest {
  originType: string;
  originId: string | Uuid;
}

export interface IUploadResponse {
  filestackKey: string;
  location: string;
  container: string;
  region: string;
  path: string;
  tags: {
    user: string;
    field: string;
  };
}

export interface IError {
  status: number;
  // TODO: Ask seba on what are the possible return error data type?
  // The known error data type is object or []
  data: { message: string } | [];
}

export interface IMessageInfo {
  showMessageModal?: boolean;
  messageModalTitle?: string;
  messageModalContent?: string;
  messageModalType?: IModalType;
  messageModalButtonText?: string;
  messageModalButtonAction?: (() => void) | null;
}

export interface IForSearchKeyValue {
  array: any[];
  searchKey: string;
  searchValue: any;
}

export enum TAGS {
  New = 'NEW',
  MostCommented = 'MOST_COMMENTED',
}

interface IListResponse<DataType> {
  meta: { page: { rangeTruncated?: boolean } };
  links: { prev?: string; next?: string };
  data: DataType[];
}

export interface IBlob {
  url: string;
  filename?: string;
  container?: string;
  handle?: string;
  key?: string;
  mimetype?: string;
  originalPath?: string;
  size?: number;
  source?: string;
  status?: string;
  uploadId?: string;
  uploadTags?: Record<string, string>;
}

export enum IBlobOrigin {
  Field = 'FIELD',
  Idea = 'IDEA',
  Challenge = 'CHALLENGE',
}

export interface ILocationState {
  from: { pathname: string };
}

export interface IHasSeenRequestData {
  ideas?: Uuid[];
  comments?: Uuid[];
  memos?: Uuid[];
  maCourses?: Uuid[];
}

export enum FileStackMaxSize {
  Image = 1024 * 1024 * 10, // 10MB
  Video = 1024 * 1024 * 100, // 100MB
  Document = 1024 * 1024 * 10, // 10MB
}

export enum FileStackAcceptedFileType {
  Pdf = '.pdf',
  Ppt = '.ppt',
  Pptx = '.pptx',
  Doc = '.doc',
  Docx = '.docx',
}

export enum FileStackAcceptedImageType {
  All = 'image/*',
}

export enum FileStackAcceptedVideoType {
  Mp4 = 'video/mp4',
}

export enum FileStackFromSource {
  LocalFileSystem = 'local_file_system',
  Url = 'url',
  Unsplash = 'unsplash',
  GoogleDrive = 'googledrive',
}

export interface IFileStackOptions {
  originType: IBlobOrigin;
  originId: string | Uuid;
  onFileUploadFinished: PickerFileCallback;
  onFileSelected: PickerFileCallback;
  accept?:
    | FileStackAcceptedImageType
    | FileStackAcceptedVideoType
    | FileStackAcceptedFileType
    | FileStackAcceptedImageType[]
    | FileStackAcceptedVideoType[]
    | FileStackAcceptedFileType[]
    | Partial<FileStackAcceptedImageType | FileStackAcceptedVideoType | FileStackAcceptedFileType>[];
  maxSize?: FileStackMaxSize;
  fromSources?: Partial<
    | FileStackFromSource.LocalFileSystem
    | FileStackFromSource.Url
    | FileStackFromSource.Unsplash
    | FileStackFromSource.GoogleDrive
  >[];
}

export interface IUserGroupFormData {
  name: string;
  description?: string;
  code: string;
  // TODO: make it set?
  userEmails: string[];
}

export interface IUserGroupFormInputs {
  name: string;
  description?: string;
  code: string;
  userEmails?: string;
}

export interface IUserPreapprovalFormInputs {
  name: string;
  description?: string;
  code: string;
  userEmails?: string;
}

export interface ISubmissionFormData {
  [key: Uuid]: string | number | boolean;
}

export interface IChallengeFormData {
  title: string;
  content: string;
  restrictSubmissions: boolean;
  defaultSubmissionVisibility: ISelectOption<IIdeaVisibility>;
  startsAt?: string;
  endsAt?: string;
  image?: IBlob;
}

export interface ISelectOption<ValueType> {
  label: string;
  value: ValueType;
}

export interface IFormFormData {
  title: string;
  shortDescription: string;
  longDescription?: string;
  type: ISelectOption<IFormType>;
}

export interface IFormFieldFormInputs {
  label: string;
  description: string;
  isRequired: boolean;
}

export interface IFormFieldTextAreaFormInputs extends IFormFieldFormInputs {
  rows: number;
  placeHolder?: string;
}

export interface IFormFieldRangeFormInputs extends IFormFieldFormInputs {
  from: number;
  to: number;
  fieldDefault?: number;
  fromLabel?: string;
  toLabel?: string;
}

export interface IFormFieldCheckboxFormInputs extends IFormFieldFormInputs {
  defaultChecked: boolean;
}

export interface IFormFieldBlobFormInputs extends IFormFieldFormInputs {
  blobType: ISelectOption<IBlobType>;
}
