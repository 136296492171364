import React, { memo } from 'react';
import styles from './index.module.scss';

interface IProps {
  embedUrl?: string;
  title?: string;
}

const VideoBanner = ({ embedUrl, title = 'video' }: IProps) => {
  return (
    <div className={styles.wrapper}>
      <iframe
        title={title}
        className={styles.iframe}
        src={embedUrl}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
      ></iframe>
    </div>
  );
};

export default memo(VideoBanner);
