import { useForm } from 'react-hook-form';
import { createCheckboxFormField } from '../../util/api';
import { queryClient } from '../../util/client';
import { IForm, IFormFieldCheckboxFormInputs, IFormFieldType, ReactQueryKey } from '../../util/types';
import { Button } from '../Button';
import HookFormSwitch from '../HookFormSwitch';
import HookFormTextInput from '../HookFormTextInput';
import styles from './index.module.scss';

interface IProps {
  form: IForm;
  onSubmit?: () => void;
}

const CheckboxFormFieldForm = ({ form, onSubmit }: IProps) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toSubmit = async (data: IFormFieldCheckboxFormInputs) => {
    const { label, description, isRequired, defaultChecked } = data;

    await createCheckboxFormField({
      formId: form.id,
      type: IFormFieldType.CheckBox,
      label,
      description: description || undefined,
      isRequired: Boolean(isRequired),
      defaultChecked: Boolean(defaultChecked),
    })
      .then(() => {
        queryClient.invalidateQueries([ReactQueryKey.Challenge, { challengeId: form.challengeId }]);
        onSubmit && onSubmit();
      })
      .catch((e: any) => console.error(e));
  };

  return (
    <form data-testid="checkbox-field-form" className={styles.form}>
      <HookFormTextInput
        name="label"
        label="Label"
        rules={{ required: 'label is required' }}
        register={register}
        errors={errors}
        dataTestId="checkbox-label-input"
      />
      <HookFormTextInput
        name="description"
        label="Description"
        register={register}
        errors={errors}
        dataTestId="checkbox-description-input"
      />
      <HookFormSwitch
        name="isRequired"
        label="Required"
        control={control}
        errors={errors}
        dataTestId="checkbox-isRequired-switch"
      />
      <HookFormSwitch
        name="defaultChecked"
        label="Default checked"
        control={control}
        errors={errors}
        dataTestId="checkbox-defaultChecked-switch"
      />
      <Button variant="black" onClick={handleSubmit(toSubmit)} className={styles.addFormFieldButton}>
        Add form field
      </Button>
    </form>
  );
};

export default CheckboxFormFieldForm;
