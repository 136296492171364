import { IForm, ISubmission, Uuid } from '../../util/types';
import SubmissionFieldValue from '../SubmissionFieldValue';
import styles from './index.module.scss';

interface IProps {
  submission: ISubmission;
  form: IForm;
}

const FormSubmissionDetail = ({ submission, form }: IProps) => {
  const submissionFields = submission.fields.filter((field) => field.formId === form.id);

  const findSubmissionField = (formFieldId: Uuid) => submissionFields.find((field) => field.fieldId === formFieldId);

  return (
    <div className={styles.container}>
      {form.fields.map((formField) => {
        const submissionField = findSubmissionField(formField.id);

        return submissionField ? (
          <div key={formField.id} className={styles.field}>
            <h3 className={styles.label}>{formField.label}</h3>
            <SubmissionFieldValue field={formField} value={submissionField.value} />
          </div>
        ) : null;
      })}
    </div>
  );
};

export default FormSubmissionDetail;
