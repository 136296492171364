import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import MDEditor from '@uiw/react-md-editor';
import PageWrapper from '../../components/PageWrapper';
import LanguageSwitch from '../../components/LanguageSwitch';
import styles from './index.module.scss';

const FAQPage = () => {
  const { t } = useTranslation(['faq']);

  return (
    <PageWrapper>
      <LanguageSwitch />
      <div className={styles.title}>{t('faq:title')}</div>
      <MDEditor.Markdown className={styles.longParagraph} source={t('faq:content')} />
    </PageWrapper>
  );
};

export default memo(FAQPage);
