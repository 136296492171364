import styles from './index.module.scss';

interface IProps {
  text: string;
}

const AdminPageTitle = ({ text }: IProps) => {
  return <h1 className={styles.text}>{text}</h1>;
};

export default AdminPageTitle;
