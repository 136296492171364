import { useState } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { createUserGroup } from '../../util/api';
import { queryClient } from '../../util/client';
import { IUserGroupFormInputs, IUserPermission, ReactQueryKey } from '../../util/types';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import AdminPageTitle from '../../components/AdminPageTitle';
import { Button } from '../../components/Button';
import UserGroupCreateForm from '../../components/UserGroupCreateForm';
import styles from './index.module.scss';
import { useError } from '../../hooks/useError';
import Modal from '../../components/Modal';

const AdminUserGroupCreatePage = () => {
  const history = useHistory();
  const { handleError } = useError();
  const { hasPermission } = useCurrentUser();
  const [unassignedEmails, setUnassignedEmails] = useState<string[]>([]);

  const createUserGroupForm = useForm({ reValidateMode: 'onSubmit' });

  if (!hasPermission(IUserPermission.CanManageUserGroups)) {
    // TODO: show modal to explain no permission and redirect to user groups page
    return <div>No permission to manage user groups!</div>;
  }

  const toCreateUserGroup = async (data: IUserGroupFormInputs) => {
    // TODO: add a useServerErrorHandling hook to handle server request error in a later PR
    const { name, description, code, userEmails } = data;
    await createUserGroup({
      name,
      description,
      code,
      userEmails: userEmails ? userEmails.split(',').map((email: string) => email.trim()) : [],
    })
      .then((res) => {
        if (res.unassignedEmails.length > 0) {
          setUnassignedEmails(res.unassignedEmails);
        } else {
          completeTransaction();
        }
      })
      .catch(handleError);
  };

  const completeTransaction = () => {
    setUnassignedEmails([]);
    queryClient.invalidateQueries(ReactQueryKey.UserGroups);
    history.push('/admin/user-groups');
  };

  return (
    <div className={styles.wrapper}>
      <AdminPageTitle text="User Groups Management" />
      <Button className={styles.button} variant="black" onClick={createUserGroupForm.handleSubmit(toCreateUserGroup)}>
        Create User Group
      </Button>
      <div className={styles.container}>
        <UserGroupCreateForm form={createUserGroupForm} />
      </div>

      <Modal isVisible={unassignedEmails.length > 0} dismiss={completeTransaction}>
        <div>
          <div>{`${unassignedEmails.length} unassigned emails: `}</div>
          {unassignedEmails.map((email) => (
            <li key={email}>{email}</li>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default AdminUserGroupCreatePage;
