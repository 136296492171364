import { useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { ReactComponent as ThreeDotsIcon } from '../../assets/icons/threeDots.svg';
import { formatDate } from '../../util/helpers';
import { IMemo, ReactQueryKey } from '../../util/types';
import { deleteMemo } from '../../util/api';
import { queryClient } from '../../util/client';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import ProfileAvatar from '../ProfileAvatar';
import AlertModal from '../AlertModal';
import { useError } from '../../hooks/useError';
import RichTextPreview from '../RichTextPreview';
import styles from './index.module.scss';

interface IProps {
  memo: IMemo;
}

const Memo = ({ memo }: IProps) => {
  const { data: currentUser } = useCurrentUser();
  const { handleError } = useError();
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false);
  const optionsRef = useRef(null);
  useOnClickOutside(optionsRef, () => setIsOptionsVisible(false));

  const userName =
    memo.user.givenName && memo.user.familyName
      ? `${memo.user.givenName} ${memo.user.familyName}`
      : memo.user.givenName || memo.user.familyName;
  const isCurrentUserMemo = currentUser?.id === memo.user.id;

  const handleShowAlertModal = () => {
    setIsOptionsVisible(false);
    setIsAlertModalVisible(true);
  };

  const handleDeleteMemo = async () =>
    await deleteMemo(memo.id)
      .then(() => queryClient.invalidateQueries(ReactQueryKey.Memos))
      .catch(handleError);

  return (
    <>
      <div className={styles.memo}>
        <div className={styles.memo__header}>
          <div className={styles.memo__profile}>
            <ProfileAvatar avatarUrl={memo.user.avatar?.url} firstName={memo.user.givenName} />
            <div className={styles.memo__user}>
              {userName && <span>{userName}</span>}
              <span className={styles.memo__emailAddress}>{memo.user.emailAddress}</span>
            </div>
          </div>
        </div>
        <div className={styles.memo__options}>
          {isCurrentUserMemo && (
            <ThreeDotsIcon className={styles.memo_icon} onClick={() => setIsOptionsVisible(true)} />
          )}
          {isOptionsVisible && (
            <div ref={optionsRef} className={styles.memo__optionsMenu} onClick={handleShowAlertModal}>
              Delete memo
            </div>
          )}
        </div>
        <RichTextPreview className={styles.memo__content} value={memo.content} />
        <div className={styles.memo__createdAt}>{formatDate(memo.createdAt)}</div>
      </div>

      <AlertModal
        title="Delete your memo"
        body="Are you sure you want to delete this memo?"
        isVisible={isAlertModalVisible}
        cancel={{ text: 'Cancel', onClick: () => setIsAlertModalVisible(false) }}
        confirm={{ text: 'Delete', onClick: handleDeleteMemo }}
      />
    </>
  );
};

export default Memo;
