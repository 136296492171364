import { Link } from 'react-router-dom';
import AdminChallenges from '../../components/AdminChallenges';
import AdminPageTitle from '../../components/AdminPageTitle';
import { Button } from '../../components/Button';
import styles from './index.module.scss';

const AdminChallengesPage = () => {
  return (
    <div className={styles.wrapper}>
      <AdminPageTitle text="Challenges Management" />
      <Link className={styles.createChallengeLink} to="/admin/challenges/create">
        <Button className={styles.createChallengeButton} variant="black">
          New Challenge
        </Button>
      </Link>
      <div className={styles.container}>
        <AdminChallenges />
      </div>
    </div>
  );
};

export default AdminChallengesPage;
