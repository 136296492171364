import { Link } from 'react-router-dom';
import { ideaUrl } from '../../util/helpers';
import { IIdea } from '../../util/types';
import IdeaCard from '../IdeaCard';
import styles from './index.module.scss';

interface IProps {
  ideas: IIdea[];
}

const IdeasGrid = ({ ideas }: IProps) => {
  return (
    <div className={styles.ideasGrid}>
      {ideas.map((idea) => (
        <Link to={ideaUrl(idea)} key={idea.id} data-testid="idea">
          <IdeaCard idea={idea} />
        </Link>
      ))}
    </div>
  );
};

export default IdeasGrid;
