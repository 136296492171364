import { useEffect, useState } from 'react';
import { ISubmissionsFilters, Uuid } from '../util/types';
import { useHistory } from 'react-router-dom';
import useQueryParams from './useQueryParams';

interface IProps {
  initialFilters: ISubmissionsFilters;
  defaultPath: string;
}

const useChallengeFilters = ({ initialFilters, defaultPath }: IProps) => {
  const [filters, setFilters] = useState<ISubmissionsFilters>(initialFilters);
  const history = useHistory();
  const queryParams = useQueryParams();

  useEffect(() => {
    const challenge = queryParams.get('challenge');

    if (challenge) {
      setFilters({ ...filters, challenge: challenge as Uuid });
      history.replace(`?challenge=${filters.challenge}`);
    }
  }, []);

  useEffect(() => {
    if (filters.challenge) {
      history.replace(`?challenge=${filters.challenge}`);
    } else {
      history.replace(defaultPath);
    }
  }, [filters]);

  return [filters, setFilters] as const;
};

export default useChallengeFilters;
