import React, { memo, ReactNode } from 'react';
import UserNavbar from '../../components/UserNavbar';
import Footer from '../../components/Footer';
import styles from './index.module.scss';

interface IProps {
  children: ReactNode;
}

const SubmissionLayout = ({ children }: IProps) => {
  return (
    <div className={styles.wrapper}>
      <UserNavbar />
      <div className={styles.mainContent}>{children}</div>
      <Footer />
    </div>
  );
};

export default memo(SubmissionLayout);
