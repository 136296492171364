import ReactSelect from 'react-select';
import { IDefaultSubmissionTag } from '../../util/types';

interface IProps {
  name: string;
  inputId: string;
  options: any;
  placeholder: string;
  value?: any;
  defaultValue?: any;
  isClearable?: boolean;
  isMulti?: boolean;
  width?: string;
  onChange?: (value: any) => void;
}

// https://react-select.com/styles#styles
// Styling with styles object is recommended by the library
// Make sure to keep in sync with the properties in the variable.scss
const Select = ({
  name,
  inputId,
  options,
  placeholder,
  value,
  onChange,
  defaultValue,
  isClearable = true,
  isMulti = false,
  width,
  ...props
}: IProps) => {
  const customStyles = {
    container: (styles: any) => ({ ...styles, width: width ?? '20rem' }),
    control: (styles: any) => ({
      ...styles,
      padding: '0.2rem 0.65rem;',
      borderColor: '#e5e7eb',
      ':hover': { ...styles[':hover'], borderColor: '#d1d5db' },
      ':focus-within': { ...styles[':focus-within'], borderColor: '#000', boxShadow: 'none' },
    }),
    option: (styles: any, { isFocused }: any) => ({
      ...styles,
      cursor: 'pointer',
      color: isFocused ? '#fff' : '#000',
      backgroundColor: isFocused ? '#000' : '#fff',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    multiValueRemove: (styles: any) => ({ ...styles, ':hover': { color: '#828282' } }),
  };

  const customTheme = (theme: any) => ({ ...theme, borderRadius: '0.25rem' });

  return (
    <ReactSelect
      {...props}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      name={name}
      inputId={inputId}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      isClearable={isClearable}
      styles={customStyles}
      theme={customTheme}
    />
  );
};

export default Select;
