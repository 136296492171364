import React, { memo, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import styles from './index.module.scss';

const NotFound = () => {
  const history = useHistory();

  useEffect(() => {
    history.replace('/404');
  }, []);

  return (
    <div className={styles.wrapper}>
      <NavLink exact to="/home">
        <div className={styles.brand}>SMAP</div>
      </NavLink>
      <div className={styles.container}>
        <p className={styles.messageTitle}>Page Not Found - Sorry!</p>
      </div>
    </div>
  );
};

export default memo(NotFound);
