import { Link } from 'react-router-dom';
import styles from './index.module.scss';

const Footer = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Link to="/terms-of-use" className={styles.link} target="_blank">
          Terms of Use
        </Link>
        <Link to="/privacy-policy" className={styles.link} target="_blank">
          Privacy Policy
        </Link>
        <Link to="/cookie-preferences" className={styles.link} target="_blank">
          Cookie Policy
        </Link>
        <Link to="/faq" className={styles.link} target="_blank">
          FAQ
        </Link>
      </div>
    </div>
  );
};

export default Footer;
