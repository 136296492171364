import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import classnames from 'classnames';
import styles from './index.module.scss';

interface IProps {
  value: string;
  className?: string;
  isSanitized?: boolean;
}

// RichTextPreview sanitizes potential malicious user input by default.
// Be sure to keep the isSanitized prop in sync with RichTextInput for consistency.
// For more information: https://github.com/rehypejs/rehype-sanitize
const RichTextPreview = ({ value, className, isSanitized = true, ...props }: IProps) => {
  const sanitize = isSanitized ? [rehypeSanitize] : [];
  const rehypePlugins = [...sanitize];

  return (
    <div className={classnames(styles.richTextPreview, className)} {...props}>
      <MDEditor.Markdown
        linkTarget="_blank"
        className={styles.richTextPreview__markdown}
        source={value}
        rehypePlugins={rehypePlugins}
      />
    </div>
  );
};

export default RichTextPreview;
