import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import MDEditor from '@uiw/react-md-editor';
import PageWrapper from '../../components/PageWrapper';
import styles from './index.module.scss';
import LanguageSwitch from '../../components/LanguageSwitch';

const PrivacyPolicyPage = () => {
  const { t } = useTranslation(['privacyPolicy']);

  return (
    <PageWrapper>
      {/* TODO: Disable because we don't have JA version yet */}
      {/* <LanguageSwitch /> */}
      <div className={styles.title}>{t('privacyPolicy:title')}</div>
      <MDEditor.Markdown className={styles.longParagraph} source={t('privacyPolicy:content')} />
    </PageWrapper>
  );
};

export default memo(PrivacyPolicyPage);
