import React, { memo, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { IUserPermission } from '../../util/types';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import UserNavbarMobileMenu from '../UserNavbarMobileMenu';
import ProfileAvatar from '../ProfileAvatar';
import UserProfileMenu from '../UserProfileMenu';
import MyIdeaNavItem from '../MyIdeaNavItem';
import styles from './index.module.scss';

const UserNavbar = () => {
  const { data: currentUser } = useCurrentUser();
  const [showMobileNavbar, setShowMobileNavbar] = useState<boolean>(false);
  const [showUserProfileMenu, setShowUserProfileMenu] = useState<boolean>(false);

  const canSeeAdmin = Boolean(currentUser?.permissions?.includes(IUserPermission.CanSeeAdminUI));

  return (
    <div className={styles.wrapper} data-testid="navbar" data-cy="navbar">
      <div className={styles.container}>
        <div className={styles.containerDesktop}>
          <div className={styles.mainNavigation}>
            <NavLink exact to="/home">
              <div className={styles.brand}>smap</div>
            </NavLink>
            <div className={styles.ideasAndProfileContainer}>
              <MyIdeaNavItem />
              {currentUser && (
                <div className={styles.userIconContainer}>
                  <div
                    className={styles.profileAvatarWrapper}
                    onClick={() => setShowUserProfileMenu(!showUserProfileMenu)}
                  >
                    <ProfileAvatar small avatarUrl={currentUser?.avatar?.url} firstName={currentUser?.givenName} />
                  </div>
                  {showUserProfileMenu && (
                    <UserProfileMenu canSeeAdmin={canSeeAdmin} dismiss={() => setShowUserProfileMenu(false)} />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles.subNavigation} data-testid="sub-navigation">
            <NavLink
              data-cy="open-challenge-nav-link"
              className={styles.navItem}
              activeClassName={styles.activeNavItem}
              to="/challenges"
            >
              Submit an Idea
            </NavLink>
            <NavLink className={styles.navItem} activeClassName={styles.activeNavItem} to="/ideas">
              Idea Library
            </NavLink>
            <NavLink className={styles.navItem} activeClassName={styles.activeNavItem} exact to="/organization-ideas">
              Transform your Org
            </NavLink>
          </div>
        </div>
        <div className={styles.containerMobile}>
          <Link to="/" onClick={() => setShowMobileNavbar(false)}>
            <div className={styles.brand}>smap</div>
          </Link>
          <div onClick={() => setShowMobileNavbar(!showMobileNavbar)}>
            <MenuIcon />
          </div>
        </div>
      </div>
      {showMobileNavbar && <UserNavbarMobileMenu dismiss={() => setShowMobileNavbar(false)} />}
    </div>
  );
};

export default memo(UserNavbar);
