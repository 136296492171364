import { useState } from 'react';
import { Uuid } from '../../util/types';
import { Button } from '../Button';
import MemoCreateForm from '../MemoCreateForm';
import Modal from '../Modal';

interface IProps {
  submissionId: Uuid;
}

const CreateMemo = ({ submissionId }: IProps) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Button variant="black" onClick={() => setIsVisible(true)}>
        Add a Memo
      </Button>
      <Modal isVisible={isVisible} dismiss={() => setIsVisible(false)}>
        <MemoCreateForm submissionId={submissionId} onSubmit={() => setIsVisible(false)} />
      </Modal>
    </>
  );
};

export default CreateMemo;
