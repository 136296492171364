import { useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg';
import { ReactComponent as PublishIcon } from '../../assets/icons/published.svg';
import { ReactComponent as LeftRightIcon } from '../../assets/icons/leftRight.svg';
import { IMainAppState, IModalType } from '../../util/types';
import { closeMessageModal } from '../../redux/actions';
import styles from './index.module.scss';

const GlobalMessageModal = () => {
  const dispatch = useDispatch();

  const {
    showMessageModal,
    messageModalTitle,
    messageModalContent,
    messageModalType,
    messageModalButtonText,
    messageModalButtonAction,
  } = useSelector((state: IMainAppState) => state.common.globalMessageModal);

  const handleCloseModal = useCallback(() => {
    dispatch(closeMessageModal());
    if (messageModalButtonAction) {
      messageModalButtonAction();
    }
  }, []);

  const handleModalButtonClick = useCallback(() => {
    if (messageModalButtonAction) {
      messageModalButtonAction();
    }

    handleCloseModal();
  }, [messageModalButtonAction]);

  const ErrorModal = () => (
    <Modal contentClassName={styles.content} centered show={showMessageModal} onHide={handleCloseModal}>
      <AlertIcon className={styles.icon} />
      <Modal.Title className={styles.title}>{messageModalTitle}</Modal.Title>
      <Modal.Body data-cy="global-modal-message" className={styles.body}>
        {messageModalContent}
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <Button className={styles.button} variant={messageModalType} onClick={handleModalButtonClick}>
          {messageModalButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const ChangeIdeaVisibilityModal = () => (
    <Modal contentClassName={styles.publishContent} centered show={showMessageModal} onHide={handleCloseModal}>
      <Modal.Title className={styles.publishTitle}>
        <PublishIcon className={styles.publishIcon} />
        <span>{messageModalTitle}</span>
      </Modal.Title>
      <Modal.Body data-cy="global-modal-message" className={styles.publishBody}>
        {messageModalContent}
      </Modal.Body>
      <Modal.Footer className={styles.publishFooter}>
        <Button className={styles.publishButton} variant={'outline-dark'} onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button className={styles.publishButton} variant={'dark'} onClick={handleModalButtonClick}>
          {messageModalButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const ChangeIdeaPhaseModal = () => (
    <Modal contentClassName={styles.publishContent} centered show={showMessageModal} onHide={handleCloseModal}>
      <Modal.Title className={styles.publishTitle}>
        <LeftRightIcon className={styles.publishIcon} />
        <span>{messageModalTitle}</span>
      </Modal.Title>
      <Modal.Body data-cy="global-modal-message" className={styles.publishBody}>
        {messageModalContent}
      </Modal.Body>
      <Modal.Footer className={styles.publishFooter}>
        <Button className={styles.publishButton} variant={'outline-dark'} onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button className={styles.publishButton} variant={'dark'} onClick={handleModalButtonClick}>
          {messageModalButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  switch (messageModalType) {
    case IModalType.Error:
      return ErrorModal();
    case IModalType.ChangeIdeaVisibility:
      return ChangeIdeaVisibilityModal();
    case IModalType.ChangeIdeaPhase:
      return ChangeIdeaPhaseModal();
  }

  return null; // just to bypass TS check: JSX cannot return undefined
};

export default GlobalMessageModal;
