import { useQuery } from 'react-query';
import { getChallenge } from '../../util/api';
import { arraySearchByKeyValuePair } from '../../util/helpers';
import { ISubmissionField, ReactQueryKey, Uuid } from '../../util/types';
import IdeaSubmissionFieldValue from '../SubmissionFieldValue';
import styles from './index.module.scss';

interface IProps {
  challengeId: Uuid;
  submissionFields: ISubmissionField[];
}

const IdeaSubmissionFields = ({ challengeId, submissionFields }: IProps) => {
  const { data } = useQuery([ReactQueryKey.Challenge, { challengeId }], () => getChallenge(challengeId));

  const challengeForms = data?.forms;

  return (
    <div>
      <h2 className={styles.header}>Idea Submission</h2>
      {challengeForms?.map((form) => (
        <div key={form.id} className={styles.form}>
          {/* TODO: Handle distinguish form from each other when there are more than one (design needed)*/}
          {form.fields?.map((challengeFormField) => {
            if (!challengeFormField || !challengeFormField.id || !submissionFields) {
              return null;
            }

            const searchResults = arraySearchByKeyValuePair({
              array: submissionFields,
              searchKey: 'fieldId',
              searchValue: challengeFormField.id,
            });

            const ideaFieldValue = searchResults?.length > 0 ? searchResults[0].value : '';

            return (
              <div key={challengeFormField.id}>
                {ideaFieldValue && (
                  <div className={styles.field}>
                    <h3 className={styles.label}>{challengeFormField.label}</h3>
                    <IdeaSubmissionFieldValue field={challengeFormField} value={ideaFieldValue} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default IdeaSubmissionFields;
