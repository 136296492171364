import { QueryClient } from 'react-query';
import { IError } from './types';

// https://react-query.tanstack.com/guides/query-retries
// Does not retry on Bad Request (400), Unauthorized (401), Forbidden (403), and Not Found (404)
const customRetry = (failureCount: number, rawError: any | null): boolean => {
  const error = rawError.error as IError;

  if ([400, 401, 403, 404].includes(error.status)) return false;

  if (failureCount === 2) return false;

  return true;
};

// https://react-query.tanstack.com/reference/QueryClient
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: customRetry,
      retryDelay: 1000,
    },
  },
});
