import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { FallbackProps } from 'react-error-boundary';
import AlertModal from '../AlertModal';

// https://docs.sentry.io/platforms/javascript/guides/react/usage/
export const globalErrorHandler = (error: Error, info: { componentStack: string }) => {
  Sentry.captureException(error);
  Sentry.captureMessage(info.componentStack);
};

const GlobalErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const [isModalVisible, setIsModalVisible] = useState(Boolean(error));

  const handleTryAgainOnConfirm = () => {
    setIsModalVisible(false);
    resetErrorBoundary();
    window.location.reload();
  };

  const handleTakeMeHomeOnCancel = () => {
    setIsModalVisible(false);
    resetErrorBoundary();
    window.location.replace('/home');
  };

  return (
    <AlertModal
      title="Error"
      body="We are sorry but it seems like something went wrong."
      isVisible={isModalVisible}
      cancel={{ text: 'Take me home', onClick: handleTakeMeHomeOnCancel }}
      confirm={{ text: 'Try Again', onClick: handleTryAgainOnConfirm }}
    />
  );
};

export default GlobalErrorFallback;
