import { useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MDEditor from '@uiw/react-md-editor';
import PageWrapper from '../../components/PageWrapper';
import LanguageSwitch from '../../components/LanguageSwitch';
import { useAuthenticate } from '../../hooks/useAuthenticate';
import { useAcceptAgreements } from '../../hooks/useAcceptAgreements';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { hasAcceptedAgreements } from '../../util/helpers';
import styles from './index.module.scss';

const AgreementsPage = () => {
  const history = useHistory();
  const { t } = useTranslation(['agreements']);
  const { data: currentUser } = useCurrentUser();
  const { logOut } = useAuthenticate();
  const { setTermsAgreed, setIpConsentAgreed, setPolicyAgreed, acceptAgreements } = useAcceptAgreements();

  if (currentUser && hasAcceptedAgreements(currentUser)) {
    history.replace('/home');
  }

  return (
    <PageWrapper>
      <LanguageSwitch />

      <div className={styles.title}>{t('agreements:title')}</div>
      <div className={styles.subtitle}>{t('agreements:subtitle')}</div>
      <div className={styles.agreementSection}>
        <div className={styles.sectionTitle}>
          <a href="/terms-of-use" target="__blank">
            {t('agreements:termsOfUse')}
          </a>
        </div>
        <Form.Check
          id="terms-checkbox"
          onChange={(event) => setTermsAgreed(event.currentTarget.checked)}
          type="checkbox"
          label={t('agreements:termsOfUseLabel')}
        />
      </div>
      <div className={styles.agreementSection}>
        <div className={styles.sectionTitle}>
          <a href="/privacy-policy" target="__blank">
            {t('agreements:privacyPolicy')}
          </a>
        </div>
        <Form.Check
          id="privacy-checkbox"
          className={styles.customCheckbox}
          onChange={(event) => setPolicyAgreed(event.currentTarget.checked)}
          type="checkbox"
          label={t('agreements:privacyPolicyLabel')}
        />
      </div>
      <div className={styles.agreementSection}>
        <div className={styles.sectionTitle}>{t('agreements:ipConsent')}</div>
        <MDEditor.Markdown className={styles.longParagraph} source={t('agreements:ipConsentContent')} />

        <Form.Check
          id="ip-consent-checkbox"
          onChange={(event) => setIpConsentAgreed(event.currentTarget.checked)}
          type="checkbox"
          label={t('agreements:ipConsentLabel')}
        />
      </div>
      <Button className={styles.agreeButton} variant="primary" onClick={acceptAgreements}>
        {t('agreements:agreeSubmit')}
      </Button>
      <Button className={styles.declineButton} variant="secondary" onClick={logOut}>
        {t('agreements:decline')}
      </Button>
    </PageWrapper>
  );
};

export default AgreementsPage;
