import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../util/constants';
import styles from './index.module.scss';

const LanguageSwitch = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    if (i18n.language === language) {
      return;
    }

    i18n.changeLanguage(language);
  };

  return (
    <div className={styles.wrapper}>
      <ButtonGroup>
        {Object.keys(LANGUAGES).map((lang) => (
          <Button
            key={lang}
            onClick={() => changeLanguage(LANGUAGES[lang])}
            variant={i18n.language === LANGUAGES[lang] ? 'primary' : 'secondary'}
          >
            {LANGUAGES[lang].toUpperCase()}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default LanguageSwitch;
