import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { IUrlParams, IUserPermission, ReactQueryKey } from '../../util/types';
import { getUserGroup, getUserShortsByGroup } from '../../util/api';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import BackButton from '../../components/BackButton';
import { Button } from '../../components/Button';
import AdminPageTitle from '../../components/AdminPageTitle';
import UserGroupChallenges from '../../components/UserGroupChallenges';
import UserShorts from '../../components/UserShorts';
import styles from './index.module.scss';

const AdminUserGroupDetailPage = () => {
  const { userGroupId } = useParams<IUrlParams>();
  const { hasPermission } = useCurrentUser();

  const { data: userGroup, isLoading } = useQuery([ReactQueryKey.UserGroup, userGroupId], () =>
    getUserGroup(userGroupId),
  );
  const { data: users, isLoading: isUsersLoading } = useQuery([ReactQueryKey.UserShorts, userGroupId], () =>
    getUserShortsByGroup(userGroupId),
  );

  return (
    <div>
      <AdminPageTitle text="User Groups Management" />
      <div className={styles.container}>
        {hasPermission(IUserPermission.CanManageUserGroups) ? (
          <>
            {isLoading && <p>loading...</p>}
            {userGroup && (
              <>
                <div className={styles.actions}>
                  <Link to="/admin/user-groups" data-testid="back-icon">
                    <BackButton />
                  </Link>
                  <div className={styles.actionButtons}>
                    <Link to={`/admin/user-groups/${userGroupId}/edit`}>
                      <Button className={styles.actionButton} variant="black">
                        Edit User Group
                      </Button>
                    </Link>
                  </div>
                </div>
                <div className={styles.contentContainer}>
                  {/* TODO: componentize */}
                  <div data-testid="group-info" className={styles.description}>
                    <h3 className={styles.header}>{userGroup.name}</h3>
                    <div className={styles.sections}>
                      <section>
                        <h4 className={styles.sectionHeader}>Description</h4>
                        <p className={styles.sectionParagraph}>{userGroup.description}</p>
                      </section>
                      <section>
                        <h4 className={styles.sectionHeader}>Code</h4>
                        <p className={styles.sectionParagraph}>{userGroup.code}</p>
                      </section>
                    </div>
                  </div>
                  <UserGroupChallenges userGroup={userGroup} />
                </div>
              </>
            )}
            {hasPermission(IUserPermission.CanRunAdvancedUserSearch) && users && userGroup && (
              <>
                <div className={styles.title}>{`User list for '${userGroup.name}'`}</div>
                <UserShorts userShorts={users} isLoading={isUsersLoading} />
              </>
            )}
          </>
        ) : (
          <div>No permission to manage user groups!</div>
        )}
      </div>
    </div>
  );
};

export default AdminUserGroupDetailPage;
