import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../../pages/HomePage';
import NotFound from '../../pages/NotFound';
import AdminHome from '../../pages/AdminHome';
import AdminChallengeDetailPage from '../../pages/AdminChallengeDetailPage';
import AdminIdeaDetailPage from '../../pages/AdminIdeaDetailPage';
import UserChallengeDetailPage from '../../pages/UserChallengeDetailPage';
import UserSubmissionIdeaPage from '../../pages/UserSubmissionIdeaPage';
import UserSubmissionFormPage from '../../pages/UserSubmissionFormPage';
import UserIdeasPage from '../../pages/UserIdeasPage';
import PublicIdeasPage from '../../pages/PublicIdeasPage';
import LoginPage from '../../pages/LoginPage';
import LoginApprovalPage from '../../pages/LoginApprovalPage';
import SignUpApprovalPage from '../../pages/SignUpApprovalPage';
import SignUpThankYouPage from '../../pages/SignUpThankYouPage';
import MaintenancePage from '../../pages/MaintenancePage';
import AgreementsPage from '../../pages/AgreementsPage';
import SettingsPage from '../../pages/SettingsPage';
import TermsOfUsePage from '../../pages/TermsOfUsePage';
import PrivacyPolicyPage from '../../pages/PrivacyPolicyPage';
import CookiePreferencesPage from '../../pages/CookiePreferencesPage';
import UserLayout from '../../layouts/UserLayout';
import NoLayout from '../../layouts/NoLayout';
import AdminLayout from '../../layouts/AdminLayout';
import SubmissionLayout from '../../layouts/SubmissionLayout';
import OrganizationIdeasPage from '../../pages/OrganizationIdeasPage';
import FAQPage from '../../pages/FAQPage';
import Authenticated from '../Authenticated';
import UserChallengeListPage from '../../pages/UserChallengeListPage';
import OnBehalfOfPage from '../../pages/OnBehalfOfPage';
import RedirectToIdeaUrl from '../RedirectToIdeaUrl';
import AdminIdeaEvaluationCreatePage from '../../pages/AdminIdeaEvaluationCreatePage';
import AdminIdeaEvaluationPage from '../../pages/AdminIdeaEvaluationPage';
import AdminIdeaEvaluationEditPage from '../../pages/AdminIdeaEvaluationEditPage';
import AdminUserInfoPage from '../../pages/AdminUserInfoPage';
import AdminUserSearchPage from '../../pages/AdminUserSearchPage';
import AdminIdeasPage from '../../pages/AdminIdeasPage';
import AdminChallengesPage from '../../pages/AdminChallengesPage';
import AdminChallengeCreatePage from '../../pages/AdminChallengeCreatePage';
import AdminChallengeEditPage from '../../pages/AdminChallengeEditPage';
import AdminUserGroupsPage from '../../pages/AdminUserGroupsPage';
import AdminUserGroupCreatePage from '../../pages/AdminUserGroupCreatePage';
import AdminUserGroupDetailPage from '../../pages/AdminUserGroupDetailPage';
import AdminUserGroupEditPage from '../../pages/AdminUserGroupEditPage';
import AdminChallengeFormPreviewPage from '../../pages/AdminChallengeFormPreviewPage';
import UserIdeaDetailsPage from '../../pages/UserIdeaDetailsPage';
import AdminPreapproveUsersPage from '../../pages/AdminPreapproveUsersPage';

// TODO: SMAP-385 Properly types the RouteWithLayout exact props
interface IProps {
  component: any;
  layout: any;
  path: any;
  exact: any;
  requireSession?: boolean;
}

// Each route is required to have the following props:
//   - path: link to the route
//   - exact: path matches the location.pathname exactly
//   - Component: The page the route renders
//   - Layout: Layout that wraps the page. i.e. UserLayout, AdminLayout, NoLayout, or custom layout
//   - requireSession: default to true as most pages are behind auth
const RouteWithLayout = ({
  requireSession: requireSession = true,
  component: Component,
  layout: Layout,
  exact,
  ...rest
}: IProps) => (
  <Route
    {...rest}
    render={(props) => (
      <Authenticated requireSession={requireSession}>
        <Layout>
          <Component {...props} />
        </Layout>
      </Authenticated>
    )}
  />
);

const Router = () => {
  return (
    <Switch>
      {/* ADMIN */}
      <RouteWithLayout exact path="/admin" component={AdminHome} layout={AdminLayout} />
      {/* TODO: Temporary page until User List Page and User Detail Page is supported */}
      <RouteWithLayout exact path="/admin/on-behalf-of" layout={NoLayout} component={OnBehalfOfPage} />
      <RouteWithLayout exact path="/admin/user-search" component={AdminUserSearchPage} layout={AdminLayout} />
      <RouteWithLayout exact path="/admin/user-groups" component={AdminUserGroupsPage} layout={AdminLayout} />
      <RouteWithLayout
        exact
        path="/admin/user-groups/create"
        component={AdminUserGroupCreatePage}
        layout={AdminLayout}
      />
      <RouteWithLayout
        exact
        path="/admin/user-groups/:userGroupId"
        component={AdminUserGroupDetailPage}
        layout={AdminLayout}
      />
      <RouteWithLayout
        exact
        path="/admin/user-groups/:userGroupId/edit"
        component={AdminUserGroupEditPage}
        layout={AdminLayout}
      />
      <RouteWithLayout exact path="/admin/user/:userId" component={AdminUserInfoPage} layout={AdminLayout} />
      <RouteWithLayout exact path="/admin/preapprove-users" component={AdminPreapproveUsersPage} layout={AdminLayout} />
      <RouteWithLayout exact path="/admin/challenges" component={AdminChallengesPage} layout={AdminLayout} />
      <RouteWithLayout
        exact
        path="/admin/challenges/create"
        component={AdminChallengeCreatePage}
        layout={AdminLayout}
      />
      <RouteWithLayout
        exact
        path="/admin/challenges/:challengeId/edit"
        component={AdminChallengeEditPage}
        layout={AdminLayout}
      />
      <RouteWithLayout
        exact
        path="/admin/challenges/:challengeId"
        component={AdminChallengeDetailPage}
        layout={AdminLayout}
      />
      <RouteWithLayout
        exact
        path="/admin/challenges/:challengeId/forms/:formId/preview"
        component={AdminChallengeFormPreviewPage}
        layout={NoLayout}
      />
      <RouteWithLayout exact path="/admin/ideas" component={AdminIdeasPage} layout={AdminLayout} />
      <RouteWithLayout exact path="/admin/ideas/:ideaId" component={AdminIdeaDetailPage} layout={AdminLayout} />
      <RouteWithLayout
        exact
        path="/admin/ideas/:ideaId/evaluations/create"
        component={AdminIdeaEvaluationCreatePage}
        layout={AdminLayout}
      />
      <RouteWithLayout
        exact
        path="/admin/ideas/:ideaId/evaluations/:evaluationId/edit"
        component={AdminIdeaEvaluationEditPage}
        layout={AdminLayout}
      />
      <RouteWithLayout
        exact
        path="/admin/ideas/:ideaId/evaluations/:evaluationId"
        component={AdminIdeaEvaluationPage}
        layout={AdminLayout}
      />

      {/* USER */}
      <RouteWithLayout exact path="/home" component={Home} layout={UserLayout} />
      <RouteWithLayout exact path="/challenges" component={UserChallengeListPage} layout={UserLayout} />
      <RouteWithLayout exact path="/organization-ideas" component={OrganizationIdeasPage} layout={UserLayout} />
      <RouteWithLayout
        exact
        path="/challenges/:challengeName/:challengeId"
        component={UserChallengeDetailPage}
        layout={UserLayout}
      />
      <RouteWithLayout
        exact
        path="/challenges/:challengeId/submission/create"
        component={UserSubmissionIdeaPage}
        layout={SubmissionLayout}
      />
      <RouteWithLayout
        exact
        path="/challenges/:challengeId/submission/:submissionId/form"
        component={UserSubmissionFormPage}
        layout={SubmissionLayout}
      />
      <RouteWithLayout
        exact
        path="/challenges/:challengeId/submission/:submissionId"
        component={UserSubmissionIdeaPage}
        layout={SubmissionLayout}
      />

      <RouteWithLayout exact path="/my/ideas" component={UserIdeasPage} layout={UserLayout} />
      <RouteWithLayout exact path="/ideas" component={PublicIdeasPage} layout={UserLayout} />
      <RouteWithLayout exact path="/ideas/:ideaId" component={RedirectToIdeaUrl} layout={UserLayout} />
      <RouteWithLayout exact path="/ideas/:ideaName/:ideaId" component={UserIdeaDetailsPage} layout={UserLayout} />
      <RouteWithLayout exact path="/agreements" component={AgreementsPage} layout={NoLayout} />
      <RouteWithLayout exact requireSession={false} path="/terms-of-use" component={TermsOfUsePage} layout={NoLayout} />
      <RouteWithLayout
        exact
        requireSession={false}
        path="/privacy-policy"
        component={PrivacyPolicyPage}
        layout={NoLayout}
      />
      <RouteWithLayout exact requireSession={false} path="/faq" component={FAQPage} layout={NoLayout} />
      <RouteWithLayout exact path="/settings" component={SettingsPage} layout={NoLayout} />
      <RouteWithLayout
        exact
        requireSession={false}
        path="/cookie-preferences"
        component={CookiePreferencesPage}
        layout={NoLayout}
      />
      <RouteWithLayout
        exact
        requireSession={false}
        path="/login-approval"
        component={LoginApprovalPage}
        layout={NoLayout}
      />
      <RouteWithLayout
        exact
        requireSession={false}
        path="/signup-approval"
        component={SignUpApprovalPage}
        layout={NoLayout}
      />
      <RouteWithLayout
        exact
        requireSession={false}
        path="/signup-thankyou"
        component={SignUpThankYouPage}
        layout={NoLayout}
      />
      <RouteWithLayout exact requireSession={false} path="/maintenance" component={MaintenancePage} layout={NoLayout} />
      <RouteWithLayout exact requireSession={false} path="/" component={LoginPage} layout={NoLayout} />

      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Router;
