import { ISubmissionFindDirection, ISubmissionFindOrder, ISubmissionPhaseFilter } from './types';

export const SERVICE_NAME = 'SMAP';
export const PREVIOUS_ROUTE_LOCAL_STORAGE_KEY = 'smapPreviousRoute';
export const NAME_PLACEHOLDER = 'Project Name';
export const DESC_PLACEHOLDER = 'Project Description';
export const BAD_SESSION_STATUS_CODE = 401;
export const MAINTENANCE_STATUS_CODE = 503;
export const MAINTENANCE_ROUTE = '/maintenance';
export const AGREEMENT_ROUTE = '/agreements';
export const ROUTES_WITHOUT_AUTH = [
  '/',
  '/authenticate',
  '/login-approval',
  '/terms-of-use',
  '/privacy-policy',
  '/cookie-preferences',
  '/signup-approval',
  '/signup-thankyou',
  '/beta-sign-up',
  '/faq',
];
export const ROUTES_WITHOUT_COOKIE_FORM = ROUTES_WITHOUT_AUTH.concat([AGREEMENT_ROUTE, MAINTENANCE_ROUTE]);
export const AGREEMENT_ROUTES = ['/terms-of-use', '/privacy-policy'];
export const EDITING_API_METHODS = ['post', 'put', 'get'];
export const EDITING_ROUTE_PATHS = [
  /^\/admin\/challenges\/[^\s\\\n]+\/form\/create/,
  /^\/challenges\/[^\s\\\n]+\/submission\/create/,
  /^\/challenges\/[^\s\\\n]+\/submission\/[^\s\\\n]+/,
  /^\/challenges\/[^\s\\\n]+\/submission\/[^\s\\\n]+\/form/,
];
export const AUTH_DOMAIN_WHITELIST = [
  'mooncreativelab.com',
  'mooncreativelab.onmicrosoft.com',
  'mitsui.com',
  'mitsui-steel.com',
  'mki.co.jp',
  'portek.com',
];

// Error codes from API
export const ERROR_MESSAGES = {
  ERROR: 'Error', // common/default
  SOMETHING_WENT_WRONG: 'Something went wrong',
  INFO: 'Information',
  AUTHENTICATION_ERROR: 'Authentication Error',
  UNSUPPORTED_IDENTITY_PROVIDER: 'UNSUPPORTED_IDENTITY_PROVIDER',
  NO_SESSION: 'NO_SESSION',
  INVALID_USER_INFORMATION: 'INVALID_USER_INFORMATION',
  CANT_AUTHENTICATE_SESSION: 'CANT_AUTHENTICATE_SESSION',
  NO_AUTHORIZATION_CODE: 'NO_AUTHORIZATION_CODE',
  PROVIDER_CANT_FIND_TOKEN: 'PROVIDER_CANT_FIND_TOKEN',
  NO_ID_TOKEN: 'NO_ID_TOKEN',
  INVALID_TOKEN: 'INVALID_TOKEN',
  UPLOAD_IMAGE: 'Please upload an image',
  NOT_READY_FOR_PUBLICATION: 'NOT_READY_FOR_PUBLICATION',
  FILESTACK_MAX_SIZE_ERROR: 'Image size cannot exceed 10MB',
  USER_NEEDS_APPROVAL: 'USER_NEEDS_APPROVAL',
  ASSURE_NAME_DESC_IMAGE_BEFORE:
    'Please remember to put a Name, Short description and a Photo before submitting your idea',
  ALL_MANDATORY_FIELDS: 'Please answer all mandatory fields',
  SUBMISSION_ERROR_CONTEXT: 'Please fix the below error(s) and try again:\n',
  MAINTENANCE_WARN_MESSAGE:
    'SMAP is undergoing planned maintenance, sorry for the inconvenience!\nPlease save your work and try again soon.',
} as any; // To support access with dynamic key

export const FILESTACK_URL = 'https://cdn.filestackcontent.com';

export const LANGUAGES = {
  EN: 'en',
  JA: 'ja',
} as any; // To support access with dynamic key
export const INTERCOM_APP_ID = 'besxlv0e';
export const SETTINGS = {
  NOTICES: {
    WELCOME_MESSAGE: 'NOTICES_WELCOME_MESSAGE',
  },
  // Switches on '/settings' page will be automatically appear
  // when new notification types are added to the below constant:
  NOTIFICATION_TYPES: [
    {
      id: 'notifications.email.newIdea',
      name: 'New idea submission',
      description: 'Every time a new idea is posted on SMAP',
    },
    {
      id: 'notifications.email.newCommentOnMyIdeas',
      name: 'Comments on your idea',
      description: 'Every time someone comments on your idea',
    },
    // TODO: add corresponding enum types from backend i.e. 'new memo'?
  ],
};

export const DEFAULT_DATE_STYLE = 'yyyy.MM.dd';

// Temporary harded routes for challenges
// https://docs.google.com/spreadsheets/d/1aS0i90-5of3xspnPXAXbglIha6fiFVkFDoFSVpwqD6w/edit#gid=0
export const CHALLENGE_TITLES = {
  openCallTitle: 'Open Call',
  moonOriginalTitle: 'Moon Original Challenge',
  individualIdeaApplicationTitle: 'Individual Idea Application',
};

// Temporary indicator on "new" marcom contents (be updated manually until proper CMS implementation)
export const NEW_CONTENT_IDS = ['day-in-the-life-moon-ux-design-lead-director-of-product-and-director-of-engineering'];

export const MOBILE_WIDTH = 414;
export const LARGE_DISPLAY_WIDTH = 1920;

export const DEFAULT_SUBMISSIONS_FILTERS = {
  includeRestrictedChallenges: true,
  includeInvisibleSubmissions: false,
  includeEvaluations: false,
  phase: ISubmissionPhaseFilter.All,
  order: ISubmissionFindOrder.Interesting,
  direction: ISubmissionFindDirection.Desc,
};
