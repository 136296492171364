import styles from './index.module.scss';

interface IProps {
  title: string;
  subtitle: string;
  Icon: any;
}

// TODO: The structure is temporary, needs update when we have a design system.
const EmptyState = ({ title, subtitle, Icon }: IProps) => {
  return (
    <div>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.subtitle}>{subtitle}</p>
      <div className={styles.image}>{<Icon className={styles.icon} />}</div>
    </div>
  );
};

export default EmptyState;
