import { ICheckBoxFormField } from '../../util/types';
import Switch from '../Switch';

interface IProps {
  field: ICheckBoxFormField;
}

const CheckBoxFormField = ({ field }: IProps) => (
  <Switch name={field.id} checked={field.defaultChecked} onChange={() => {}} dataTestId="checkbox-form-field" />
);

export default CheckBoxFormField;
