import { IForm, ISubmission } from '../../util/types';
import FormSubmissionDetail from '../FormSubmissionDetail';
import styles from './index.module.scss';

interface IProps {
  forms: IForm[];
  submission: ISubmission;
}

const FormsSubmission = ({ forms, submission }: IProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {forms.map((form) => (
          <div key={form.id}>
            <h2 className={styles.header}>{form.title}</h2>
            <p className={styles.paragraph}>{form.shortDescription}</p>
            {form.longDescription && <p className={styles.paragraph}>{form.longDescription}</p>}
            <br />

            <FormSubmissionDetail submission={submission} form={form} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormsSubmission;
