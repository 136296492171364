import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { downloadEvaluationsByChallenge, getChallenge } from '../../util/api';
import { IChallenge, IUrlParams, IUserPermission, ReactQueryKey } from '../../util/types';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import AdminChallengeBanner from '../../components/AdminChallengeBanner';
import AdminPageTitle from '../../components/AdminPageTitle';
import AdminChallengeDescription from '../../components/AdminChallengeDescription';
import ChallengePublishToggle from '../../components/ChallengePublishToggle';
import { Button } from '../../components/Button';
import BackButton from '../../components/BackButton';
import Form from '../../components/Form';
import ChallengeUserGroups from '../../components/ChallengeUserGroups';
import Modal from '../../components/Modal';
import FormCreateForm from '../../components/FormCreateForm';
import styles from './index.module.scss';

const AdminChallengeDetailPage = () => {
  const { hasPermission } = useCurrentUser();
  const { challengeId } = useParams<IUrlParams>();
  const { data: challenge, isLoading } = useQuery([ReactQueryKey.Challenge, { challengeId }], () =>
    getChallenge(challengeId),
  );
  const [isCreateFormModalVisible, setIsCreateFormModalVisible] = useState(false);
  const csvLinkRef = useRef<any>();

  const handleDownloadEvaluations = async (challenge: IChallenge) => {
    await downloadEvaluationsByChallenge(challengeId).then((csv) => {
      const blob = new Blob(['\ufeff', csv]);
      const url = URL.createObjectURL(blob);
      csvLinkRef.current.href = url;
      csvLinkRef.current.download = `${challenge.title} evaluations.csv`;
      csvLinkRef.current.click();
    });
  };

  const canDownloadEvaluations = (challenge: IChallenge) =>
    hasPermission(IUserPermission.CanSeeEvaluationDetails) &&
    challenge.evaluationForms &&
    challenge.evaluationForms.length > 0;

  return (
    <div>
      <AdminPageTitle text="Challenges Management" />
      <div>
        {isLoading && <p>loading...</p>}
        {challenge && (
          <>
            <div className={styles.actions}>
              <Link to="/admin/challenges" className={styles.backButton} data-testid="back-icon">
                <BackButton />
              </Link>

              <div className={styles.control}>
                {canDownloadEvaluations(challenge) && (
                  <>
                    <Button
                      className={styles.downloadEvaluationsButton}
                      variant="black"
                      onClick={() => handleDownloadEvaluations(challenge)}
                    >
                      Download Evaluations
                      <DownloadIcon className={styles.downloadIcon} />
                    </Button>
                    <a ref={csvLinkRef} hidden target="_" data-testid="evaluations-download-link" />
                  </>
                )}
                {hasPermission(IUserPermission.CanUpdateAllChallenges) && (
                  <>
                    <Button className={styles.button} variant="black" onClick={() => setIsCreateFormModalVisible(true)}>
                      Add Form
                    </Button>
                    <Link to={`/admin/challenges/${challengeId}/edit`}>
                      <Button className={styles.button} variant="black">
                        Edit Challenge
                      </Button>
                    </Link>
                    <ChallengePublishToggle challenge={challenge} />
                  </>
                )}
              </div>
            </div>

            <Tabs>
              <TabList className={styles.tabList}>
                <Tab className={styles.tab}>Description</Tab>
                <Tab className={styles.tab}>Submission Forms</Tab>
                <Tab className={styles.tab}>Evaluation Forms</Tab>
              </TabList>

              <TabPanel>
                <div className={styles.descriptionContainer}>
                  <div className={styles.banner}>
                    <AdminChallengeBanner title={challenge.title} image={challenge.image} />
                  </div>
                  <div className={styles.description}>
                    <AdminChallengeDescription challenge={challenge} />
                  </div>
                  {hasPermission(IUserPermission.CanManageUserGroups) && (
                    <div className={styles.challengeUserGroup}>
                      <ChallengeUserGroups challenge={challenge} />
                    </div>
                  )}
                </div>
              </TabPanel>

              <TabPanel>
                <div className={styles.formsContainer}>
                  {challenge.forms &&
                    challenge.forms.length !== 0 &&
                    challenge.forms.map((form) => <Form form={form} key={form.id} />)}
                </div>
              </TabPanel>

              <TabPanel>
                <div className={styles.formsContainer}>
                  {challenge.evaluationForms &&
                    challenge.evaluationForms.length !== 0 &&
                    challenge.evaluationForms.map((form) => <Form form={form} key={form.id} />)}
                </div>
              </TabPanel>
            </Tabs>

            <Modal isVisible={isCreateFormModalVisible} dismiss={() => setIsCreateFormModalVisible(false)}>
              <FormCreateForm challengeId={challenge.id} onSubmit={() => setIsCreateFormModalVisible(false)} />
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminChallengeDetailPage;
