import { IBlob } from '../../util/types';
import noImage from '../../assets/img/noImage.png';
import styles from './index.module.scss';

interface IProps {
  title: string;
  image: IBlob;
}

const AdminChallengeBanner = ({ title, image }: IProps) => {
  return (
    <div className={styles.wrapper} style={{ backgroundImage: image?.url ? `url(${image.url})` : `url(${noImage})` }}>
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
      </div>
    </div>
  );
};

export default AdminChallengeBanner;
