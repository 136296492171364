import React, { memo, ReactNode } from 'react';
import UserNavbar from '../../components/UserNavbar';
import Footer from '../../components/Footer';
import NewsLetterSignUp from '../../components/NewsLetterSignUp';
import styles from './index.module.scss';

interface IProps {
  children: ReactNode;
}

const UserLayout = ({ children }: IProps) => {
  return (
    <div className={styles.wrapper}>
      <UserNavbar />
      <div className={styles.mainContent}>{children}</div>
      <NewsLetterSignUp />
      <Footer />
    </div>
  );
};

export default memo(UserLayout);
