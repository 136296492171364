import { ITextAreaFormField } from '../../util/types';
import styles from './index.module.scss';

interface IProps {
  field: ITextAreaFormField;
}

const TextAreaFormField = ({ field }: IProps) => (
  <textarea className={styles.textarea} rows={field.rows} placeholder={field.placeHolder} disabled />
);

export default TextAreaFormField;
