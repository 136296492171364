import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IForm } from '../../util/types';
import { Button } from '../Button';
import FormEditForm from '../FormEditForm';
import FormField from '../FormField';
import styles from './index.module.scss';

interface IProps {
  form: IForm;
}

const Form = ({ form }: IProps) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className={styles.container} data-testid={form.type}>
      {isEditing ? (
        <FormEditForm form={form} onSubmit={() => setIsEditing(false)} />
      ) : (
        <>
          <div className={styles.controls}>
            <Link
              to={`/admin/challenges/${form.challengeId}/forms/${form.id}/preview`}
              target="_blank"
              className={styles.previewLink}
            >
              Preview
            </Link>
            <Button variant="black" onClick={() => setIsEditing(true)}>
              Edit
            </Button>
          </div>
          <h3 className={styles.header}>{form.title}</h3>
          <div className={styles.sections}>
            <p className={styles.paragraph}>{form.shortDescription}</p>
            {form.longDescription && <p className={styles.paragraph}>{form.longDescription}</p>}
            {form.fields && form.fields.length != 0 && (
              <div className={styles.formFields}>
                {form.fields.map((field) => (
                  <FormField field={field} key={field.id} />
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Form;
