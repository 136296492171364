import React, { memo, useState, MouseEvent } from 'react';
import styles from './index.module.scss';
import IdeaComment from '../IdeaComment';
import { useInfiniteQuery, useMutation } from 'react-query';
import { createIdeaComment, getIdeaComments } from '../../util/api';
import { queryClient } from '../../util/client';
import { Button } from '../Button';
import { ReactQueryKey, Uuid } from '../../util/types';
import RichTextInput from '../RichTextInput';

interface IProps {
  ideaId: Uuid;
}

const IdeaComments = ({ ideaId }: IProps) => {
  const [newComment, setNewComment] = useState<string>('');
  const createNewCommentMutation = useMutation(createIdeaComment);
  const {
    data: ideaCommentsData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [ReactQueryKey.IdeaComments, { submissionId: ideaId }],
    ({ pageParam = null }) => getIdeaComments(ideaId, pageParam),
    { getNextPageParam: (lastPage) => lastPage.links?.next },
  );

  const ideaComments = ideaCommentsData?.pages.flatMap((page) => page.data) || [];
  const hasComments = ideaComments?.length !== 0;

  const createNewComment = () =>
    createNewCommentMutation.mutate(
      { submissionId: ideaId, content: newComment },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(ReactQueryKey.IdeaComments);
          setNewComment('');
        },
      },
    );

  const handleCreateNewCommentOnClick = (event: MouseEvent) => {
    event.preventDefault();
    if (newComment.trim().length === 0) return;

    createNewComment();
  };

  return (
    <div className={styles.ideaComments}>
      <h2>Comments</h2>
      <RichTextInput
        className={styles.ideaComments__richTextInput}
        value={newComment}
        onChange={(value: any) => setNewComment(value)}
        placeholder="What's your thought?"
        data-cy="idea-comment-input"
      />
      <Button
        variant="black"
        data-cy="idea-comment-post-button"
        className={styles.ideaComments__submitButton}
        onClick={handleCreateNewCommentOnClick}
      >
        Post
      </Button>

      {isLoading ? <div>loading...</div> : null}

      {hasComments ? (
        <div className={styles.ideaComments__comments}>
          {ideaComments.map((comment) => (
            <IdeaComment key={comment.id} comment={comment} />
          ))}
        </div>
      ) : null}

      {hasNextPage ? (
        <Button variant="outline" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
          Load more
        </Button>
      ) : null}
    </div>
  );
};

export default memo(IdeaComments);
