import { useForm } from 'react-hook-form';
import { createTextAreaFormField } from '../../util/api';
import { queryClient } from '../../util/client';
import { IForm, IFormFieldTextAreaFormInputs, IFormFieldType, ReactQueryKey } from '../../util/types';
import { Button } from '../Button';
import HookFormSwitch from '../HookFormSwitch';
import HookFormTextInput from '../HookFormTextInput';
import styles from './index.module.scss';

interface IProps {
  form: IForm;
  onSubmit?: () => void;
}

const TextAreaFormFieldForm = ({ form, onSubmit }: IProps) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toSubmit = async (data: IFormFieldTextAreaFormInputs) => {
    const { label, description, placeHolder, rows, isRequired } = data;

    await createTextAreaFormField({
      formId: form.id,
      type: IFormFieldType.TextArea,
      label,
      description: description || undefined,
      placeHolder: placeHolder || undefined,
      rows,
      isRequired: Boolean(isRequired),
    })
      .then(() => {
        queryClient.invalidateQueries([ReactQueryKey.Challenge, { challengeId: form.challengeId }]);
        onSubmit && onSubmit();
      })
      .catch((e: any) => console.error(e));
  };

  return (
    <form data-testid="textarea-field-form" className={styles.form}>
      <HookFormTextInput
        name="label"
        label="Label"
        rules={{ required: 'label is required' }}
        register={register}
        errors={errors}
        dataTestId="textarea-label-input"
      />
      <HookFormTextInput
        name="description"
        label="Description"
        register={register}
        errors={errors}
        dataTestId="textarea-description-input"
      />
      <HookFormTextInput
        type="number"
        min="1"
        name="rows"
        label="Rows"
        rules={{ required: 'rows is required' }}
        register={register}
        errors={errors}
        dataTestId="textarea-rows-input"
      />
      <HookFormSwitch
        name="isRequired"
        label="Required"
        control={control}
        errors={errors}
        dataTestId="textarea-isRequired-switch"
      />
      <HookFormTextInput
        name="placeHolder"
        label="Placeholder"
        register={register}
        errors={errors}
        dataTestId="textarea-placeHolder-input"
      />
      <Button variant="black" onClick={handleSubmit(toSubmit)} className={styles.addFormFieldButton}>
        Add form field
      </Button>
    </form>
  );
};

export default TextAreaFormFieldForm;
